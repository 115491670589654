import { ref } from "vue";

const headers = { 
  'Content-Type': 'application/json',
  'Accept': 'application/json',
};

const base = process.env.VUE_APP_API_URL;

const Is5xx = ref(false);


async function load (method, uri, body) {
  const response = await fetch(base + uri, {
    method,
    body: body ? JSON.stringify(body) : undefined,
    headers,
  });
  Is5xx.value = (response.status + "").substring(0, 1) === '5';
  if (Is5xx.value) return;
  return await response.json();
}


export default {

  async post (uri, body) {
    return await load("POST", uri, body);
  },

  async get (uri) {    
    return await load("GET", uri);    
  },

  is5xx: Is5xx,
  
}