<template>
  <section class="text-white px-4 md:px-0 text-center py-16 md:py-24 lg:py-32" 
    style="background: linear-gradient(315deg, #0B4652 0%, #00C0A2 100%)">
    
    <div class="container mx-auto">
      <h2 class="text-2xl md:text-4xl font-semibold">Buy with confidence.</h2>
      
      <p class="mt-5 md:text-lg leading-relaxed header-text max-w-3xl mx-auto">
        Fake {{this.$localize(this.country, "paystubs")}} are costly. Our forms are built, vetted and 
        updated by certified {{this.$localize(this.country, "American")}}
        tax professionals {{$route.name === 'tax-season' ? '— like you —' : ''}}
        so you can rest assured you’re getting accurate {{this.$localize(this.country, "paystubs")}} every time.
      </p>
      
      <ul class="grid grid-rows-3 grid-cols-1 md:grid-rows-1 md:grid-cols-3 justify-center gap-4 lg:gap-2 
        px-8 mt-12 md:mt-24">
        <li class="flex flex-col items-center gap-2 md:gap-6" v-for="item of confidence" :key="item.text">
          <div class="confidence-bg" :class="item.cls"></div>
          <p class="text-xl md:text-2xl lg:text-3xl w-64">{{item.text}}</p>
        </li>           
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    country () {
      return this.$route.meta.country;
    },

    confidence: () => {
      return [
        {cls: "confidence-guarantee", text: "Money Back Guarantee"},
        {cls: "confidence-shield", text: "Secure Payment Processing"},
        {cls: "confidence-check", text: "High Customer Satisfaction Rating"},
      ]
    },
  },
}
</script>

<style scoped>
  .confidence-bg {
    @apply w-10 h-10 bg-no-repeat bg-contain bg-center;
  }

  .confidence-guarantee {
    background-image: url('@/assets/images/guarantee_grreen.svg');
  }

  .confidence-shield {
    background-image: url('@/assets/images/shield_green.svg');
  }

  .confidence-check {
    background-image: url('@/assets/images/check_green.svg');
  }
</style>