<template>
  <div class="psn-gradient-header overflow-hidden w-full">

    <header class="psn-paystub-banner bg-no-repeat text-white p-4 md:p-16  ">

      <div class="container mx-auto p-2">
        <h1 :class="(small ? 'text-3xl' : 'text-4xl') + ' md:text-5xl'">{{title}}</h1>

        <p class="mt-6 text-lg font-bold w-96 p-2 pr-8 md:p-0">{{text}}</p>

        <p class="ml-2 md:ml-0 text-base mt-4 md:mt-12">
          Need help? <a href="javascript:void(0);" @click="$otherAPI.initChat()">Get support  ⟶</a>
        </p>
      </div>
      
    </header>
    
  </div>  
</template>

<script>
export default {
  props: ["title", "text", "small"],
}
</script>


<style scoped>
  /* .psn-page-header {
    background: linear-gradient(90deg, rgba(4, 190, 160, 1)  0%, rgba(0, 81, 90, 1) 100%);
  } */
  /* background: linear-gradient( 0deg, rgba(49,73,156,1) 0%, rgba(93,118,240,1) 100%); */     


  /* TODO: This is not working well. Resizing browser doesn't work.
  Also size limits are arbitrary match the ones used in tailwind. */

  @media (max-width:19641px) {
    .psn-paystub-banner {
      background-image: url("../assets/images/paystub-banner.svg");    
      height: 28rem;
      /* background-size: cover; */
      background-position: 100% 1.5rem;     
    }

    .psn-gradient-header {
      height: 28rem;;
    }
  }


  @media (max-width:768px)  { 
    .psn-paystub-banner {
      background-image: url("../assets/images/paystub-banner.svg");    
      height: 23rem;
      background-size: contain; 
      background-position: 0px 100%;
    }

    .psn-gradient-header {
      height: 23rem;;
    }
  }
</style>