
import { computed, nextTick, reactive, watch } from "vue";
import taxCalc from "../tax-calc";
import taxCalcCa from "../tax-calc-ca";
import taxCalcUK from "../tax-calc-uk";
import Deduction from "./Deduction";
import Earning from "./Earning";

const taxModule = {
  us: taxCalc,
  ca: taxCalcCa,
  uk: taxCalcUK,
}

let paystub;
taxModule; nextTick;

export default {
  runner: null,

  init (ps, _runner, _country) {
    paystub = ps;
    this.runner = _runner;
    this.country = _country;
    Earning.init(paystub, this.runner);
    Deduction.init(paystub, this.runner, _country);
  },

  computify (rawStub, forNew) {
    const stub = reactive(rawStub);

    watch(() => stub.priorYTD, () => {
      const nominalEarningYTD = + (stub.earnings.reduce((sum, e) => {
        return e.nominalPriorYTD + sum;
      }, 0)).toFixed(2);

      const ratio = nominalEarningYTD ? (stub.priorYTD / nominalEarningYTD): 1;
      stub.deductions.forEach(d => {
        //TODO: This should not have happened but it happens.
        //Find the real reason to solve it correctly.
        if (d.nominalPriorYTD == undefined ) {
          //console.log(d.name, "priorytd undefined");
          d.nominalPriorYTD = 0;
        }
        d.priorYTD = d.nominalPriorYTD * ratio;
      });

      this.runner.priorYTDChanged(stub)
    });

    //income
    stub.total = computed(() => {
      return + (stub.earnings.reduce((acc, earning) => acc + earning.total, 0)).toFixed(2);
    });

    stub.priorYTD = computed(() => {
      return + (stub.earnings.reduce((acc, earning) => acc + earning.priorYTD, 0)).toFixed(2);
    });

    stub.ytdTotal = computed(() => {
      return + (stub.priorYTD + stub.total).toFixed(2);
    });

    //deduction
    stub.employeeDeductions = computed( () => stub.deductions.filter(d => ! d.isEmployer));
    stub.employerDeductions = computed( () => stub.deductions.filter(d => d.isEmployer));

    stub.deductionTotal = computed(() => {
      return + stub.employeeDeductions.reduce((acc, deduction) => acc + deduction.amount, 0).toFixed(2);
    });

    stub.deductionPriorYTD = computed(() => {
      return + (stub.employeeDeductions.reduce((acc, deduction) => acc + deduction.priorYTD, 0)).toFixed(2);
    });

    stub.deductionYTDTotal = computed(() => {
      return + (stub.employeeDeductions.reduce((acc, deduction) => acc  + deduction.ytdTotal, 0)).toFixed(2);
    });

    //employer contribution
    stub.employerTotal = computed(() => {
      return + stub.employerDeductions.reduce((acc, deduction) => acc + deduction.amount, 0).toFixed(2);
    });

    stub.employerPriorYTD = computed(() => {
      return + (stub.employerDeductions.reduce((acc, deduction) => acc + deduction.priorYTD, 0)).toFixed(2);
    });

    stub.employerYTDTotal = computed(() => {
      return + (stub.employerDeductions.reduce((acc, deduction) => acc  + deduction.ytdTotal, 0)).toFixed(2);
    });

    //net pay
    stub.netPayTotal = computed(() => {
      return + (stub.total - stub.deductionTotal).toFixed(2);
    });

    stub.netPayPriorYTD = computed(() => {
      return + (stub.priorYTD - stub.deductionPriorYTD).toFixed(2);
    });

    stub.netPayYTDTotal = computed(() => {
      return + (stub.ytdTotal - stub.deductionYTDTotal).toFixed(2);
    });

    //loaded-from-data paystubs are not new, so we run computifies for non-new stubs only.
    if (! forNew) {
      //console.log("kompütifycek");
      stub.earnings.forEach(e => Earning.computify(stub, e));
      stub.deductions.forEach(d => Deduction.computify(stub, d, false));
    }

    //notify so that consequent paystubs' pay dates are adjusted similar to the first.
    watch(() => stub.payDate, () => this.runner.payDateChanged(stub), {immediate: true});

    //when payment type is changed, we must reset the regular payment values for all paystubs
    watch(() => paystub.details.paymentType, () => {
      const regular = stub.earnings.find(e => e.isRegular);
      const regularDefaults = Earning.getRegularEarningDefaults();
      Object.keys(regularDefaults).forEach(key => regular[key] = regularDefaults[key]);
    });

    return stub;
  },

  //new one. Not called for loaded-from-data ones.
  make (period, isNewStub) {
    const rawStub = {
      payDate: period.end,
      payPeriod: period,
      periodIndex: 0,
      earnings: [],
      deductions: [],
      fields: {}, //special fields on template1 and Advice number
      _key: Math.random(), //crucial, as the order of stubs changes when nr of paystubs reqired changes.
      _new: isNewStub,
    };

    const stub = this.computify(rawStub, true);
    paystub.stubs.unshift(stub);
    Earning.fillDefaultEarnings(stub);
    Deduction.bindWatch(stub);

    //createDeductions is not necassary, as the trigger is immediate.
    //Deduction.createDeductions(stub);
    return stub;
  },

}