<template>
  <div class="mt-2 mb-16">
    <div class="flex flex-row gap-4 md:gap-8 items-baseline w-full bg-psn-blue p-4" ><!--@click="toggle(item, index)"-->
                
      <span class="w-8 h-8 pt-1 md:w-24 md:h-11 md:pt-2.5  
      uppercase text-center  text-white text-2xl font-bold">
        <span class="hidden md:inline">Step </span>{{index}}
      </span>

      <h2 class="text-psn-light-header text-2xl md:text-2xl font-bold flex-grow">{{title}}</h2>

      <!-- beads -->
      <div class="relative" v-if="false"> <!--expanded[index]-->
        <div class="h-0 absolute top-1.5 md:top-3 w-full border-psn-blue border-t md:border"></div>
        
        <div class="grid grid-flow-col gap-2 md:gap-4 relative" :title="'Step ' + index + ' of ' + total">
          <span v-for="i in total" :key="i"            
            :class="'border-2 border-psn-blue rounded-full w-3 h-3 md:w-6 md:h-6 ' + getCellBgClass(i, index)">
          </span>
        </div>
        
      </div>

    </div>

    <div class="mb-8 mt-12">    
      <slot></slot>
    </div>
    
  </div>
</template>

<script>
//import AccordionHeader from "./psn-form-accordion-header.vue"
export default {
  components: {
    //AccordionHeader,
  },

  methods: {
    getCellBgClass (beadIndex, index) {
      return beadIndex <= index ? "bg-psn-blue" : "bg-white";
    },
  },

  props: ["index", "total", "title", "active"],
}
</script>