"use strict";
import dayjs from "dayjs";
import stateTax2021 from "./stateTaxCA-2021.js";
import stateTax2022 from "./stateTaxCA-2022.js";
import stateTax2023 from "./stateTaxCA-2023.js";
import stateTax2024 from "./stateTaxCA-2024.js";


const stateTaxTableByYear = {
  2021: stateTax2021,
  2022: stateTax2022,
  2023: stateTax2023,
  2024: stateTax2024,
};

const federalBrackets = {

  2021: [
    { max: 13808,       rate: 0,    constant: 0 },
    { max: 49020,       rate: 15,   constant: 0 },
    { max: 98040,       rate: 20.5, constant: 2696 },
    { max: 151978,      rate: 26,   constant: 8088 },
    { max: 216511,      rate: 29,   constant: 12648 },
    { max: 9999999999,  rate: 33,   constant: 21308 },
  ],

  2022: [
    { max: 14398,       rate: 0,    constant: 0 },
    { max: 50197,       rate: 15,   constant: 0 },
    { max: 100392,      rate: 20.5, constant: 2761 },
    { max: 155625,      rate: 26,   constant: 8282 },
    { max: 221708,      rate: 29,   constant: 12951 },
    { max: 9999999999,  rate: 33,   constant: 21819 },
  ],

  2023: [ //everything about 2023 rates: https://www.canada.ca/en/revenue-agency/services/forms-publications/payroll/t4032-payroll-deductions-tables/t4032on-jan/t4032on-january-general-information.html#_Toc337712789
    { max: 15000,       rate: 0,    constant: 0 },
    { max: 53359,       rate: 15,   constant: 0 },
    { max: 106717,      rate: 20.5, constant: 2935 },
    { max: 165430,      rate: 26,   constant: 8804 },
    { max: 235675,      rate: 29,   constant: 13767 },
    { max: 9999999999,  rate: 33,   constant: 23194 },
  ],

  2024: [
	{ max: 15705,       rate: 0,    constant: 0 },
	{ max: 55867,       rate: 15,   constant: 0 },
	{ max: 111733,      rate: 20.5, constant: 3073 },
	{ max: 173205,      rate: 26,   constant: 9218 },
	{ max: 246752,      rate: 29,   constant: 14414 },
	{ max: 9999999999,  rate: 33,   constant: 24284 },
  ]
};

//Deducted from the taxable income https://htkacademy.com/cra-interpretation/t4032on-payroll-deductions-tables-cpp-ei-and-income-tax-deductions-ontario-effective-january-1-2019/#Stepbystep_calculation_of_tax_deductions
const BPA = {
  2021: {
    federal: 13808,
    ON: 10880, //Ontario
    QC: 15728, //Quebec
    NS: 11481, //Nova Scotia
    NB: 10564, //New Brunswick
    MB: 9936, //Manitoba
    BC: 11070, //British Columbia
    PE: 10500, //Prince Edward Island
    SK: 16225, //Saskatchewan
    AB: 19369, //Alberta
    NL: 9536, //Newfoundland and Labrador
    NT: 15243, //Northwest Territories
    YT: 13808, //Yukon
    NU: 16467, //Nunavut
  },

  2022: {
    federal: 14398,
    ON: 11141, //Ontario
    QC: 16143, //Quebec
    NS: 11481, //Nova Scotia
    NB: 10817, //New Brunswick
    MB: 10145, //Manitoba
    BC: 11302, //British Columbia
    PE: 11250, //Prince Edward Island
    SK: 16615, //Saskatchewan
    AB: 19369, //Alberta
    NL: 9803, //Newfoundland and Labrador
    NT: 15609, //Northwest Territories
    YT: 14398, //Yukon
    NU: 16862, //Nunavut
  },

  2023: {
    federal: 15000,
    ON: 11865, //Ontario
    QC: 17183, //Quebec
    NS: 11481, //Nova Scotia
    NB: 12458, //New Brunswick
    MB: 10855, //Manitoba
    BC: 11981, //British Columbia
    PE: 12000, //Prince Edward Island
    SK: 17661, //Saskatchewan
    AB: 21003, //Alberta
    NL: 10382, //Newfoundland and Labrador
    NT: 16593, //Northwest Territories
    YT: 15000, //Yukon
    NU: 17925, //Nunavut
},

  2024: {
	federal: 15705,
	ON: 12399, //Ontario
    QC: 17183, //Quebec
    NS: 11481, //Nova Scotia
    NB: 13044, //New Brunswick
    MB: 15780, //Manitoba
    BC: 12580, //British Columbia
    PE: 13500, //Prince Edward Island
    SK: 18491, //Saskatchewan
    AB: 21885, //Alberta
    NL: 10818, //Newfoundland and Labrador
    NT: 17373, //Northwest Territories
    YT: 15705, //Yukon
    NU: 18767, //Nunavut

  }
};

const CANADA_EMPLOYMENT_AMOUNT = { // Canada Employment Amount (Double this amount for Yukon residents? Check)
  2021: 1257,
  2022: 1287,
  2023: 1368,
  2024: 1433,
}

const MIN_TAXABLE = { //like standard deduction in the us. Below this is not taxed.
  2021: 15999,
  2022: 16701.10,
  2023: 16701.10,
  2024: 16701.10,
}

const CPP = { // Canada Pension Plan
  2021: 5.45 / 100,
  2022: 5.7 / 100,
  2023: 5.95 / 100,
  2024: 4.95 / 100,
}

const CPP_MAX_CONTRIBUTION = { //yearly
  2021: 3166.45,
  2022: 3499.8,
  2023: 3754.45,
  2024: 3217.5,
}

const CPP_BASIC_EXEMPTION = { //yearly
  2021: 3500,
  2022: 3500,
  2023: 3500,
  2024: 3500,
}

const QPP = { // Quebec Pension Plan
  2021: 5.9 / 100,
  2022: 6.15 / 100,
  2023: 6.4 / 100,
  2024: 6.4 / 100,
}

const QPP_MAX_CONTRIBUTION = { //yearly
  2021: 3427.9,
  2022: 3776.1,
  2023: 4038.4,
  2024: 4348,
}

const QPP_BASIC_EXEMPTION = { //yearly
  2021: 3500,
  2022: 3500,
  2023: 3500,
  2024: 3500,
}

const QPIP = {
  2021: {
    employeeRate: 0.494 / 100,
    employeeMax: 412.49,
    employerRate: 0.692 / 100,
    employerMax: 577.82
  },
  2022: {
    employeeRate: 0.494 / 100,
    employeeMax: 434.72,
    employerRate: 0.692 / 100,
    employerMax: 608.96
  },
  2023: {
    employeeRate: 0.494 / 100,
    employeeMax: 449.54,
    employerRate: 0.692 / 100,
    employerMax: 629.72
  },
  2024: {
	employeeRate: 0.494 / 100,
	employeeMax: 464.36,
	employerRate: 0.692 / 100,
	employerMax: 650.48
  },
}

const ABATEMENT = { //QC only.
  2021: 16.5 / 100,
  2022: 16.5 / 100,
  2023: 16.5 / 100,
  2024: 16.5 / 100,
}

const EI = { // source: https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/payroll/payroll-deductions-contributions/employment-insurance-ei/ei-premium-rates-maximums.html
	2021: 1.58 / 100,
	2022: 1.58 / 100,
	2023: 1.63 / 100,
	2024: 1.66 / 100
}

const EI_MAX_CONTRIBUTION = {
  2021: {
    employee: 889.54,
    employer: 1245.36,
  },
  2022: {
    employee: 952.74,
    employer: 1333.84,
  },
  2023: {
    employee: 1002.45,
    employer: 1403.43,
  },
  2024: {
	employee: 1049.12,
	employer: 1468.77,
  },
}


export default {

  //status can be either "single" or "married"
  calcFederal (yearlyRegular, periodTotal, freq, period, state) {
    //- extract year:
    const year = dayjs(period.end).year();

    //-. tax bracket is found using the yearly regular:
    const brackets = federalBrackets[year];
    const bracketIndex = brackets.findIndex(b => yearlyRegular <= b.max);
    const bracket = brackets[bracketIndex];
    const taxAmount = +(bracket.rate / 100 * yearlyRegular).toFixed(2);

    //qpp is for Quebec, cpp for everywhere else. Only the constants change, calculation is the same:
    const BASIC_EXEMPTION = state === 'QC' ? QPP_BASIC_EXEMPTION[year] : CPP_BASIC_EXEMPTION[year];
    const PP = state === 'QC' ? QPP[year] : CPP[year];
    const MAX_CONTRIBUTION = state === 'QC' ? QPP_MAX_CONTRIBUTION[year] : CPP_MAX_CONTRIBUTION[year];

    //common cpp & qpp:
    let cpp = 0;
    let cppYearly = + ( (yearlyRegular - BASIC_EXEMPTION) * PP ).toFixed(2);
    if (cppYearly > cpp) {
      if (cppYearly > MAX_CONTRIBUTION) cppYearly = MAX_CONTRIBUTION;
      const maxCPPPerPeriod = MAX_CONTRIBUTION / freq;
      const cppRatio = cppYearly / yearlyRegular;
      cpp = + (periodTotal * cppRatio).toFixed(2);
      if (cpp > maxCPPPerPeriod) cpp = maxCPPPerPeriod;
    }

    //ei:
    let eiYearly = + (yearlyRegular * EI[year]).toFixed(2);
    if (eiYearly > EI_MAX_CONTRIBUTION[year].employee) eiYearly = EI_MAX_CONTRIBUTION[year].employee;
    const maxEIPerPeriod = EI_MAX_CONTRIBUTION[year].employee / freq;
    const eiRatio = eiYearly / yearlyRegular;
    let ei = + (periodTotal * eiRatio).toFixed(2);
    if (ei > maxEIPerPeriod) ei = maxEIPerPeriod;

    //tax credit:
    const lowestFederalRate = federalBrackets[year].find(bracket => bracket.rate).rate;
    const taxCreditIngredients = cppYearly + eiYearly + BPA[year].federal + CANADA_EMPLOYMENT_AMOUNT[year];
    let taxCreditYearly = yearlyRegular <= MIN_TAXABLE[year]
      ? 0 //no tax credit for low earners.
      : +(taxCreditIngredients * lowestFederalRate / 100).toFixed(2);

    //federal tax:
    let payableTaxYearly = taxAmount - taxCreditYearly - bracket.constant;
    if (state === 'QC') payableTaxYearly = payableTaxYearly * (1 - ABATEMENT[year]); //Quebec has abatement.

    const ratio = payableTaxYearly / yearlyRegular;
    let fedTaxInPeriod = + (periodTotal * ratio).toFixed(2);

    let additionalTaxes = [];

    //province taxes:
    let provinceTaxInPeriod = 0;
    if (state) {
      const brackets = stateTaxTableByYear[year].provinceTaxBrackets[state];
      const bracketIndex = brackets.findIndex(b => yearlyRegular <= b.max);
      const bracket = brackets[bracketIndex];
      const taxAmount = +(bracket.rate / 100 * yearlyRegular).toFixed(2);

      const lowestProvinceRate = brackets.find(bracket => bracket.rate).rate;
      const taxCreditIngredients = cppYearly + eiYearly + BPA[year][state];
      let taxCreditYearly = yearlyRegular <= stateTaxTableByYear[year].provinceMinTaxable[state]
        ? 0
        : +(taxCreditIngredients * lowestProvinceRate / 100).toFixed(2);

      let payableTaxYearly = taxAmount - taxCreditYearly - bracket.constant;

      //tax reduction: (must come before province tax, because included by it)
      const trTable = stateTaxTableByYear[year].provincialTaxReductionBrackets[state];
      if (trTable) {
        const bracketIndex = trTable.findIndex(bracket => payableTaxYearly <= bracket.max);
        const bracket = trTable[bracketIndex];
        const prevBracket = trTable[bracketIndex - 1];

        const reductionYearly = prevBracket
          ? prevBracket.constant - (payableTaxYearly - prevBracket.max) * (bracket.rate / 100)
          : bracket.constant;

        payableTaxYearly -= reductionYearly;
        payableTaxYearly = + payableTaxYearly.toFixed(2);
      }

      const ratio = payableTaxYearly / yearlyRegular;
      provinceTaxInPeriod = + (periodTotal * ratio).toFixed(2);

      //surtax:
      const surtaxTable = stateTaxTableByYear[year].provincialSurtaxBrackets[state];
      if (surtaxTable) {
        const bracketIndex = surtaxTable.findIndex(b => payableTaxYearly <= b.max);
        const bracket = surtaxTable[bracketIndex];
        const prevBracket = surtaxTable[bracketIndex - 1];
        const yearlySurtax = prevBracket
          ? (payableTaxYearly - prevBracket.max) * (bracket.rate / 100) + bracket.constant
          : 0;

        const surtaxRatio = yearlySurtax / yearlyRegular;
        const surtaxInPeriod = + (periodTotal * surtaxRatio).toFixed(2);
        additionalTaxes.push( {name: "Surtax", amount: surtaxInPeriod, key: "surtax"} );
      }

      //health premium:
      const premiumTable = stateTaxTableByYear[year].provincialHealthPremiumBrackets[state];
      if (premiumTable) {
        const bracket = premiumTable.find(bracket => yearlyRegular <= bracket.max);
        const yearlyHP = bracket.constant;

        const ratio = yearlyHP / yearlyRegular;
        const hpInPeriod = + (periodTotal * ratio).toFixed(2);
        additionalTaxes.push( {name: "Health Premium", amount: hpInPeriod, key: "health-premium"} );
      }

      //qpip: Quebec only
      if (state === 'QC') {
        ["employee", "employer"].forEach(who => {
          const rate = who === "employee" ? QPIP[year].employeeRate : QPIP[year].employerRate;
          const max = who === "employee" ? QPIP[year].employeeMax : QPIP[year].employerMax;

          let qpipYearly = + (yearlyRegular * rate).toFixed(2);
          if (qpipYearly > max) qpipYearly = max;
          const maxQPIPPerPeriod = max / freq;
          const qpipRatio = qpipYearly / yearlyRegular;
          let qpip = + (periodTotal * qpipRatio).toFixed(2);
          if (qpip > maxQPIPPerPeriod) qpip = maxQPIPPerPeriod;
          additionalTaxes.push( {name: "QPIP", amount: qpip, key: who + "-qpip", isEmployer: who === 'employer' } );
        });
      }
    }

    let taxes = [
      {name: "Federal Tax", amount: fedTaxInPeriod, key: "federal"},
      {name: "Provincial Tax", amount: provinceTaxInPeriod, key: "state"},
      {name: "Employment Insurance", amount: ei, key: "ei"},
      {name: state === 'QC' ? "Quebec Pension Plan" : "Canada Pension Plan", amount: cpp, key: "cpp"},
      ...additionalTaxes,
    ];

    taxes.forEach(t => {
      if (! t.isEmployer) t.isEmployer = false;
    });

    const employerContributions = this.calcEmployerContributions(taxes);

    taxes = [...taxes, ...employerContributions];

    taxes.forEach(tax => Object.assign(tax, {priorYTD: 0, ytdTotal: 0, isCustom: false, }));

    return taxes;
  },

  employerEI_fn: amount => +(amount * 1.4).toFixed(2),
  employerCPP_fn: amount => + (amount).toFixed(2),

  calcEmployerContributions (taxes) {
    //employer contributions: 1.4x EI, 1x cpp
    const ei = taxes.find(tax => tax.key === 'ei');
    const employerEI = JSON.parse(JSON.stringify(ei));
    employerEI.amount = 0;
    employerEI.key = "employer-ei";
    employerEI.isEmployer = true;
    employerEI.boundTo = "ei";
    employerEI.fn = "employerEI_fn";

    const cpp = taxes.find(tax => tax.key === 'cpp');
    const employerCPP = JSON.parse(JSON.stringify(cpp));
    employerCPP.amount = 0;
    employerCPP.key = "employer-cpp";
    employerCPP.isEmployer = true;
    employerCPP.boundTo = "cpp";
    employerCPP.fn = "employerCPP_fn";

    return [employerEI, employerCPP];
  },

  createCustomDeduction (name, isEmployer = false) {
    return {
      name,
      amount: 0,
      key: "custom-" + name,
      priorYTD: 0,
      ytdTotal: 0,
      isCustom: true,
      isEmployer,
    }
  },

  //calculated how many days since the year start for priodYDT (not ytd)
  ytdDays (period) {
    const jan1ForEnd = dayjs(period.end).startOf('year');
    const jan1ForStart = dayjs(period.start).startOf('year');
    //if period crosses jan 1, that means there is no prior YTD
    return jan1ForEnd.isSame(jan1ForStart) ? dayjs(period.start).diff(jan1ForStart, "day") : 0;
  },

  ytdCoefficient (period, freq) {
    const days = this.ytdDays(period);
    return days / (365 / freq);
  },


}