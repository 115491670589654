<template>

  <Accordion ref="tabContainer" v-model:activeIndex="activeIndex"
      @tab-open="tabToggled(true, $event.index)"
      @tab-close="tabToggled(false, $event.index);">

    <AccordionTab v-for="(item, index) of tabs" :key="item.key">
      <template #header>
        <PaystubHeader ref="paystubHeader"
          :index="index + 1" :total="tabs.length" :title="item.tab.props.title"
          :active="index === activeIndex"  />
      </template>
      <div class="tab-content">
        <form ref="forms" @submit.prevent="submit($event, item, index)">
          <component :is="item.tab"></component>

          <!-- back and next buttons -->
          <div class="flex flex-row justify-between mt-12">
            <psn-sharp-button class="" text="Back" :isBack="true" @click="back(item, index)" :disabled="index === 0" />
            <psn-sharp-button submit :text="getNextText(index)" :isNext="true"
              :id="finalStepId || Math.random()"
              :loading="index === tabs.length - 1 && finalStepLoading" />
          </div>
        </form>
      </div>

    </AccordionTab>

  </Accordion>

</template>

<script>

import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import PaystubHeader from "./psn-form-accordion-header.vue"

export default {
  components: { Accordion, AccordionTab, PaystubHeader },
  props: ["finalStepText", "finalStepId", "finalStepCallback", "finalStepLoading"],
  emits: ["toggle"],

  data () {
    return {
      activeIndex: 0,
      expanded: {},
    }
  },

  methods: {
    tabToggled (isOpen, index) {
      this.$emit('toggle', isOpen, index);
    },

    getNextText (index) {
      if (index === this.tabs.length - 1)
        return this.finalStepText || "Next";
      return "Next";
    },

    validate () {
      for (let index = 0; index < this.tabs.length; index ++) {
        const inputs = [...this.$refs.forms[index].querySelectorAll("input, select")];
        const invalidInput = inputs.find(input => ! input.checkValidity());
        if (invalidInput) {
          this.activeIndex = index;
          setTimeout(() => invalidInput.reportValidity(), 900 )
          return false;
        }
      }

      return true;
    },

    submit (e, item, index) {
      if (index === this.tabs.length - 1) { //last item:
        if (! this.validate()) return;
        this.finalStepCallback();
        return;
      }

      //normal item:
      this.next(item, index);
    },

    next (item, index) {
      this.activeIndex = index + 1;
    },

    back (item, index) {
      this.activeIndex = index - 1;
    },
  },

  watch: {
    activeIndex () {
      setTimeout(() => {
        if (this.activeIndex == null) return;
        const item = this.$refs.paystubHeader[this.activeIndex].$el;
        item.scrollIntoView({behavior: "smooth"});
        this.tabToggled(true, this.activeIndex); //without this toggle doesn't trigger.
      }, 400);
    }
  },

  computed: {
    tabs () {
      const tabs = this.$slots.default().filter(s => s.type.name === 'PsnFormAccordionTab')
        .map((s, index) => ({tab: s, key: index, id: Math.random()}));
      return tabs;
    }
  },

}
</script>

<style>

</style>

<style scoped>
  :deep(.p-accordion-toggle-icon) {
    display: none !important;
  }

  .tab-content {
    @apply mx-3 md:mx-6 py-8
  }

  :deep(.p-accordion-header) {
    @apply mt-4;
  }
</style>
