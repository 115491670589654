<template>
  <div class="flex flex-row gap-4 md:gap-8 items-baseline w-full" ><!--@click="toggle(item, index)"-->
                
    <span class="bg-psn-blue w-8 h-8 pt-1 md:w-24 md:h-11 md:pt-2.5  
    uppercase text-center  text-white text-xl font-bold">
      <span class="hidden md:inline">Step </span>{{index}}
    </span>

    <h2 class="text-psn-light-header text-base md:text-2xl font-bold flex-grow">{{title}}</h2>

    <!-- beads -->
    <div class="relative" v-if="active"> <!--expanded[index]-->
      <div class="h-0 absolute top-1.5 md:top-3 w-full border-psn-blue border-t md:border"></div>
      
      <div class="grid grid-flow-col gap-2 md:gap-4 relative" :title="'Step ' + index + ' of ' + total">
        <span v-for="i in total" :key="i"            
          :class="'border-2 border-psn-blue rounded-full w-3 h-3 md:w-6 md:h-6 ' + getCellBgClass(i, index)">
        </span>
      </div>
      
    </div>

    <!-- expand arrow -->
    <div v-else class="cursor-pointer">
      <svg class="hidden md:inline-block" xmlns="http://www.w3.org/2000/svg" width="49.999" height="20" viewBox="0 0 49.999 20">
        <path d="M20,20,0,40V30L10,20,0,10V0Z" transform="translate(39.999) rotate(90)" fill="#00C0A2"></path>
      </svg>
      <svg class="md:hidden" xmlns="http://www.w3.org/2000/svg" width="30" height="15" viewBox="0 0 49.999 20">
        <path d="M20,20,0,40V30L10,20,0,10V0Z" transform="translate(39.999) rotate(90)" fill="#00C0A2"></path>
      </svg>
    </div>

  </div>
</template>

<script>
export default {
  props: ["index", "total", "title", "active"],

  methods: {
    getCellBgClass (beadIndex, index) {
      return beadIndex <= index ? "bg-psn-blue" : "bg-white";
    },
  },
}

</script>