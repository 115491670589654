//2022 provincial rates
export default {

  provinceTaxBrackets: {

    ON: [//Ontario
      { max: 46226,           rate: 5.05,     constant: 0 },
      { max: 92454,           rate: 9.15,     constant: 1895 },
      { max: 150000,          rate: 11.16,    constant: 3754 },
      { max: 220000,          rate: 12.16,    constant: 5254 },
      { max: 9999999999,      rate: 13.16,    constant: 7454 },
    ],
    
    QC: [ //Quebec
      { max: 46295,           rate: 15,       constant: 0 },
      { max: 92580,           rate: 20,       constant: 2314 },
      { max: 112655,          rate: 24,       constant: 6017 },
      { max: 9999999999,      rate: 25.75,    constant: 7989 },
    ],

    NS: [ //Nova Scotia 
        { max: 29590,          rate: 8.79,     constant: 0 },
        { max: 59180,          rate: 14.95,    constant: 1823 },
        { max: 93000,          rate: 16.67,    constant: 2841 },
        { max: 150000,         rate: 17.5,     constant: 3613 },
        { max: 9999999999,     rate: 21,       constant: 8863 },
    ],

    NB: [ //New Brunswick 
        { max: 44887,          rate: 9.40,     constant: 0 },
        { max: 89775,          rate: 14.82,    constant: 2433 },
        { max: 145955,         rate: 16.52,    constant: 3959 },
        { max: 166280,         rate: 17.84,    constant: 5886 },
        { max: 9999999999,     rate: 20.3,     constant: 9976 },
    ],

    MB: [ //Manitoba 
        { max: 34431,          rate: 10.8,     constant: 0 },
        { max: 74416,          rate: 12.75,    constant: 671 },
        { max: 9999999999,     rate: 17.4,     constant: 4132 },
    ],

    BC: [ //British Columbia
        { max: 43070,          rate: 5.06,     constant: 0 },
        { max: 86141,          rate: 7.7,      constant: 1137 },
        { max: 98901,          rate: 10.5,     constant: 3548 },
        { max: 120094,         rate: 12.29,    constant: 5319 },
        { max: 162832,         rate: 14.7,     constant: 8214 },
        { max: 227091,         rate: 16.8,     constant: 11633 },
        { max: 9999999999,     rate: 20.5,     constant: 20035 },
    ],

    PE: [ //Prince Edward Island 
        { max: 31984,          rate: 9.8,      constant: 0 },
        { max: 63969,          rate: 13.8,     constant: 1279 },
        { max: 9999999999,     rate: 16.7,     constant: 3134 },
    ],

    SK: [ //Saskatchewan
        { max: 46773,          rate: 10.5,     constant: 0 },
        { max: 133638,         rate: 12.5,     constant: 935 },
        { max: 9999999999,     rate: 14.5,     constant: 3608 },
    ],

    AB: [ //Alberta
        { max: 131220,           rate: 10,      constant: 0 },
        { max: 157464,           rate: 12,      constant: 1851 },
        { max: 209952,           rate: 13,      constant: 3666 },
        { max: 314928,           rate: 14,      constant: 5166 },
        { max: 9999999999,       rate: 15,      constant: 7366 },
  ],

    NL: [ //Newfoundland and Labrador //There were 5 tax brackets in 2021, they increased to 8 in 2022
        { max: 39147,           rate: 8.7,      constant: 0 },
        { max: 78294,           rate: 14.5,     constant: 2271 },
        { max: 139780,          rate: 15.8,     constant: 3288 },
        { max: 195693,          rate: 17.8,     constant: 6084 },
        { max: 250000,          rate: 19.8,     constant: 9998 },
        { max: 500000,          rate: 20,       constant: 12498 },
        { max: 1000000,         rate: 21.3,     constant: 14998 },
        { max: 9999999999,      rate: 21.8,     constant: 19998 },
    ],

    NT: [ //Northwest Territories
        { max: 45462,            rate: 5.9,     constant: 0 },
        { max: 90927,            rate: 8.6,     constant: 1227 },
        { max: 147826,           rate: 12.2,    constant: 4501 },
        { max: 9999999999,       rate: 14.05,   constant: 7236 },
    ],

    YT: [ //Yukon
        { max: 50197,           rate: 6.4,      constant: 0 },
        { max: 100392,          rate: 9,        constant: 1305 },
        { max: 155625,          rate: 10.9,     constant: 3213 },
        { max: 500000,          rate: 12.8,     constant: 6169 },
        { max: 9999999999,      rate: 15,       constant: 17169 },
    ],

    NU: [ //Nunavut
        { max: 47862,            rate: 4,       constant: 0 },
        { max: 95724,            rate: 7,       constant: 1436 },
        { max: 155625,           rate: 9,       constant: 3350 },
        { max: 9999999999,       rate: 11.5,    constant: 7241 },
    ],
  },

  provinceMinTaxable: {
    ON: 17290, //Ontario
    QC: 0, //Quebec //not yet provided
    NS: 12144, //Nova Scotia
    NB: 11450.5, //New Brunswick
    MB: 10727.6, //Manitoba
    BC: 20630, //British Columbia
    PE: 11918.4, //Prince Edward Island
    SK: 17703.4, //Saskatchewan
    AB: 20675, //Alberta
    NL: 10358.4, //Newfoundland and Labrador
    NT: 16614, //Northwest Territories
    YT: 15314, //Yukon
    NU: 17973.8, //Nunavut
  },

  provincialSurtaxBrackets: {
    PE: [
      { max: 12500, rate: 0, constant: 0 },
      { max: 9999999999, rate: 10, constant: 0 },
    ],

    ON: [
      { max: 4991, rate: 0, constant: 0 },
      { max: 6387, rate: 20, constant: 0 }, 
      { max: 9999999999, rate: 36, constant: 279.2 }, //Found by (6387 * 0,2) This constant amount will be added to ((basic payable tax - 6387) * 0,36)
    ],
  },

  provincialHealthPremiumBrackets: { //health premium only available in Ontario. Constant amounts are fixed amounts, no percentages.
    ON: [
      { max: 20000,       constant: 0 },
      { max: 36000,       constant: 300 },
      { max: 48000,       constant: 450 },
      { max: 72000,       constant: 600 },
      { max: 200000,      constant: 750 },
      { max: 9999999999,  constant: 900 },
    ]
  },

  provincialTaxReductionBrackets: { //only available in BC. Reduction will be lower as income raises.
    BC: [
      { max: 21867,       rate: 0,    constant: 491 },//Tax reduction amount is 491 fixed in this bracket
      { max: 35659,       rate: 3.56, constant: 0 }, //Tax Reduction in this bracket is 491 - ((35.659 - yearlyRegular) * 0,0356)
      { max: 9999999999,  rate: 0,    constant: 0 },//No tax reduction in this bracket
    ]
  },

}