<template>
  <div class="font-proxima relative -z-40">

    <section class="mt-4 lg:mt-16 container mx-auto px-4">

      <div class="relative min-h-[80] lg:min-h-[700px] 2xl:w-full">
        
        <div class="z-0 image-5xx absolute mt-16 xl:-mt-8"></div>

        <h1 class="relative z-10 text-center text-4xl lg:text-6xl text-psn-green-text font-bold">5xx Server Error</h1>


        <div class="relative z-20 mt-72 lg:mt-0 text-center lg:text-left xl:ml-48 2xl:ml-52">
          <h2 class="content-h2">Oops!</h2>
          <p class="font-georgia italic text-psn-light-text mt-5 text-xl md:text-2xl">Something went wrong.</p>

          <p class="mt-8 text-base text-psn-light-text max-w-sm mx-auto lg:mx-0">Sorry, we are fixing the issue. Please try again at a different time.</p>
        </div>

      </div>

    </section>
  </div>
</template>

<style>
  .image-5xx {
    background-image: url("@/assets/images/5xx.webp");
    @apply bg-no-repeat bg-center lg:bg-right w-full bg-contain h-72 lg:h-[600px];
  }
</style>
