<template>

  <input type="text" :pattern="mp.pattern" autocomplete="postal-code" name="zip"
    :placeholder="ph" v-model="mv" v-if="! country || country === 'us'" :class="cls"
    :required="required" />

  <InputMask v-model="mv" :required="required" v-if="country === 'ca'" :class="cls"
    :placeholder="ph" :mask="mp.mask" :pattern="mp.pattern"
    @blur="blur"
    :auto-clear="false" autocomplete="postal-code" name="postalCode" />

  <input type="text" :pattern="mp.pattern" autocomplete="postal-code" name="postcode"
    :placeholder="ph" v-model="mv" v-if="country === 'uk'" :class="cls"
    :required="required" />

</template>

<script>
import InputMask from "primevue/inputmask";

export default {
  components: { InputMask },

  props: ["modelValue", "country", "required", "class"],

  data () {
    return {
      mv: this.modelValue,
    }
  },

  methods: {
    blur () {
      this.mv = this.mv.toUpperCase();
      this.$emit('update:modelValue', this.mv.toUpperCase());
      this.$forceUpdate();
    }
  },

  computed: {
    ph () {
      let name = {
        us: "Zip code",
        ca: "Postal code / Code postal",
        uk: "Postcode",
      }[this.country || 'us'];

      if (this.required !== undefined && this.required !== false) name += " *";
      return name;
    },

    mp () {
      switch (this.country) {
        case 'ca': return {pattern: "[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]", mask: "a9a 9a9" };

        //https://stackoverflow.com/a/51885364
        case "uk": return {pattern: "[A-Z]{1,2}\\d[A-Z\\d]? ?\\d[A-Z]{2}"};

        //https://en.wikipedia.org/wiki/ZIP_Code (zip+4)
        case "us":
        default: return {pattern: "[0-9]{5}(-[0-9]{4})?"};
      }
    },

    cls () {
      return this.class || '';
    }
  },

  watch: {
    mv () {
      this.$emit('update:modelValue', this.mv.toUpperCase());
    },
  }

}
</script>