<template>

  <div>
    <p class="mb-4"><strong><span class="text-psn-blue">*</span> indicates required field.</strong></p>

    <div class="md:grid grid-flow-col grid-cols-2 md:divide-x-2 divide-gray-200">
      <!-- left side -->
      <div class="psn-paystub-company-side md:pr-16">
        <div>
          <psn-input-proper type="text" placeholder="Employer (Company) Name *" v-model="c.name" required data-hj-allow />
        </div>

        <div class="mb-4">
          <PhoneNumber placeholder="Employer Telephone Number" v-model="c.phone" country="us" v-if="country !== 'uk'" />
        </div>

        <div class="mb-4">
          <psn-input-proper type="text"
            :placeholder="'Street Address 1 ' + (country === 'us' ? '*' : '')" v-model="c.address1"
            :required="country === 'us'" />
        </div>

        <div class="mb-4">
          <psn-input-proper type="text" placeholder="Street Address 2" v-model="c.address2" />
        </div>
      </div>

      <!-- right side -->
      <div class="psn-paystub-company-side md:pl-16">

        <div class="mb-4">
          <psn-input-proper type="text"
            :placeholder="'City ' + (country === 'us' ? '*' : '')"
            v-model="c.city" :required="country === 'us'" data-hj-allow />
        </div>

        <div class="mb-4">
          <StateSelect v-model="c.state" :required="country === 'us'" :country="country" v-if="country !== 'uk'" />
        </div>

        <div class="mb-4">
          <PsnZip v-model="c.zip" :required="country === 'us'" class="w-full" :country="country" />
        </div>

      </div>

    </div>

  </div>

</template>

<script>
import StateSelect from "/src/components/psn-state-select";
import PhoneNumber from "/src/components/phone-number";
import PsnZip from "/src/components/psn-zip";

export default {

  components: { StateSelect, PhoneNumber, PsnZip },

  props: ["company", "country"],

  data () {
    return {
      c: {}
    };
  },

  watch: {
    company: {
      handler () {
        this.c = this.company;
      }, deep: true, immediate: true,
    },

    c: {
      handler () {
        this.$emit('update:company', this.c);
      }, deep: true,
    }
  },

}
</script>

<style scoped>
.psn-paystub-company-side > div {
  @apply mb-4;
}

.psn-paystub-company-side input, .psn-paystub-company-side select {
  @apply w-full;
}
</style>

