class PQueueItem {

  constructor (type, args) {
    this.type = type;
    this.args = args;
  }

}

class PQueue {
  constructor (dequeueCb) {
    this.batchNo = 0;
    this.items = [];
    this.lastItemPushedAt = new Date();
    this.timeoutInterval = 10; //ms
    this.timeout = null;
    this.running = true;
    this.dequeueCb = dequeueCb;
  }

  push (item) {
    item.batchNo = this.batchNo;
    this.items.push(item);

    if (new Date() - this.lastItemPushedAt < this.timeoutInterval) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.dequeueCb(this.items);
      this.batchNo++;
    }, this.timeoutInterval);

    this.lastItemPushedAt = new Date();
  }

  run () {

  }

  log () {
    console.log("batch: ", this.batchNo, ", ", this.items.length + " items.");
    this.items.forEach((item, index) => console.log("#" + index, item));
  }

}

export {
  PQueue, PQueueItem
}