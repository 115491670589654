/* 2022-23 country tax rates

ENG: England
NIR: Northern Ireland
WLS: Wales
SCT: Scotland

 */

export default {

  incomeTaxBrackets: {

    ENG: [ //England
      { max: 12570,       rate: 0,    plus: 0     },
      { max: 50270,       rate: 20,   plus: 0     },
      { max: 150000,      rate: 40,   plus: 10054 },
      { max: 9999999999,  rate: 45,   plus: 49946 },
    ],

    NIR: [ //Northern Ireland
      { max: 12570,       rate: 0,    plus: 0     },
      { max: 50270,       rate: 20,   plus: 0     },
      { max: 150000,      rate: 40,   plus: 10054 },
      { max: 9999999999,  rate: 45,   plus: 49946 },
    ],

    WLS: [ //Wales
      { max: 12570,       rate: 0,    plus: 0     },
      { max: 50270,       rate: 20,   plus: 0     },
      { max: 150000,      rate: 40,   plus: 10054 },
      { max: 9999999999,  rate: 45,   plus: 49946 },
    ],

    SCT: [ //Scotland
      { max: 12570,       rate: 0,    plus: 0       },
      { max: 14732,       rate: 19,   plus: 0       },
      { max: 25688,       rate: 20,   plus: 410     },
      { max: 43662,       rate: 21,   plus: 2601    },
      { max: 150000,      rate: 41,   plus: 6376    },
      { max: 9999999999,  rate: 46,   plus: 49975   },
    ],

  },

  NIClass1Brackets: { //national insurance rates based on the category letter selected
    employer: { 
      weekly:  {
        A: [
          { max: 175,         rate: 0,      plus: 0     },
          { max: 481 ,        rate: 15.05,  plus: 0     },
          { max: 967,         rate: 15.05,  plus: 46    },
          { max: 9999999999,  rate: 15.05,  plus: 119   },
        ],
      
        B: [
          { max: 175,         rate: 0,      plus: 0     },
          { max: 481 ,        rate: 15.05,  plus: 0     },
          { max: 967,         rate: 15.05,  plus: 46    },
          { max: 9999999999,  rate: 15.05,  plus: 119   },
        ],
      
        C: [
          { max: 175,         rate: 0,      plus: 0   },
          { max: 481 ,        rate: 15.05,  plus: 0   },
          { max: 967,         rate: 15.05,  plus: 46  },
          { max: 9999999999,  rate: 15.05,  plus: 119 },
        ],
      
        F: [
          { max: 175,         rate: 0,      plus: 0   },
          { max: 481 ,        rate: 0,      plus: 0   },
          { max: 967,         rate: 15.05,  plus: 0   },
          { max: 9999999999,  rate: 15.05,  plus: 73  },
        ],
      
        H: [
          { max: 175,         rate: 0,      plus: 0   },
          { max: 481 ,        rate: 0,      plus: 0   },
          { max: 967,         rate: 0,      plus: 0   },
          { max: 9999999999,  rate: 15.05,  plus: 0   },
        ],
      
        I: [
          { max: 175,         rate: 0,      plus: 0   },
          { max: 481 ,        rate: 0,      plus: 0   },
          { max: 967,         rate: 15.05,  plus: 0   },
          { max: 9999999999,  rate: 15.05,  plus: 73  },
        ],
      
        J: [
          { max: 175,         rate: 0,      plus: 0   },
          { max: 481 ,        rate: 15.05,  plus: 0   },
          { max: 967,         rate: 15.05,  plus: 46  },
          { max: 9999999999,  rate: 15.05,  plus: 119 },
        ],
      
        L: [
          { max: 175,         rate: 0,      plus: 0   },
          { max: 481 ,        rate: 0,      plus: 0   },
          { max: 967,         rate: 15.05,  plus: 0   },
          { max: 9999999999,  rate: 15.05,  plus: 73  },
        ],
      
        M: [
          { max: 175,         rate: 0,      plus: 0   },
          { max: 481 ,        rate: 0,      plus: 0   },
          { max: 967,         rate: 0,      plus: 0   },
          { max: 9999999999,  rate: 15.05,  plus: 0   },
        ],
      
        S: [
          { max: 175,         rate: 0,      plus: 0   },
          { max: 481 ,        rate: 0,      plus: 0   },
          { max: 967,         rate: 15.05,  plus: 0   },
          { max: 9999999999,  rate: 15.05,  plus: 73  },
        ],
      
        V: [
          { max: 175,         rate: 0,      plus: 0   },
          { max: 481 ,        rate: 0,      plus: 0   },
          { max: 967,         rate: 0,      plus: 0   },
          { max: 9999999999,  rate: 15.05,  plus: 0   },
        ],
      
        X: [
          { max: 9999999999,  rate: 0,      plus: 0 },
        ],
      
        Z: [
          { max: 175,         rate: 0,      plus: 0   },
          { max: 481 ,        rate: 0,      plus: 0   },
          { max: 967,         rate: 0,      plus: 0   },
          { max: 9999999999,  rate: 15.05,  plus: 0   },
        ]
      },

      monthly: {
        A: [
          { max: 758,         rate: 0,      plus: 0   },
          { max: 2083,        rate: 15.05,  plus: 0   },
          { max: 4189,        rate: 15.05,  plus: 199 },
          { max: 9999999999,  rate: 15.05,  plus: 516 },
        ],

        B: [
          { max: 758,         rate: 0,      plus: 0   },
          { max: 2083,        rate: 15.05,  plus: 0   },
          { max: 4189,        rate: 15.05,  plus: 199 },
          { max: 9999999999,  rate: 15.05,  plus: 516 },
        ],

        C: [
          { max: 758,         rate: 0,      plus: 0   },
          { max: 2083,        rate: 15.05,  plus: 0   },
          { max: 4189,        rate: 15.05,  plus: 199 },
          { max: 9999999999,  rate: 15.05,  plus: 516 },
        ],

        F: [
          { max: 758,         rate: 0,      plus: 0   },
          { max: 2083,        rate: 0,      plus: 0   },
          { max: 4189,        rate: 15.05,  plus: 0   },
          { max: 9999999999,  rate: 15.05,  plus: 317 },
        ],

        H: [
          { max: 758,         rate: 0,      plus: 0 },
          { max: 2083,        rate: 0,      plus: 0 },
          { max: 4189,        rate: 0,      plus: 0 },
          { max: 9999999999,  rate: 15.05,  plus: 0 },
        ],

        I: [
          { max: 758,         rate: 0,      plus: 0   },
          { max: 2083,        rate: 0,      plus: 0   },
          { max: 4189,        rate: 15.05,  plus: 0   },
          { max: 9999999999,  rate: 15.05,  plus: 317 },
        ],

        J: [
          { max: 758,         rate: 0,      plus: 0   },
          { max: 2083,        rate: 15.05,  plus: 0   },
          { max: 4189,        rate: 15.05,  plus: 199 },
          { max: 9999999999,  rate: 15.05,  plus: 516 },
        ],

        L: [
          { max: 758,         rate: 0,      plus: 0   },
          { max: 2083,        rate: 15.05,  plus: 0   },
          { max: 4189,        rate: 15.05,  plus: 199 },
          { max: 9999999999,  rate: 15.05,  plus: 516 },
        ],

        M: [
          { max: 758,         rate: 0,      plus: 0 },
          { max: 2083,        rate: 0,      plus: 0 },
          { max: 4189,        rate: 0,      plus: 0 },
          { max: 9999999999,  rate: 15.05,  plus: 0 },
        ],

        S: [
          { max: 758,         rate: 0,      plus: 0   },
          { max: 2083,        rate: 0,      plus: 0   },
          { max: 4189,        rate: 15.05,  plus: 0   },
          { max: 9999999999,  rate: 15.05,  plus: 317 },
        ],

        V: [
          { max: 758,         rate: 0,      plus: 0 },
          { max: 2083,        rate: 0,      plus: 0 },
          { max: 4189,        rate: 0,      plus: 0 },
          { max: 9999999999,  rate: 15.05,  plus: 0 },
        ],

        X: [
          { max: 9999999999,  rate: 0,      plus: 0 },
        ],

        Z: [
          { max: 758,         rate: 0,      plus: 0 },
          { max: 2083,        rate: 0,      plus: 0 },
          { max: 4189,        rate: 0,      plus: 0 },
          { max: 9999999999,  rate: 15.05,  plus: 0 },
        ]
      }
    },

    employee: { 
      weekly:  {
        A: [
          { max: 242,         rate: 0,      plus: 0   },
          { max: 967 ,        rate: 13.25,  plus: 0   },
          { max: 9999999999,  rate: 3.25,   plus: 96  },
        ],
      
        B: [
          { max: 242,         rate: 0,      plus: 0   },
          { max: 967 ,        rate: 7.1,    plus: 0   },
          { max: 9999999999,  rate: 3.25,   plus: 51  },
        ],
      
        C: [
          { max: 9999999999,  rate: 0,      plus: 0 },
        ],
      
        F: [
          { max: 242,         rate: 0,      plus: 0   },
          { max: 967 ,        rate: 13.25,  plus: 0   },
          { max: 9999999999,  rate: 3.25,   plus: 96  },
        ],
      
        H: [
          { max: 242,         rate: 0,      plus: 0   },
          { max: 967 ,        rate: 13.25,  plus: 0   },
          { max: 9999999999,  rate: 3.25,   plus: 96  },
        ],
      
        I: [
          { max: 242,         rate: 0,      plus: 0   },
          { max: 967 ,        rate: 7.1,    plus: 0   },
          { max: 9999999999,  rate: 3.25,   plus: 51  },
        ],
      
        J: [
          { max: 242,         rate: 0,      plus: 0   },
          { max: 967,         rate: 3.25,   plus: 0   },
          { max: 9999999999,  rate: 3.25,   plus: 24  },
        ],
      
        L: [
          { max: 242,         rate: 0,      plus: 0   },
          { max: 967,         rate: 3.25,   plus: 0   },
          { max: 9999999999,  rate: 3.25,   plus: 24  },
        ],
      
        M: [
          { max: 242,         rate: 0,      plus: 0   },
          { max: 967 ,        rate: 13.25,  plus: 0   },
          { max: 9999999999,  rate: 3.25,   plus: 96  },
        ],
      
        S: [
          { max: 9999999999,  rate: 0,      plus: 0 },
        ],
      
        V: [
          { max: 242,         rate: 0,      plus: 0   },
          { max: 967 ,        rate: 13.25,  plus: 0   },
          { max: 9999999999,  rate: 3.25,   plus: 96  },
        ],
      
        X: [
          { max: 9999999999,  rate: 0,      plus: 0 },
        ],
      
        Z: [
          { max: 242,         rate: 0,      plus: 0   },
          { max: 967,         rate: 3.25,   plus: 0   },
          { max: 9999999999,  rate: 3.25,   plus: 24  },
        ]
      },
    
      monthly: {
        A: [
          { max: 1048,        rate: 0,      plus: 0   },
          { max: 4189,        rate: 13.25,  plus: 0   },
          { max: 9999999999,  rate: 3.25,   plus: 416 },
        ],
      
        B: [
          { max: 1048,        rate: 0,      plus: 0   },
          { max: 4189,        rate: 7.1,    plus: 0   },
          { max: 9999999999,  rate: 3.25,   plus: 223 },
        ],
      
        C: [
          { max: 9999999999,  rate: 0,      plus: 0 },
        ],
      
        F: [
          { max: 1048,        rate: 0,      plus: 0   },
          { max: 4189,        rate: 13.25,  plus: 0   },
          { max: 9999999999,  rate: 3.25,   plus: 416 },
        ],
      
        H: [
          { max: 1048,        rate: 0,      plus: 0   },
          { max: 4189,        rate: 13.25,  plus: 0   },
          { max: 9999999999,  rate: 3.25,   plus: 416 },
        ],
      
        I: [
          { max: 1048,        rate: 0,      plus: 0   },
          { max: 4189,        rate: 7.1,    plus: 0   },
          { max: 9999999999,  rate: 3.25,   plus: 223 },
        ],
      
        J: [
          { max: 1048,        rate: 0,      plus: 0   },
          { max: 4189,        rate: 3.25,   plus: 0   },
          { max: 9999999999,  rate: 3.25,   plus: 102 },
        ],
      
        L: [
          { max: 1048,        rate: 0,      plus: 0   },
          { max: 4189,        rate: 3.25,   plus: 0   },
          { max: 9999999999,  rate: 3.25,   plus: 102 },
        ],
      
        M: [
          { max: 1048,        rate: 0,      plus: 0   },
          { max: 4189,        rate: 13.25,  plus: 0   },
          { max: 9999999999,  rate: 3.25,   plus: 416 },
        ],
      
        S: [
          { max: 9999999999,  rate: 0,      plus: 0 },
        ],
      
        V: [
          { max: 1048,        rate: 0,      plus: 0   },
          { max: 4189,        rate: 13.25,  plus: 0   },
          { max: 9999999999,  rate: 3.25,   plus: 416 },
        ],
      
        X: [
          { max: 9999999999,  rate: 0,      plus: 0 },
        ],
      
        Z: [
          { max: 1048,        rate: 0,      plus: 0   },
          { max: 4189,        rate: 3.25,   plus: 0   },
          { max: 9999999999,  rate: 3.25,   plus: 102 },
        ]
      }
    }
  },

  NIClass2Amounts: { //These are pound values, not rates!
    threshold: 6725, //They start paying above this threshold (yearly). Nothing below this.
    weekly: 3.15,
    monthly: 13.65,
  },

  NIClass4Rates: [
    { max: 11908, rate: 0,            plus: 0  },
    { max: 50270, rate: 10.25,        plus: 0  },
    { max: 9999999999, rate: 3.25,    plus: 3932.11  },
  ],  
}