
<template>

  <select :required="req" :id="htmlId" @change="$emit('change')" :value="modelValue" 
    @input="$emit('update:modelValue', $event.target.value)" :class="cls"
    autocomplete="address-level1"
    >
    
    <option v-for="s of states" :key="s.code" :value="s.code">{{s.state}}</option>

  </select>

</template>

<script>

export default {  

  props: ["modelValue", "label", "required", "cls", "country"],

  computed: {
    htmlId () {
      return "" + Math.random();
    },

    req () {
      return this.required || this.required !== undefined && !!this.required !== false;
    }, 

    states () {

      const all = {
        us: [
          {code: "", state: ""},
          {code: "AL", state: "Alabama"},
          {code: "AK", state: "Alaska"},
          {code: "AZ", state: "Arizona"},
          {code: "AR", state: "Arkansas"},
          {code: "CA", state: "California"},
          {code: "CO", state: "Colorado"},
          {code: "CT", state: "Connecticut"},
          {code: "DE", state: "Delaware"},
          {code: "DC", state: "District of Columbia"},
          {code: "FL", state: "Florida"},
          {code: "GA", state: "Georgia"},
          {code: "HI", state: "Hawaii"},
          {code: "ID", state: "Idaho"},
          {code: "IL", state: "Illinois"},
          {code: "IN", state: "Indiana"},
          {code: "IA", state: "Iowa"},
          {code: "KS", state: "Kansas"},
          {code: "KY", state: "Kentucky"},
          {code: "LA", state: "Louisiana"},
          {code: "ME", state: "Maine"},
          {code: "MD", state: "Maryland"},
          {code: "MA", state: "Massachusetts"},
          {code: "MI", state: "Michigan"},
          {code: "MN", state: "Minnesota"},
          {code: "MS", state: "Mississippi"},
          {code: "MO", state: "Missouri"},
          {code: "MT", state: "Montana"},
          {code: "NE", state: "Nebraska"},
          {code: "NV", state: "Nevada"},
          {code: "NH", state: "New Hampshire"},
          {code: "NJ", state: "New Jersey"},
          {code: "NM", state: "New Mexico"},
          {code: "NY", state: "New York"},
          {code: "NC", state: "North Carolina"},
          {code: "ND", state: "North Dakota"},
          {code: "OH", state: "Ohio"},
          {code: "OK", state: "Oklahoma"},
          {code: "OR", state: "Oregon"},
          {code: "PA", state: "Pennsylvania"},
          {code: "PR", state: "Puerto Rico"},
          {code: "RI", state: "Rhode Island"},
          {code: "SC", state: "South Carolina"},
          {code: "SD", state: "South Dakota"},
          {code: "TN", state: "Tennessee"},
          {code: "TX", state: "Texas"},
          {code: "UT", state: "Utah"},
          {code: "VT", state: "Vermont"},
          {code: "VA", state: "Virginia"},
          {code: "WA", state: "Washington"},
          {code: "WV", state: "West Virginia"},
          {code: "WI", state: "Wisconsin"},
          {code: "WY", state: "Wyoming"},
        ],
        ca: [
          {code: "", state: ""},
          {code: "AB", state: "Alberta"},
          {code: "BC", state: "British Columbia"},
          {code: "MB", state: "Manitoba"},
          {code: "NB", state: "New Brunswick"},
          {code: "NL", state: "Newfoundland and Labrador"},
          {code: "NT", state: "Northwest Territories"},
          {code: "NS", state: "Nova Scotia"},
          {code: "NU", state: "Nunavut"},
          {code: "ON", state: "Ontario"},
          {code: "PE", state: "Prince Edward Island"},
          {code: "QC", state: "Quebec"},
          {code: "SK", state: "Saskatchewan"},
          {code: "YT", state: "Yukon"},
        ],
        uk: [
          {code: "", state: ""},
          {code: "ENG", state: "England"},
          {code: "NIR", state: "Northern Ireland"},
          {code: "SCT", state: "Scotland"},
          {code: "WLS", state: "Wales"},
        ]
      };
 
      const country = this.country || "us";
      const states = all[country];
      let defaultStr = this.$localize(this.country, 'State');
      if (this.req) defaultStr += " *";      
      states[0].state = defaultStr;
      return states;
    }
  }
}
</script>
