<template>
  <div class="mx-auto" v-if="$route.name">

    <Nav />
    
    <router-view v-if="! $ajax.is5xx.value" />
    <Fivexx v-else />

    <Footer />
  </div>
</template>

<script>

import Nav from "./components/nav-header.vue";
import Footer from "./components/nav-footer.vue";
import Fivexx from "@/views/5xx.vue";

export default {
  components: { Nav, Footer, Fivexx },

  //root data:
  data () {
    return {
      country: null,
      products: null,
      ui: {
        countryProcessed: false,
      },
    }
  },

  methods: {
    async queryUserCountry () {
      const controller = new AbortController();
      const TIMEOUT = 400; //ms (other api provider was fine with 200)
      setTimeout(() => controller.abort(), TIMEOUT);

      const API_KEY = "ae0d1b893464c4892f36c8a63b2064bd";
      const API_URL = "https://api.ipapi.com/api/check";
      const fields = "country_code";
      const fetchURI = `${API_URL}?fields=${fields}&access_key=${API_KEY}`;
      try {
        const ret = await fetch(fetchURI, {signal: controller.signal});        
        if (! ret.ok) throw new Error("API err.");
        const res = await ret.json();        
        return res.country_code.toLowerCase();
      } catch (err) {
        console.error(err);
      }
      
      return null;
    },

    async processCountryRedirection () {      
      //localStorage.removeItem("country");
      const path = window.location.pathname;
      if (path !== '/') return; //only apply to root domain
      
      let storeCountry = "us";
      const defaultCountry = "us";

      const country = localStorage.getItem("country");
      if (country) {
        storeCountry = country;        
      } else { //fetch from api:
        try {       
          const retCountry = await this.queryUserCountry();
          storeCountry = ["us", "ca", "gb"].includes(retCountry) ? retCountry : defaultCountry;                    
        } catch (err) {    
          console.error(err); //prevent eslint complaints for empty      
        } 
      }

      localStorage.setItem("country", storeCountry);
      switch (storeCountry) {
        case "ca": this.$router.replace({name: "ca"}); break;
        case "gb": this.$router.replace({name: "uk"}); break;
        default: break; //no need to process us, we are already in /
      }    
    },

  },

  watch: {
    async "$route.meta.country" (country, oldCountry) {
      if (this.$route.meta.dontRedirect) return; // do not process 404 and 3dResult pages.
      
      if (! oldCountry) { //undefined oldCountry means the page freshly loaded
        await this.processCountryRedirection();
      } else { //user changed the country in the app        
        localStorage.setItem("country", country);
      }
    },
  },
}
</script>
