"use strict";

export default {

  brackets: {

    AL: {
      single: [
        { rate: 2, max: 500,            plus: 0   },
        { rate: 4, max: 3000,           plus: 10  },
        { rate: 5, max: 99999999999999, plus: 110 }
      ],
      married: [
        { rate: 2, max: 1000,           plus: 0   },
        { rate: 4, max: 6000,           plus: 20  },
        { rate: 5, max: 99999999999999, plus: 220 }
      ]
    },
    AK: { single: [], married: [] },
    AZ: {
      single: [
        { rate: 2.59, max: 27808,           plus: 0         },
        { rate: 3.34, max: 55615,           plus: 720.23    },
        { rate: 4.17, max: 166843,          plus: 1648.98   },
        { rate: 4.5,  max: 99999999999999,  plus: 6287.19   }
      ],
      married: [
        { rate: 2.59, max: 55615,           plus: 0         },
        { rate: 3.34, max: 111229,          plus: 1440.43   },
        { rate: 4.17, max: 333684,          plus: 3297.94   },
        { rate: 4.5,  max: 99999999999999,  plus: 12574.31  }
      ]
    },
    AR: {
      single: [
        { rate: 2,    max: 4000,            plus: 0   },
        { rate: 4,    max: 8000,            plus: 80  },
        { rate: 5.9,  max: 99999999999999,  plus: 240 }
      ],
      married: [
        { rate: 2,    max: 4000,            plus: 0   },
        { rate: 4,    max: 8000,            plus: 80  },
        { rate: 5.9,  max: 99999999999999,  plus: 240 }
      ]
    },
    CA: {
      single: [
        { rate: 1,    max: 9325,            plus: 0         },
        { rate: 2,    max: 22107,           plus: 93.25     },
        { rate: 4,    max: 34892,           plus: 348.89    },
        { rate: 6,    max: 48435,           plus: 860.29    },
        { rate: 8,    max: 61214,           plus: 1672.87   },
        { rate: 9.3,  max: 312686,          plus: 2695.19   },
        { rate: 10.3, max: 375221,          plus: 26082.09  },
        { rate: 11.3, max: 625369,          plus: 32523.19  },
        { rate: 12.3, max: 1000000,         plus: 60789.92  },
        { rate: 13.3, max: 99999999999999,  plus: 106869.53 }
      ],
      married: [
        { rate: 1,    max: 18650,           plus: 0         },
        { rate: 2,    max: 44214,           plus: 186.5     },
        { rate: 4,    max: 69784,           plus: 697.78    },
        { rate: 6,    max: 96870,           plus: 1720.58   },
        { rate: 8,    max: 122428,          plus: 3345.74   },
        { rate: 9.3,  max: 625372,          plus: 5390.38   },
        { rate: 10.3, max: 750442,          plus: 52164.17  },
        { rate: 11.3, max: 1000000,         plus: 65046.38  },
        { rate: 12.3, max: 1250738,         plus: 93246.44  },
        { rate: 13.3, max: 99999999999999,  plus: 124087.21 }
      ]
    },
    CO: {
      single: [   { rate: 4.5, max: 99999999999999, plus: 0 } ],
      married: [  { rate: 4.5, max: 99999999999999, plus: 0 } ]
    },
    CT: {
      single: [
        { rate: 3,    max: 10000,           plus: 0     },
        { rate: 5,    max: 50000,           plus: 300   },
        { rate: 5.5,  max: 100000,          plus: 2300  },
        { rate: 6,    max: 200000,          plus: 5050  },
        { rate: 6.5,  max: 250000,          plus: 11050 },
        { rate: 6.9,  max: 500000,          plus: 14300 },
        { rate: 6.99, max: 99999999999999,  plus: 31550 }
      ],
      married: [
        { rate: 3,    max: 20000,           plus: 0     },
        { rate: 5,    max: 100000,          plus: 600   },
        { rate: 5.5,  max: 200000,          plus: 4600  },
        { rate: 6,    max: 400000,          plus: 10100 },
        { rate: 6.5,  max: 500000,          plus: 22100 },
        { rate: 6.9,  max: 1000000,         plus: 28600 },
        { rate: 6.99, max: 99999999999999,  plus: 63100 }
      ]
    },
    DE: {
      single: [
        { rate: 0,    max: 2000,            plus: 0       },
        { rate: 2.2,  max: 5000,            plus: 0       },
        { rate: 3.9,  max: 10000,           plus: 66      },
        { rate: 4.8,  max: 20000,           plus: 261     },
        { rate: 5.2,  max: 25000,           plus: 741     },
        { rate: 5.55, max: 60000,           plus: 1001    },
        { rate: 6.6,  max: 99999999999999,  plus: 2943.5  }
      ],
      married: [
        { rate: 0,    max: 2000,            plus: 0       },
        { rate: 2.2,  max: 5000,            plus: 0       },
        { rate: 3.9,  max: 10000,           plus: 66      },
        { rate: 4.8,  max: 20000,           plus: 261     },
        { rate: 5.2,  max: 25000,           plus: 741     },
        { rate: 5.55, max: 60000,           plus: 1001    },
        { rate: 6.6,  max: 99999999999999,  plus: 2943.5  }
      ]
    },
    DC: {
      single: [
        { rate: 4,    max: 10000,           plus: 0     },
        { rate: 6,    max: 40000,           plus: 400   },
        { rate: 6.5,  max: 60000,           plus: 2200  },
        { rate: 8.5,  max: 350000,          plus: 3500  },
        { rate: 8.75, max: 1000000,         plus: 28150 },
        { rate: 8.95, max: 99999999999999,  plus: 85025 }
      ],
      married: [
        { rate: 4,    max: 10000,           plus: 0     },
        { rate: 6,    max: 40000,           plus: 400   },
        { rate: 6.5,  max: 60000,           plus: 2200  },
        { rate: 8.5,  max: 350000,          plus: 3500  },
        { rate: 8.75, max: 1000000,         plus: 28150 },
        { rate: 8.95, max: 99999999999999,  plus: 85025 }
      ]
    },
    FL: { single: [], married: [] },
    GA: {
      single: [
        { rate: 1,    max: 750,             plus: 0     },
        { rate: 2,    max: 2250,            plus: 7.5   },
        { rate: 3,    max: 3750,            plus: 37.5  },
        { rate: 4,    max: 5250,            plus: 82.5  },
        { rate: 5,    max: 7000,            plus: 142.5 },
        { rate: 5.75, max: 99999999999999,  plus: 230   }
      ],
      married: [
        { rate: 1,    max: 750,             plus: 0     },
        { rate: 2,    max: 2250,            plus: 7.5   },
        { rate: 3,    max: 3750,            plus: 37.5  },
        { rate: 4,    max: 5250,            plus: 82.5  },
        { rate: 5,    max: 7000,            plus: 142.5 },
        { rate: 5.75, max: 99999999999999,  plus: 230   }
      ]
    },
    HI: {
      single: [
        { rate: 1.4,  max: 2400,            plus: 0       },
        { rate: 3.2,  max: 4800,            plus: 33.6    },
        { rate: 5.5,  max: 9600,            plus: 110.4   },
        { rate: 6.4,  max: 14400,           plus: 374.4   },
        { rate: 6.8,  max: 19200,           plus: 681.6   },
        { rate: 7.2,  max: 24000,           plus: 1008    },
        { rate: 7.6,  max: 36000,           plus: 1353.6  },
        { rate: 7.9,  max: 48000,           plus: 2265.6  },
        { rate: 8.25, max: 150000,          plus: 3213.6  },
        { rate: 9,    max: 175000,          plus: 11628.6 },
        { rate: 10,   max: 200000,          plus: 13878.6 },
        { rate: 11,   max: 99999999999999,  plus: 16378.6 }
      ],
      married: [
        { rate: 1.4,  max: 4800,            plus: 0       },
        { rate: 3.2,  max: 9600,            plus: 67.2    },
        { rate: 5.5,  max: 19200,           plus: 220.8   },
        { rate: 6.4,  max: 28800,           plus: 748.8   },
        { rate: 6.8,  max: 38400,           plus: 1363.2  },
        { rate: 7.2,  max: 48000,           plus: 2016    },
        { rate: 7.6,  max: 72000,           plus: 2707.2  },
        { rate: 7.9,  max: 96000,           plus: 4531.2  },
        { rate: 8.25, max: 300000,          plus: 6427.2  },
        { rate: 9,    max: 350000,          plus: 23257.2 },
        { rate: 10,   max: 400000,          plus: 27757.2 },
        { rate: 11,   max: 99999999999999,  plus: 32757.2 }
      ]
    },
    ID: {
      single: [
        { rate: 1,    max: 1588,            plus: 0       },
        { rate: 3.1,  max: 4763,            plus: 15.88   },
        { rate: 4.5,  max: 6351,            plus: 114.31  },
        { rate: 5.5,  max: 7939,            plus: 185.77  },
        { rate: 6.5,  max: 99999999999999,  plus: 273.11  }
      ],
      married: [
        { rate: 1,    max: 3176,            plus: 0       },
        { rate: 3.1,  max: 9526,            plus: 31.76   },
        { rate: 4.5,  max: 12702,           plus: 228.61  },
        { rate: 5.5,  max: 15878,           plus: 371.53  },
        { rate: 6.5,  max: 99999999999999,  plus: 546.21  }
      ]
    },
    IL: {
      single: [   { rate: 4.95, max: 99999999999999, plus: 0 } ],
      married: [  { rate: 4.95, max: 99999999999999, plus: 0 } ]
    },
    IN: {
      single: [   { rate: 3.23, max: 99999999999999, plus: 0 } ],
      married: [  { rate: 3.23, max: 99999999999999, plus: 0 } ]
    },
    IA: {
      single: [
        { rate: 0.33, max: 1676,            plus: 0       },
        { rate: 0.67, max: 3352,            plus: 5.53    },
        { rate: 2.25, max: 6704,            plus: 16.76   },
        { rate: 4.14, max: 15084,           plus: 92.18   },
        { rate: 5.63, max: 25140,           plus: 439.11  },
        { rate: 5.96, max: 33520,           plus: 1005.26 },
        { rate: 6.25, max: 50280,           plus: 1504.71 },
        { rate: 7.44, max: 75420,           plus: 2552.21 },
        { rate: 8.53, max: 99999999999999,  plus: 4422.63 }
      ],
      married: [
        { rate: 0.33, max: 1676,            plus: 0       },
        { rate: 0.67, max: 3352,            plus: 5.53    },
        { rate: 2.25, max: 6704,            plus: 16.76   },
        { rate: 4.14, max: 15084,           plus: 92.18   },
        { rate: 5.63, max: 25140,           plus: 439.11  },
        { rate: 5.96, max: 33520,           plus: 1005.26 },
        { rate: 6.25, max: 50280,           plus: 1504.71 },
        { rate: 7.44, max: 75420,           plus: 2552.21 },
        { rate: 8.53, max: 99999999999999,  plus: 4422.63 }
      ]
    },
    KS: {
      single: [
        { rate: 3.1,  max: 15000,           plus: 0       },
        { rate: 5.25, max: 30000,           plus: 465     },
        { rate: 5.7,  max: 99999999999999,  plus: 1252.5  }
      ],
      married: [
        { rate: 3.1,  max: 30000,           plus: 0     },
        { rate: 5.25, max: 60000,           plus: 930   },
        { rate: 5.7,  max: 99999999999999,  plus: 2505  }
      ]
    },
    KY: {
      single: [   { rate: 5, max: 99999999999999, plus: 0 } ],
      married: [  { rate: 5, max: 99999999999999, plus: 0 } ]
    },
    LA: {
      single: [
        { rate: 2, max: 12500,          plus: 0     },
        { rate: 4, max: 50000,          plus: 250   },
        { rate: 6, max: 99999999999999, plus: 1750  }
      ],
      married: [
        { rate: 2, max: 25000,          plus: 0     },
        { rate: 4, max: 100000,         plus: 500   },
        { rate: 6, max: 99999999999999, plus: 3500  }
      ]
    },
    ME: {
      single: [
        { rate: 5.8,  max: 22450,           plus: 0       },
        { rate: 6.75, max: 53150,           plus: 1302.1  },
        { rate: 7.15, max: 99999999999999,  plus: 3374.35 }
      ],
      married: [
        { rate: 5.8,  max: 44950,           plus: 0       },
        { rate: 6.75, max: 106350,          plus: 2607.1  },
        { rate: 7.15, max: 99999999999999,  plus: 6751.6  }
      ]
    },
    MD: {
      single: [
        { rate: 2,    max: 1000,            plus: 0       },
        { rate: 3,    max: 2000,            plus: 20      },
        { rate: 4,    max: 3000,            plus: 50      },
        { rate: 4.75, max: 100000,          plus: 90      },
        { rate: 5,    max: 125000,          plus: 4697.5  },
        { rate: 5.25, max: 150000,          plus: 5947.5  },
        { rate: 5.5,  max: 250000,          plus: 7260    },
        { rate: 5.75, max: 99999999999999,  plus: 12760   }
      ],
      married: [
        { rate: 2,    max: 1000,            plus: 0       },
        { rate: 3,    max: 2000,            plus: 20      },
        { rate: 4,    max: 3000,            plus: 50      },
        { rate: 4.75, max: 150000,          plus: 90      },
        { rate: 5,    max: 175000,          plus: 7072.5  },
        { rate: 5.25, max: 225000,          plus: 8322.5  },
        { rate: 5.5,  max: 300000,          plus: 10947.5 },
        { rate: 5.75, max: 99999999999999,  plus: 15072.5 }
      ]
    },
    MA: {
      single: [   { rate: 5, max: 99999999999999, plus: 0 } ],
      married: [  { rate: 5, max: 99999999999999, plus: 0 } ]
    },
    MI: {
      single: [   { rate: 4.25, max: 99999999999999, plus: 0 } ],
      married: [  { rate: 4.25, max: 99999999999999, plus: 0 } ]
    },
    MN: {
      single: [
        { rate: 5.35, max: 27230,           plus: 0         },
        { rate: 6.8,  max: 89440,           plus: 1456.81   },
        { rate: 7.85, max: 166040,          plus: 5687.09   },
        { rate: 9.85, max: 99999999999999,  plus: 11700.19  }
      ],
      married: [
        { rate: 5.35, max: 39810,           plus: 0 },
        { rate: 6.8,  max: 158140,          plus: 2129.84   },
        { rate: 7.85, max: 276200,          plus: 10176.28  },
        { rate: 9.85, max: 99999999999999,  plus: 19443.99  }
      ]
    },
    MS: {
      single: [
        { rate: 0, max: 4000,           plus: 0   },
        { rate: 3, max: 5000,           plus: 0   },
        { rate: 4, max: 10000,          plus: 30  },
        { rate: 5, max: 99999999999999, plus: 230 }
      ],
      married: [
        { rate: 0, max: 4000,           plus: 0   },
        { rate: 3, max: 5000,           plus: 0   },
        { rate: 4, max: 10000,          plus: 30  },
        { rate: 5, max: 99999999999999, plus: 230 }
      ]
    },
    MO: {
      single: [
        { rate: 0,    max: 108,             plus: 0       },
        { rate: 1.5,  max: 1088,            plus: 0       },
        { rate: 2,    max: 2176,            plus: 14.7    },
        { rate: 2.5,  max: 3264,            plus: 36.46   },
        { rate: 3,    max: 4352,            plus: 63.66   },
        { rate: 3.5,  max: 5440,            plus: 96.3    },
        { rate: 4,    max: 6528,            plus: 134.38  },
        { rate: 4.5,  max: 7616,            plus: 177.9   },
        { rate: 5,    max: 8704,            plus: 226.86  },
        { rate: 5.4,  max: 99999999999999,  plus: 281.26  }
      ],
      married: [
        { rate: 0,    max: 108,             plus: 0       },
        { rate: 1.5,  max: 1088,            plus: 0       },
        { rate: 2,    max: 2176,            plus: 14.7    },
        { rate: 2.5,  max: 3264,            plus: 36.46   },
        { rate: 3,    max: 4352,            plus: 63.66   },
        { rate: 3.5,  max: 5440,            plus: 96.3    },
        { rate: 4,    max: 6528,            plus: 134.38  },
        { rate: 4.5,  max: 7616,            plus: 177.9   },
        { rate: 5,    max: 8704,            plus: 226.86  },
        { rate: 5.4,  max: 99999999999999,  plus: 281.26  }
      ]
    },
    MT: {
      single: [
        { rate: 1,    max: 3100,            plus: 0   },
        { rate: 2,    max: 5500,            plus: 31  },
        { rate: 3,    max: 8400,            plus: 79  },
        { rate: 4,    max: 11400,           plus: 166 },
        { rate: 5,    max: 14600,           plus: 286 },
        { rate: 6,    max: 18800,           plus: 446 },
        { rate: 6.9,  max: 99999999999999,  plus: 698 }
      ],
      married: [
        { rate: 1,    max: 3100,            plus: 0   },
        { rate: 2,    max: 5500,            plus: 31  },
        { rate: 3,    max: 8400,            plus: 79  },
        { rate: 4,    max: 11400,           plus: 166 },
        { rate: 5,    max: 14600,           plus: 286 },
        { rate: 6,    max: 18800,           plus: 446 },
        { rate: 6.9,  max: 99999999999999,  plus: 698 }
      ]
    },
    NE: {
      single: [
        { rate: 2.46, max: 3340,            plus: 0       },
        { rate: 3.51, max: 19990,           plus: 82.16   },
        { rate: 5.01, max: 32210,           plus: 666.58  },
        { rate: 6.84, max: 99999999999999,  plus: 1278.80 }
      ],
      married: [
        { rate: 2.46, max: 6660,            plus: 0       },
        { rate: 3.51, max: 39990,           plus: 163.84  },
        { rate: 5.01, max: 64430,           plus: 1333.72 },
        { rate: 6.84, max: 99999999999999,  plus: 2558.16 }
      ]
    },
    NV: { single: [], married: [] },
    NH: { single: [], married: [] },
    NJ: {
      single: [
        { rate: 1.4,    max: 20000,           plus: 0         },
        { rate: 1.75,   max: 35000,           plus: 280       },
        { rate: 3.5,    max: 40000,           plus: 542.5     },
        { rate: 5.525,  max: 75000,           plus: 717.5     },
        { rate: 6.37,   max: 500000,          plus: 2651.25   },
        { rate: 8.97,   max: 1000000,         plus: 29723.75  },
        { rate: 10.75,  max: 99999999999999,  plus: 74573.75  }
      ],
      married: [
        { rate: 1.4,    max: 20000,           plus: 0         },
        { rate: 1.75,   max: 50000,           plus: 280       },
        { rate: 2.45,   max: 70000,           plus: 805       },
        { rate: 3.5,    max: 80000,           plus: 1295      },
        { rate: 5.525,  max: 150000,          plus: 1645      },
        { rate: 6.37,   max: 500000,          plus: 5512.5    },
        { rate: 8.97,   max: 1000000,         plus: 27807.5   },
        { rate: 10.75,  max: 99999999999999,  plus: 72657.5   }
      ]
    },
    NM: {
      single: [
        { rate: 1.7, max: 5500,           plus: 0       },
        { rate: 3.2, max: 11000,          plus: 93.5    },
        { rate: 4.7, max: 16000,          plus: 269.5   },
        { rate: 4.9, max: 210000,         plus: 504.5   },
        { rate: 5.9, max: 99999999999999, plus: 10010.5 }
      ],
      married: [
        { rate: 1.7, max: 8000,           plus: 0     },
        { rate: 3.2, max: 16000,          plus: 136   },
        { rate: 4.7, max: 24000,          plus: 392   },
        { rate: 4.9, max: 315000,         plus: 768   },
        { rate: 5.9, max: 99999999999999, plus: 15027 }
      ]
    },
    NY: {
      single: [
        { rate: 4,    max: 8500,            plus: 0         },
        { rate: 4.5,  max: 11700,           plus: 340       },
        { rate: 5.25, max: 13900,           plus: 484       },
        { rate: 5.9,  max: 21400,           plus: 599.5     },
        { rate: 5.97, max: 80650,           plus: 1042      },
        { rate: 6.33, max: 215400,          plus: 4579.23   },
        { rate: 6.85, max: 1077550,         plus: 13108.9   },
        { rate: 9.65, max: 5000000,         plus: 72166.18  },
        { rate: 10.3, max: 25000000,        plus: 450682.6  },
        { rate: 10.9, max: 99999999999999,  plus: 2510682.6 }
      ],
      married: [
        { rate: 4,    max: 17150,           plus: 0           },
        { rate: 4.5,  max: 23600,           plus: 686         },
        { rate: 5.25, max: 27900,           plus: 976.25      },
        { rate: 5.9,  max: 43000,           plus: 1202        },
        { rate: 5.97, max: 161550,          plus: 2092.9      },
        { rate: 6.33, max: 323200,          plus: 9170.34     },
        { rate: 6.85, max: 2155350,         plus: 19402.78    },
        { rate: 9.65, max: 5000000,         plus: 144905.06   },
        { rate: 10.3, max: 25000000,        plus: 419413.78   },
        { rate: 10.9, max: 99999999999999,  plus: 2479413.78  }
      ]
    },
    NC: {
      single: [   { rate: 5.25, max: 99999999999999, plus: 0 } ],
      married: [  { rate: 5.25, max: 99999999999999, plus: 0 } ]
    },
    ND: {
      single: [
        { rate: 1.1,  max: 40525,           plus: 0         },
        { rate: 2.04, max: 98100,           plus: 445.78    },
        { rate: 2.27, max: 204675,          plus: 1620.31   },
        { rate: 2.64, max: 445000,          plus: 4039.56   },
        { rate: 2.9,  max: 99999999999999,  plus: 10384.14  }
      ],
      married: [
        { rate: 1.1,  max: 67700,           plus: 0       },
        { rate: 2.04, max: 163550,          plus: 744.7   },
        { rate: 2.27, max: 249150,          plus: 2700.04 },
        { rate: 2.64, max: 445000,          plus: 4643.16 },
        { rate: 2.9,  max: 99999999999999,  plus: 9813.6  }
      ]
    },
    OH: {
      single: [
        { rate: 0,      max: 25000,           plus: 0       },
        { rate: 2.765,  max: 44250,           plus: 0       },
        { rate: 3.226,  max: 88450,           plus: 532.26  },
        { rate: 3.688,  max: 110650,          plus: 1958.15 },
        { rate: 3.99,   max: 99999999999999,  plus: 2776.89 }
      ],
      married: [
        { rate: 0,      max: 25000,           plus: 0       },
        { rate: 2.765,  max: 44250,           plus: 0       },
        { rate: 3.226,  max: 88450,           plus: 532.26  },
        { rate: 3.688,  max: 110650,          plus: 1958.15 },
        { rate: 3.99,   max: 99999999999999,  plus: 2776.89 }
      ]
    },
    OK: {
      single: [
        { rate: 0.5,  max: 1000,            plus: 0     },
        { rate: 1,    max: 2500,            plus: 5     },
        { rate: 2,    max: 3750,            plus: 20    },
        { rate: 3,    max: 4900,            plus: 45    },
        { rate: 4,    max: 7200,            plus: 79.5  },
        { rate: 5,    max: 99999999999999,  plus: 171.5 }
      ],
      married: [
        { rate: 0.5,  max: 2000,            plus: 0   },
        { rate: 1,    max: 5000,            plus: 10  },
        { rate: 2,    max: 7500,            plus: 40  },
        { rate: 3,    max: 9800,            plus: 90  },
        { rate: 4,    max: 12200,           plus: 159 },
        { rate: 5,    max: 99999999999999,  plus: 255 }
      ]
    },
    OR: {
      single: [
        { rate: 4.75, max: 3650,            plus: 0       },
        { rate: 6.75, max: 9200,            plus: 173.38  },
        { rate: 8.75, max: 125000,          plus: 548     },
        { rate: 9.9,  max: 99999999999999,  plus: 10680.5 }
      ],
      married: [
        { rate: 4.75, max: 7300,            plus: 0       },
        { rate: 6.75, max: 18400,           plus: 346.75  },
        { rate: 8.75, max: 250000,          plus: 1096    },
        { rate: 9.9,  max: 99999999999999,  plus: 21361   }
      ]
    },
    PA: {
      single: [   { rate: 3.07, max: 99999999999999, plus: 0 } ],
      married: [  { rate: 3.07, max: 99999999999999, plus: 0 } ]
    },
    PR: { single: [], married: [] },
    RI: {
      single: [
        { rate: 3.75, max: 66200,           plus: 0       },
        { rate: 4.75, max: 150550,          plus: 2482.5  },
        { rate: 5.99, max: 99999999999999,  plus: 6489.13 }
      ],
      married: [
        { rate: 3.75, max: 66200,           plus: 0       },
        { rate: 4.75, max: 150550,          plus: 2482.5  },
        { rate: 5.99, max: 99999999999999,  plus: 6489.13 }
      ]
    },
    SC: {
      single: [
        { rate: 0, max: 3110,           plus: 0     },
        { rate: 3, max: 6220,           plus: 0     },
        { rate: 4, max: 9330,           plus: 93.3  },
        { rate: 5, max: 12440,          plus: 217.7 },
        { rate: 6, max: 15560,          plus: 373.2 },
        { rate: 7, max: 99999999999999, plus: 560.4 }
      ],
      married: [
        { rate: 0, max: 3110,           plus: 0     },
        { rate: 3, max: 6220,           plus: 0     },
        { rate: 4, max: 9330,           plus: 93.3  },
        { rate: 5, max: 12440,          plus: 217.7 },
        { rate: 6, max: 15560,          plus: 373.2 },
        { rate: 7, max: 99999999999999, plus: 560.4 }
      ]
    },
    SD: { single: [], married: [] },
    TN: { single: [], married: [] },
    TX: { single: [], married: [] },
    UT: {
      single: [   { rate: 4.95, max: 99999999999999, plus: 0 } ],
      married: [  { rate: 4.95, max: 99999999999999, plus: 0 } ]
    },
    VT: {
      single: [
        { rate: 3.35, max: 40950,           plus: 0         },
        { rate: 6.6,  max: 99200,           plus: 1371.83   },
        { rate: 7.6,  max: 206950,          plus: 5216.33   },
        { rate: 8.75, max: 99999999999999,  plus: 13405.33  }
      ],
      married: [
        { rate: 3.35, max: 68400,           plus: 0       },
        { rate: 6.6,  max: 165350,          plus: 2291.4  },
        { rate: 7.6,  max: 251950,          plus: 8690.1  },
        { rate: 8.75, max: 99999999999999,  plus: 15271.7 }
      ]
    },
    VA: {
      single: [
        { rate: 2,    max: 3000,            plus: 0   },
        { rate: 3,    max: 5000,            plus: 60  },
        { rate: 5,    max: 17000,           plus: 120 },
        { rate: 5.75, max: 99999999999999,  plus: 720 }
      ],
      married: [
        { rate: 2,    max: 3000,            plus: 0   },
        { rate: 3,    max: 5000,            plus: 60  },
        { rate: 5,    max: 17000,           plus: 120 },
        { rate: 5.75, max: 99999999999999,  plus: 720 }
      ]
    },
    WA: { single: [], married: [] },
    WV: {
      single: [
        { rate: 3,    max: 10000,           plus: 0     },
        { rate: 4,    max: 25000,           plus: 300   },
        { rate: 4.5,  max: 40000,           plus: 900   },
        { rate: 6,    max: 60000,           plus: 1575  },
        { rate: 6.5,  max: 99999999999999,  plus: 2775  }
      ],
      married: [
        { rate: 3,    max: 10000,           plus: 0     },
        { rate: 4,    max: 25000,           plus: 300   },
        { rate: 4.5,  max: 40000,           plus: 900   },
        { rate: 6,    max: 60000,           plus: 1575  },
        { rate: 6.5,  max: 99999999999999,  plus: 2775  }
      ]
    },
    WI: {
      single: [
        { rate: 3.54, max: 12120,           plus: 0         },
        { rate: 4.65, max: 24250,           plus: 429.05    },
        { rate: 5.3,  max: 266930,          plus: 993.09    },
        { rate: 7.65, max: 99999999999999,  plus: 13855.13  }
      ],
      married: [
        { rate: 3.54, max: 16160,           plus: 0         },
        { rate: 4.65, max: 32330,           plus: 572.06    },
        { rate: 5.3,  max: 355910,          plus: 1323.97   },
        { rate: 7.65, max: 99999999999999,  plus: 18473.71  }
      ]
    },
    WY: { single: [], married: [] }
  },

  standardDeduction: {
    AL: { single: 2500,     married: 7500   },
    AK: { single: 0,        married: 0      },
    AZ: { single: 12550,    married: 25100  },
    AR: { single: 2200,     married: 4400   },
    CA: { single: 4803,     married: 9606   },
    CO: { single: 12550,    married: 25100  },
    CT: { single: 0,        married: 0      },
    DE: { single: 3250,     married: 6500   },
    DC: { single: 12550,    married: 25100  },
    FL: { single: 0,        married: 0      },
    GA: { single: 4600,     married: 6000   },
    HI: { single: 2200,     married: 4400   },
    ID: { single: 12550,    married: 25100  },
    IL: { single: 0,        married: 0      },
    IN: { single: 0,        married: 0      },
    IA: { single: 2130,     married: 5240   },
    KS: { single: 3500,     married: 8000   },
    KY: { single: 2690,     married: 5380   },
    LA: { single: 0,        married: 0      },
    ME: { single: 12550,    married: 25100  },
    MD: { single: 2350,     married: 4700   },
    MA: { single: 0,        married: 0      },
    MI: { single: 0,        married: 0      },
    MN: { single: 12525,    married: 25050  },
    MS: { single: 2300,     married: 4600   },
    MO: { single: 12550,    married: 25100  },
    MT: { single: 4830,     married: 9660   },
    NE: { single: 7100,     married: 14200  },
    NV: { single: 0,        married: 0      },
    NH: { single: 0,        married: 0      },
    NJ: { single: 0,        married: 0      },
    NM: { single: 12550,    married: 25100  },
    NY: { single: 8000,     married: 16050  },
    NC: { single: 10750,    married: 21500  },
    ND: { single: 12550,    married: 25100  },
    OH: { single: 0,        married: 0      },
    OK: { single: 6350,     married: 12700  },
    OR: { single: 2350,     married: 4700   },
    PA: { single: 0,        married: 0      },
    PR: { single: 0,        married: 0      },
    RI: { single: 9050,     married: 18100  },
    SC: { single: 12550,    married: 25100  },
    SD: { single: 0,        married: 0      },
    TN: { single: 0,        married: 0      },
    TX: { single: 0,        married: 0      },
    UT: { single: 0,        married: 0      }, //TAX credits???
    VT: { single: 6350,     married: 12700  },
    VA: { single: 4500,     married: 9000   },
    WA: { single: 0,        married: 0      },
    WV: { single: 0,        married: 0      },
    WI: { single: 11200,    married: 20730  },
    WY: { single: 0,        married: 0      },            
  },

  disability: {
    CA: {
      name: "SDI",
      max: 128298,
      rate: 1.2 / 100,
    },
    HI: {
      name: "TDI",
      max: 57304, //Weekly maximum is $5,51.
      rate: 0.5 / 100,
    },
    NJ: {
      name: "SDI",
      max: 138200,
      rate: 0.47 / 100,
    },
    NY: {
      name: "SDI",
      max: 6240,
      rate: 0.5 / 100,
    },
    RI: {
      name: "SDI",
      max: 74000,
      rate: 1.3 / 100,
    }
  }

}
