import {computed, watch, reactive, nextTick} from "vue";

import taxCalc from "../tax-calc";
import taxCalcCa from "../tax-calc-ca";
import taxCalcUK from "../tax-calc-uk";

const taxModule = {
  us: taxCalc,
  ca: taxCalcCa,
  uk: taxCalcUK,
}

let paystub, country;

export default {
  runner: null,

  init (_paystub, _runner, _country) {
    paystub = _paystub;
    this.runner = _runner;
    country = _country;
  },

  bindWatch (stub) {

    watch(() => [
        stub.total,
        paystub.details.state,
        paystub.details.employmentType,
        paystub.employee.maritalStatus,
        stub.payPeriod, //NOTE: changing payment frequency triggers twice. find a solution.
        //uk:
        paystub.employee.isBlindPerson,
        paystub.employee.NIClass,
        paystub.employee.NIOption,
        paystub.employee.NIClass3Amount
      ],

      async () => {
        await nextTick();
        this.runner.pqueue.push({type: "deductions-to-be-created", args: stub})
        //this.createDeductions(stub);
      },

      {immediate: true}
    );
  },

  computify (stub, deduction, isNewStub) {

    watch (() => deduction.ytdTotal, () => this.runner.itemYTDTotalChanged(stub, deduction, "deduction"));

    deduction.ytdTotal = computed(() =>  + (deduction.priorYTD + deduction.amount).toFixed(2));

    const calcModule = taxModule[country];

    if (deduction.boundTo) { //employer contribution
      const parent = stub.deductions.find(d => d.key === deduction.boundTo);
      if (! parent) return;

      watch(() => parent.amount, () => {
        //deduction.amount = deduction.fn(parent.amount);
        deduction.amount = calcModule[deduction.fn](parent.amount);
      }, {immediate: isNewStub}); //if we are loading from data, do not change it!
    }
  },

  async createDeductions (stub) {
    //deductions:
    const regular = stub.earnings.find(e => e.isRegular);
    const yearlyRegular = regular.type === 'hourly'
      ? (regular.rate * regular.hours * paystub.details.paymentFrequency)
      : regular.rate;
    const calcModule = taxModule[country];

    const additionalData = {
      us: {status: paystub.employee.maritalStatus},
      ca: null,
      uk: {
        isBlindPerson: paystub.employee.isBlindPerson,
        insuranceCategory: paystub.employee.NICategory,
        employmentType: paystub.details.employmentType,
        yearlyProfit: paystub.employee.yearlyProfit,
        paymentPeriodicity: paystub.details.paymentPeriodicity,
        NIClass3Amount: paystub.employee.NIClass3Amount,
      }
    }[country];

    //console.log(yearlyRegular, stub.total, paystub.details.paymentFrequency,stub.payPeriod, paystub.details.state, additionalData);
    const taxes = calcModule.calcFederal(yearlyRegular, stub.total, paystub.details.paymentFrequency,
        stub.payPeriod, paystub.details.state, additionalData);

    //we cannot overwrite stub.deductions because they may have previous ytds
    const priorYTDsByKey = {};
    /* stub.deductions.forEach(tax => {
      priorYTDsByKey[tax.key] = tax.priorYTD;
    }); */

    //add deductions that don't exist, otherwise set values
    taxes.forEach(tax => {
      const existing = stub.deductions.find(d => d.key === tax.key);
      if (existing) {
        existing.amount = tax.amount; //also priorYTD? Definitely not ytdTotal, which is auto.
        if (priorYTDsByKey[tax.key]) existing.priorYTD = priorYTDsByKey[tax.key]; //write back the prior ytd
      } else {
        const deduction = reactive(tax);
        this.computify(stub, deduction, true);
        stub.deductions.push(deduction);
      }
    });

    //delete any non-custom taxes that existed before but not renewed in this cycle
    const removedDeductions = stub.deductions.filter(d => {
      return ! d.isCustom && ! taxes.some(t => t.key === d.key);
    });

    stub.deductions = stub.deductions.filter(d => ! removedDeductions.find(rd => rd.key === d.key));

    //TODO: We delete obsolete taxes, but what if another stub is using it?
    //Because we keep all earnings and taxes in all stubs even if they are only used in other stubs.

    //calculate nominal prior ytds:
    this.calcNominalPriorYTDs(stub);

    //new in June 2022: we want taxes even for the contractors, all being 0.
    if (paystub.details.employmentType === 'contractor') {
      stub.deductions.forEach(d => {
        d.amount = 0;
        d.priorYTD = 0
      });
    }
  },

  calcNominalPriorYTDs (stub) {

    const stubIndex = paystub.stubs.findIndex(s => s._key === stub._key);
    if (! stubIndex) { //find out nominal priorYTDs and priorYTS for only the first paystub

      const nominalEarningYTD = + (stub.earnings.reduce((sum, e) => {
        return e.nominalPriorYTD + sum;
      }, 0)).toFixed(2);


      const ratio = nominalEarningYTD ? (stub.priorYTD / nominalEarningYTD): 1;

      stub.deductions.forEach(d => {
        d.nominalPriorYTD = 0;
        const nominalPriorYTD = + (d.amount * (paystub.period0Index - 1)).toFixed(2);
        d.nominalPriorYTD = nominalPriorYTD;

        const val = + (d.nominalPriorYTD * ratio).toFixed(2);
        d.priorYTD = val;
      });
    }

  },

  //custom deduction
  addDeduction (stub, name, nature) {
    const calcModule = taxModule[country];
    paystub.stubs.forEach(s => {
      const deduction = reactive(calcModule.createCustomDeduction(name, nature === 'contribution'));
      this.computify(stub, deduction, true);
      s.deductions.push(deduction);
    });
  }
}
