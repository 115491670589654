export default {
  main: {
    social: [
      //used in nav footer
      {
        title: "Facebook",
        url: "https://www.facebook.com/TheBestPaystubs/",
        icon: "pi-facebook",
      },
      {
        title: "Instagram",
        url: "https://www.instagram.com/thebestpaystubs/",
        icon: "pi-instagram",
      },
      {
        title: "LinkedIn",
        url: "https://www.linkedin.com/company/the-best-paystubs/",
        icon: "pi-linkedin",
      },
      {
        title: "Twitter",
        url: "https://twitter.com/TheBestPaystubs",
        icon: "pi-twitter",
      },
      {
        title: "Youtube",
        url: "https://www.youtube.com/channel/UC_o8im-lTmhiP_x_Vfpzleg/featured",
        icon: "pi-youtube",
      },
      //{title: "Pinterest", url: "https://www.pinterest.com/thebestpaystubs", icon: "pi-pinterest"},
    ],
  },

  blog: {
    blogFilters: [
      //unused, but let's keep it.
      { filter: "All", key: "all" },
      { filter: "Accounting", key: "accounting" },
      { filter: "Paystubs", key: "paystubs" },
      { filter: "Tax Tips", key: "tax" },
      { filter: "Advertising", ket: "advertising" },
      { filter: "Payroll", key: "payroll" },
      { filter: "Performance", key: "performance" },
      { filter: "Human Resources", key: "hr" },
    ],
  },

  home: {
    testimonials: [
      //used in multiple places.
      {
        name: "Georgia Austin",
        pic: require("@/assets/images/reviews/review-2.webp"),
        p: `“I run my own blog writing service and needed some documents for a home loan.
                The Best Paystubs was able to create a paystub as proof of income for my bank in 2 minutes.
                The banks had no issues and I was approved instantly!”`,
      },
      {
        name: "Jay Hinge",
        pic: require("@/assets/images/reviews/review-3.webp"),
        p: `“Ever since I started my own catering business, it’s been difficult to be approved for loans.
            With The Best Paystubs, I was able to create a check stub that got me approved for a mortgage.
            I even made a mistake and they fixed it instantly. Thank you!”`,
      },
      {
        name: "Sarah Miles",
        pic: require("@/assets/images/reviews/review-4.webp"),
        p: `“The Best Paystubs helped me generate a pay stub for my Yoga business for the PPP.
          This was a life saver, especially in times of Corona. Their system is by far the easiest I’ve used.
          Thank you so much!!”`,
      },
      /*
      25 Jun 2024, removed per the Walter's request.
      {
        name: "Montreal Williams", pic: require("@/assets/images/reviews/review-1.webp"),
        p: `“I lost my paystub from my last job, which I needed to provide to verify proof of income for a car loan.
          I was able to create one identical in a few minutes and submitted it with no issues.
          The system was great and seamless. So easy to use!”`
      }, */
      {
        name: "Cindi Yung",
        pic: require("@/assets/images/reviews/review-5.webp"),
        p: `“My budget is tight owning my own coffee and boba shop.
          I can’t afford software like Quickbooks or Quicken to generate payroll.
          I have to pay my employees in cash, so I use The Best Paystubs to create check stubs for my employees.
          This business is a lifesaver and budget helper!”`,
      },
      {
        name: "Jasmine Jame",
        pic: require("@/assets/images/reviews/review-7.webp"),
        p: `“I do freelancing work on Up-Work as a software engineer.
          The bank wouldn’t take their earnings statements, so I used The Best Paystubs and was instantly approved.
          I now live in the house of my dreams thanks to these paystubs. So helpful and awesome!!”`,
      },
      {
        name: "Mauve",
        pic: require("@/assets/images/reviews/review-6.webp"),
        p: `"I am a musician and freelancer so I was worried I wouldn’t be able to show complete proof of my income in order to buy a house.
          But The Best Paystubs came to save the day! The templates are so easy to follow and it’s really nice having their
          expert insight to ensure all of my earnings are correctly documented. I’m glad I came across their services."`,
      },
      {
        name: "Nick Saraev",
        pic: require("@/assets/images/reviews/review-8.webp"),
        p: `"I run a publishing business and payroll used to be my biggest problem - I'd spend hours formatting earnings statements,
         check stubs, and more. Using TheBestPayStubs, I can generate everything I need in less than 10 minutes per week! It's completely
         changed my business and freed up my time. Thanks so much!"`,
      },
      {
        name: "Vincent Méreau",
        pic: require("@/assets/images/reviews/review-9.webp"),
        p: `"I am so glad I have found this user friendly website to do my pay stubs. I really like the modern looking interface and the
         different templates to choose from. Also the pre-calculated tax rates makes it hassle-free. Not to mention the chat button which is a
         much appreciated feature, interacting instantly with someone to help me fill out my form."`,
      },
    ],
  },
};
