//all states updated on 2023-12-30 by Tuna.

export default {
  brackets: {
    AL: {
      single: [
        { rate: 2, max: 500, plus: 0 },
        { rate: 4, max: 3000, plus: 10 },
        { rate: 5, max: 999999999, plus: 110 },
      ],
      married: [
        { rate: 2, max: 1000, plus: 0 },
        { rate: 4, max: 6000, plus: 20 },
        { rate: 5, max: 999999999, plus: 220 },
      ],
    },
    AK: {
      single: [{ rate: 0, max: 0, plus: 0 }],
      married: [{ rate: 0, max: 0, plus: 0 }],
    },
    AZ: {
      single: [{ rate: 2.5, max: 999999999, plus: 0 }],
      married: [{ rate: 2.5, max: 999999999, plus: 0 }],
    },
    AR: {
      single: [
        { rate: 0, max: 5300, plus: 0 },
        { rate: 2, max: 10600, plus: 0 },
        { rate: 3, max: 15100, plus: 106 },
        { rate: 3.4, max: 25000, plus: 241 },
        { rate: 4.4, max: 999999999, plus: 577.6 },
      ],
      married: [
        { rate: 0, max: 10600, plus: 0 },
        { rate: 2, max: 21200, plus: 0 },
        { rate: 3, max: 30200, plus: 212 },
        { rate: 3.4, max: 50000, plus: 482 },
        { rate: 4.4, max: 999999999, plus: 1155.2 },
      ],
    },
    CA: {
      single: [
        { rate: 1.1, max: 10412, plus: 0 },
        { rate: 2.2, max: 24684, plus: 114.53 },
        { rate: 4.4, max: 38959, plus: 428.52 },
        { rate: 6.6, max: 54081, plus: 1056.62 },
        { rate: 8.8, max: 68350, plus: 2054.67 },
        { rate: 10.23, max: 349137, plus: 3310.34 },
        { rate: 11.33, max: 418961, plus: 32034.85 },
        { rate: 12.43, max: 698271, plus: 39945.91 },
        { rate: 13.53, max: 1000000, plus: 74664.14 },
        { rate: 14.63, max: 999999999, plus: 115488.08 },
      ],
      married: [
        { rate: 1.1, max: 20824, plus: 0 },
        { rate: 2.2, max: 49368, plus: 229.06 },
        { rate: 4.4, max: 77918, plus: 857.03 },
        { rate: 6.6, max: 108162, plus: 2113.23 },
        { rate: 8.8, max: 136700, plus: 4109.34 },
        { rate: 10.23, max: 698274, plus: 6620.68 },
        { rate: 11.33, max: 837922, plus: 64069.7 },
        { rate: 12.43, max: 1000000, plus: 79891.82 },
        { rate: 13.53, max: 1396542, plus: 100038.11 },
        { rate: 14.63, max: 999999999, plus: 153690.25 },
      ],
    },
    CO: {
      single: [{ rate: 4.4, max: 999999999, plus: 0 }],
      married: [{ rate: 4.4, max: 999999999, plus: 0 }],
    },
    CT: {
      single: [
        { rate: 2, max: 10000, plus: 0 },
        { rate: 4.5, max: 50000, plus: 200 },
        { rate: 5.5, max: 100000, plus: 2000 },
        { rate: 6, max: 200000, plus: 4750 },
        { rate: 6.5, max: 250000, plus: 10750 },
        { rate: 6.9, max: 500000, plus: 14000 },
        { rate: 6.99, max: 999999999, plus: 31250 },
      ],
      married: [
        { rate: 2, max: 20000, plus: 0 },
        { rate: 4.5, max: 100000, plus: 400 },
        { rate: 5.5, max: 200000, plus: 4000 },
        { rate: 6, max: 400000, plus: 9500 },
        { rate: 6.5, max: 500000, plus: 21500 },
        { rate: 6.9, max: 1000000, plus: 28000 },
        { rate: 6.99, max: 999999999, plus: 62500 },
      ],
    },
    DE: {
      single: [
        { rate: 0, max: 2000, plus: 0 },
        { rate: 2.2, max: 5000, plus: 0 },
        { rate: 3.9, max: 10000, plus: 66 },
        { rate: 4.8, max: 20000, plus: 261 },
        { rate: 5.2, max: 25000, plus: 741 },
        { rate: 5.55, max: 60000, plus: 1001 },
        { rate: 6.6, max: 999999999, plus: 2943.5 },
      ],
      married: [
        { rate: 0, max: 2000, plus: 0 },
        { rate: 2.2, max: 5000, plus: 0 },
        { rate: 3.9, max: 10000, plus: 66 },
        { rate: 4.8, max: 20000, plus: 261 },
        { rate: 5.2, max: 25000, plus: 741 },
        { rate: 5.55, max: 60000, plus: 1001 },
        { rate: 6.6, max: 999999999, plus: 2943.5 },
      ],
    },
    DC: {
      single: [
        { rate: 4, max: 10000, plus: 0 },
        { rate: 6, max: 40000, plus: 400 },
        { rate: 6.5, max: 60000, plus: 2200 },
        { rate: 8.5, max: 250000, plus: 3500 },
        { rate: 9.25, max: 500000, plus: 19650 },
        { rate: 9.75, max: 1000000, plus: 42775 },
        { rate: 10.75, max: 999999999, plus: 91525 },
      ],
      married: [
        { rate: 4, max: 10000, plus: 0 },
        { rate: 6, max: 40000, plus: 400 },
        { rate: 6.5, max: 60000, plus: 2200 },
        { rate: 8.5, max: 250000, plus: 3500 },
        { rate: 9.25, max: 500000, plus: 19650 },
        { rate: 9.75, max: 1000000, plus: 42775 },
        { rate: 10.75, max: 999999999, plus: 91525 },
      ],
    },
    FL: {
      single: [{ rate: 0, max: 999999999, plus: 0 }],
      married: [{ rate: 0, max: 999999999, plus: 0 }],
    },
    GA: {
      single: [{ rate: 5.49, max: 999999999, plus: 0 }],
      married: [{ rate: 5.49, max: 999999999, plus: 0 }],
    },
    HI: {
      single: [
        { rate: 1.4, max: 2400, plus: 0 },
        { rate: 3.2, max: 4800, plus: 33.6 },
        { rate: 5.5, max: 9600, plus: 110.4 },
        { rate: 6.4, max: 14400, plus: 374.4 },
        { rate: 6.8, max: 19200, plus: 681.6 },
        { rate: 7.2, max: 24000, plus: 1008 },
        { rate: 7.6, max: 36000, plus: 1353.6 },
        { rate: 7.9, max: 999999999, plus: 2265.6 },
      ],
      married: [
        { rate: 1.4, max: 4800, plus: 0 },
        { rate: 3.2, max: 9600, plus: 67.2 },
        { rate: 5.5, max: 19200, plus: 220.8 },
        { rate: 6.4, max: 28800, plus: 748.8 },
        { rate: 6.8, max: 38400, plus: 1363.2 },
        { rate: 7.2, max: 48000, plus: 2016 },
        { rate: 7.6, max: 72000, plus: 2707.2 },
        { rate: 7.9, max: 999999999, plus: 4531.2 },
      ],
    },
    ID: {
      single: [
        { rate: 1, max: 1662, plus: 0 },
        { rate: 3, max: 4987, plus: 16.62 },
        { rate: 4.5, max: 8311, plus: 116.37 },
        { rate: 6, max: 999999999, plus: 265.95 },
      ],
      married: [
        { rate: 1, max: 3324, plus: 0 },
        { rate: 3, max: 9974, plus: 33.24 },
        { rate: 4.5, max: 16622, plus: 232.74 },
        { rate: 6, max: 999999999, plus: 531.9 },
      ],
    },
    IL: {
      single: [{ rate: 4.95, max: 999999999, plus: 0 }],
      married: [{ rate: 4.95, max: 999999999, plus: 0 }],
    },
    IN: {
      single: [{ rate: 3.05, max: 999999999, plus: 0 }],
      married: [{ rate: 3.05, max: 999999999, plus: 0 }],
    },
    IA: {
      single: [
        { rate: 4.4, max: 6000, plus: 0 },
        { rate: 4.82, max: 30000, plus: 264 },
        { rate: 5.7, max: 75000, plus: 1420.8 },
        { rate: 6, max: 999999999, plus: 3985.8 },
      ],
      married: [
        { rate: 4.4, max: 12000, plus: 0 },
        { rate: 4.82, max: 60000, plus: 528 },
        { rate: 5.7, max: 150000, plus: 2841.6 },
        { rate: 6, max: 999999999, plus: 7971.6 },
      ],
    },
    KS: {
      single: [{ rate: 5.15, max: 999999999, plus: 0 }],
      married: [{ rate: 5.15, max: 999999999, plus: 0 }],
    },
    KY: {
      single: [{ rate: 4, max: 999999999, plus: 0 }],
      married: [{ rate: 4, max: 999999999, plus: 0 }],
    },
    LA: {
      single: [
        { rate: 1.85, max: 12500, plus: 0 },
        { rate: 3.5, max: 50000, plus: 231.25 },
        { rate: 4.25, max: 999999999, plus: 1543.75 },
      ],
      married: [
        { rate: 2, max: 25000, plus: 0 },
        { rate: 4, max: 100000, plus: 500 },
        { rate: 6, max: 999999999, plus: 3500 },
      ],
    },
    ME: {
      single: [
        { rate: 5.8, max: 26050, plus: 0 },
        { rate: 6.75, max: 61600, plus: 1510.9 },
        { rate: 7.15, max: 999999999, plus: 3910.52 },
      ],
      married: [
        { rate: 5.8, max: 52100, plus: 0 },
        { rate: 6.75, max: 123250, plus: 3021.8 },
        { rate: 7.15, max: 999999999, plus: 7824.42 },
      ],
    },
    MD: {
      single: [
        { rate: 2, max: 1000, plus: 0 },
        { rate: 3, max: 2000, plus: 20 },
        { rate: 4, max: 3000, plus: 50 },
        { rate: 4.75, max: 100000, plus: 90 },
        { rate: 5, max: 125000, plus: 4697.5 },
        { rate: 5.25, max: 150000, plus: 5947.5 },
        { rate: 5.5, max: 250000, plus: 7260 },
        { rate: 5.75, max: 999999999, plus: 12760 },
      ],
      married: [
        { rate: 2, max: 1000, plus: 0 },
        { rate: 3, max: 2000, plus: 20 },
        { rate: 4, max: 3000, plus: 50 },
        { rate: 4.75, max: 150000, plus: 90 },
        { rate: 5, max: 175000, plus: 7072.5 },
        { rate: 5.25, max: 225000, plus: 8322.5 },
        { rate: 5.5, max: 300000, plus: 10947.5 },
        { rate: 5.75, max: 999999999, plus: 15072.5 },
      ],
    },
    MA: {
      single: [
        { rate: 5, max: 1000000, plus: 0 },
        { rate: 9, max: 999999999, plus: 50000 },
      ],
      married: [
        { rate: 5, max: 1000000, plus: 0 },
        { rate: 9, max: 999999999, plus: 50000 },
      ],
    },
    MI: {
      single: [{ rate: 4.25, max: 999999999, plus: 0 }],
      married: [{ rate: 4.25, max: 999999999, plus: 0 }],
    },
    MN: {
      single: [
        { rate: 5.35, max: 31690, plus: 0 },
        { rate: 6.8, max: 104090, plus: 1695.41 },
        { rate: 7.85, max: 193240, plus: 6618.62 },
        { rate: 9.85, max: 999999999, plus: 13616.89 },
      ],
      married: [
        { rate: 5.35, max: 46330, plus: 0 },
        { rate: 6.8, max: 184040, plus: 2478.65 },
        { rate: 7.85, max: 321450, plus: 11842.94 },
        { rate: 9.85, max: 999999999, plus: 22629.62 },
      ],
    },
    MS: {
      single: [
        { rate: 0, max: 10000, plus: 0 },
        { rate: 5, max: 999999999, plus: 0 },
      ],
      married: [
        { rate: 0, max: 20000, plus: 0 },
        { rate: 5, max: 999999999, plus: 0 },
      ],
    },
    MO: {
      single: [
        { rate: 0, max: 1207, plus: 0 },
        { rate: 2, max: 2414, plus: 0 },
        { rate: 2.5, max: 3621, plus: 24.14 },
        { rate: 3, max: 4828, plus: 54.31 },
        { rate: 3.5, max: 6035, plus: 90.53 },
        { rate: 4, max: 7242, plus: 132.77 },
        { rate: 4.5, max: 8449, plus: 181.05 },
        { rate: 4.95, max: 999999999, plus: 235.37 },
      ],
      married: [
        { rate: 0, max: 1207, plus: 0 },
        { rate: 2, max: 2414, plus: 0 },
        { rate: 2.5, max: 3621, plus: 24.14 },
        { rate: 3, max: 4828, plus: 54.31 },
        { rate: 3.5, max: 6035, plus: 90.53 },
        { rate: 4, max: 7242, plus: 132.77 },
        { rate: 4.5, max: 8449, plus: 181.05 },
        { rate: 4.95, max: 999999999, plus: 235.37 },
      ],
    },
    MT: {
      single: [
        { rate: 4.7, max: 20500, plus: 0 },
        { rate: 5.9, max: 999999999, plus: 963.5 },
      ],
      married: [
        { rate: 4.7, max: 41000, plus: 0 },
        { rate: 5.9, max: 999999999, plus: 1927 },
      ],
    },
    NE: {
      single: [
        { rate: 0, max: 3210, plus: 0 },
        { rate: 2.26, max: 6290, plus: 0 },
        { rate: 3.22, max: 20440, plus: 69.61 },
        { rate: 4.91, max: 29620, plus: 525.24 },
        { rate: 5.77, max: 37610, plus: 975.98 },
        { rate: 5.94, max: 70630, plus: 1437.0 },
        { rate: 6.1, max: 999999999, plus: 3398.39 },
      ],
      married: [
        { rate: 0, max: 7680, plus: 0 },
        { rate: 2.26, max: 12190, plus: 0 },
        { rate: 3.22, max: 30360, plus: 101.93 },
        { rate: 4.91, max: 47230, plus: 687 },
        { rate: 5.77, max: 58600, plus: 1515.32 },
        { rate: 5.94, max: 77710, plus: 2171.37 },
        { rate: 6.1, max: 999999999, plus: 3306.5 },
      ],
    },
    NV: {
      single: [{ rate: 0, max: 999999999, plus: 0 }],
      married: [{ rate: 0, max: 999999999, plus: 0 }],
    },
    NH: {
      single: [{ rate: 0, max: 999999999, plus: 0 }],
      married: [{ rate: 0, max: 999999999, plus: 0 }],
    },
    NJ: {
      single: [
        { rate: 1.4, max: 20000, plus: 0 },
        { rate: 1.75, max: 35000, plus: 280.0 },
        { rate: 3.5, max: 40000, plus: 542.5 },
        { rate: 5.53, max: 75000, plus: 717.5 },
        { rate: 6.37, max: 500000, plus: 2651.25 },
        { rate: 8.97, max: 1000000, plus: 29723.75 },
        { rate: 10.75, max: 999999999, plus: 74573.75 },
      ],
      married: [
        { rate: 1.4, max: 20000, plus: 0 },
        { rate: 1.75, max: 50000, plus: 280.0 },
        { rate: 2.45, max: 70000, plus: 805 },
        { rate: 3.5, max: 80000, plus: 1295 },
        { rate: 5.53, max: 150000, plus: 1645 },
        { rate: 6.37, max: 500000, plus: 5512.5 },
        { rate: 8.97, max: 1000000, plus: 27807.5 },
        { rate: 10.75, max: 999999999, plus: 72657.5 },
      ],
    },
    NM: {
      single: [
        { rate: 0, max: 6925, plus: 0 },
        { rate: 1.7, max: 12425, plus: 0 },
        { rate: 3.2, max: 17925, plus: 93.5 },
        { rate: 4.7, max: 22925, plus: 269.5 },
        { rate: 4.9, max: 216925, plus: 504.5 },
        { rate: 5.9, max: 999999999, plus: 10010.5 },
      ],
      married: [
        { rate: 0, max: 13850, plus: 0 },
        { rate: 1.7, max: 21850, plus: 0 },
        { rate: 3.2, max: 29850, plus: 136 },
        { rate: 4.7, max: 37850, plus: 392 },
        { rate: 4.9, max: 328850, plus: 768 },
        { rate: 5.9, max: 999999999, plus: 15027 },
      ],
    },
    NY: {
      single: [
        { rate: 4, max: 8500, plus: 0 },
        { rate: 4.5, max: 11700, plus: 340 },
        { rate: 5.25, max: 13900, plus: 484 },
        { rate: 5.5, max: 80650, plus: 599.5 },
        { rate: 6, max: 215400, plus: 4270.75 },
        { rate: 6.85, max: 1077550, plus: 12355.75 },
        { rate: 9.65, max: 5000000, plus: 71413.02 },
        { rate: 10.3, max: 25000000, plus: 449929.45 },
        { rate: 10.9, max: 999999999, plus: 2509929.45 },
      ],
      married: [
        { rate: 4, max: 17150, plus: 0 },
        { rate: 4.5, max: 23600, plus: 686 },
        { rate: 5.25, max: 27900, plus: 976.25 },
        { rate: 5.5, max: 161550, plus: 1202 },
        { rate: 6, max: 323200, plus: 8552.75 },
        { rate: 6.85, max: 2155350, plus: 18251.75 },
        { rate: 9.65, max: 5000000, plus: 143754.03 },
        { rate: 10.3, max: 25000000, plus: 418262.75 },
        { rate: 10.9, max: 999999999, plus: 2478262.75 },
      ],
    },
    NC: {
      single: [{ rate: 4.75, max: 999999999, plus: 0 }],
      married: [{ rate: 4.75, max: 999999999, plus: 0 }],
    },
    ND: {
      single: [
        { rate: 0, max: 52025, plus: 0 },
        { rate: 1.95, max: 233275, plus: 0 },
        { rate: 2.5, max: 999999999, plus: 3534.38 },
      ],
      married: [
        { rate: 0, max: 70900, plus: 0 },
        { rate: 1.95, max: 261500, plus: 0 },
        { rate: 2.5, max: 999999999, plus: 3716.7 },
      ],
    },
    OH: {
      single: [
        { rate: 0, max: 26050, plus: 0 },
        { rate: 2.77, max: 100000, plus: 0 },
        { rate: 3.99, max: 999999999, plus: 2044.72 },
      ],
      married: [
        { rate: 0, max: 26050, plus: 0 },
        { rate: 2.77, max: 100000, plus: 0 },
        { rate: 3.99, max: 999999999, plus: 2044.72 },
      ],
    },
    OK: {
      single: [
        { rate: 0, max: 6350, plus: 0 },
        { rate: 0.25, max: 7350, plus: 0 },
        { rate: 0.75, max: 8850, plus: 2.5 },
        { rate: 1.75, max: 10100, plus: 13.75 },
        { rate: 2.75, max: 11250, plus: 35.62 },
        { rate: 3.75, max: 13550, plus: 67.25 },
        { rate: 4.75, max: 999999999, plus: 153.5 },
      ],
      married: [
        { rate: 0, max: 12700, plus: 0 },
        { rate: 0.25, max: 14700, plus: 0 },
        { rate: 0.75, max: 17700, plus: 5 },
        { rate: 1.75, max: 20200, plus: 27.5 },
        { rate: 2.75, max: 22500, plus: 71.25 },
        { rate: 3.75, max: 27100, plus: 134.5 },
        { rate: 4.75, max: 999999999, plus: 307 },
      ],
    },
    OR: {
      single: [
        { rate: 4.75, max: 4300, plus: 0 },
        { rate: 6.75, max: 10750, plus: 204.25 },
        { rate: 8.75, max: 125000, plus: 639.62 },
        { rate: 9.9, max: 999999999, plus: 10636.5 },
      ],
      married: [
        { rate: 4.75, max: 8600, plus: 0 },
        { rate: 6.75, max: 21500, plus: 408.5 },
        { rate: 8.75, max: 250000, plus: 1279.25 },
        { rate: 9.9, max: 999999999, plus: 21273 },
      ],
    },
    PA: {
      single: [{ rate: 3.07, max: 999999999, plus: 0 }],
      married: [{ rate: 3.07, max: 999999999, plus: 0 }],
    },
    PR: {
      single: [{ rate: 0, max: 999999999, plus: 0 }],
      married: [{ rate: 0, max: 999999999, plus: 0 }],
    },
    RI: {
      single: [
        { rate: 3.75, max: 68200, plus: 0 },
        { rate: 4.75, max: 155050, plus: 2557.5 },
        { rate: 5.99, max: 999999999, plus: 6682.88 },
      ],
      married: [
        { rate: 3.75, max: 77450, plus: 0 },
        { rate: 4.75, max: 176050, plus: 2904.38 },
        { rate: 5.99, max: 999999999, plus: 7587.88 },
      ],
    },
    SC: {
      single: [
        { rate: 0, max: 3460, plus: 0 },
        { rate: 3, max: 17330, plus: 0 },
        { rate: 6, max: 999999999, plus: 416.1 },
      ],
      married: [
        { rate: 0, max: 3460, plus: 0 },
        { rate: 3, max: 17330, plus: 0 },
        { rate: 6, max: 999999999, plus: 416.1 },
      ],
    },
    SD: {
      single: [{ rate: 0, max: 999999999, plus: 0 }],
      married: [{ rate: 0, max: 999999999, plus: 0 }],
    },
    TN: {
      single: [{ rate: 0, max: 999999999, plus: 0 }],
      married: [{ rate: 0, max: 999999999, plus: 0 }],
    },
    TX: {
      single: [{ rate: 0, max: 999999999, plus: 0 }],
      married: [{ rate: 0, max: 999999999, plus: 0 }],
    },
    UT: {
      single: [{ rate: 4.55, max: 999999999, plus: 0 }],
      married: [{ rate: 4.55, max: 999999999, plus: 0 }],
    },
    VT: {
      single: [
        { rate: 3.35, max: 45400, plus: 0 },
        { rate: 6.6, max: 110050, plus: 1520.9 },
        { rate: 7.6, max: 229550, plus: 5787.8 },
        { rate: 8.75, max: 999999999, plus: 14869.8 },
      ],
      married: [
        { rate: 3.35, max: 75850, plus: 0 },
        { rate: 6.6, max: 183400, plus: 2540.98 },
        { rate: 7.6, max: 279450, plus: 9639.28 },
        { rate: 8.75, max: 999999999, plus: 16939.08 },
      ],
    },
    VA: {
      single: [
        { rate: 2, max: 3000, plus: 0 },
        { rate: 3, max: 5000, plus: 60 },
        { rate: 5, max: 17000, plus: 120 },
        { rate: 5.75, max: 999999999, plus: 720 },
      ],
      married: [
        { rate: 2, max: 3000, plus: 0 },
        { rate: 3, max: 5000, plus: 60 },
        { rate: 5, max: 17000, plus: 120 },
        { rate: 5.75, max: 999999999, plus: 720 },
      ],
    },
    WA: {
      single: [{ rate: 0, max: 999999999, plus: 0 }],
      married: [{ rate: 0, max: 999999999, plus: 0 }],
    },
    WV: {
      single: [
        { rate: 2.55, max: 10000, plus: 0 },
        { rate: 3.4, max: 25000, plus: 255.0 },
        { rate: 3.82, max: 40000, plus: 765 },
        { rate: 5.1, max: 60000, plus: 1338.75 },
        { rate: 5.53, max: 999999999, plus: 2358.75 },
      ],
      married: [
        { rate: 2.55, max: 10000, plus: 0 },
        { rate: 3.4, max: 25000, plus: 255.0 },
        { rate: 3.82, max: 40000, plus: 765 },
        { rate: 5.1, max: 60000, plus: 1338.75 },
        { rate: 5.53, max: 999999999, plus: 2358.75 },
      ],
    },
    WI: {
      single: [
        { rate: 3.5, max: 13810, plus: 0 },
        { rate: 4.4, max: 27630, plus: 483.35 },
        { rate: 5.3, max: 304170, plus: 1091.43 },
        { rate: 7.65, max: 999999999, plus: 15748.05 },
      ],
      married: [
        { rate: 3.5, max: 18420, plus: 0 },
        { rate: 4.4, max: 36840, plus: 644.7 },
        { rate: 5.3, max: 405550, plus: 1455.18 },
        { rate: 7.65, max: 999999999, plus: 20996.81 },
      ],
    },
    WY: {
      single: [{ rate: 0, max: 999999999, plus: 0 }],
      married: [{ rate: 0, max: 999999999, plus: 0 }],
    },
  },

  standardDeduction: {
    AL: { single: 2500, married: 7500 },
    AK: { single: 0, married: 0 },
    AZ: { single: 14600, married: 29200 },
    AR: { single: 2340, married: 4680 },
    CA: { single: 5363, married: 10726 },
    CO: { single: 14600, married: 29200 },
    CT: { single: 0, married: 0 },
    DE: { single: 5700, married: 11400 },
    DC: { single: 14600, married: 29200 },
    FL: { single: 0, married: 0 },
    GA: { single: 12000, married: 18500 },
    HI: { single: 2200, married: 4400 },
    ID: { single: 14600, married: 29200 },
    IL: { single: 0, married: 0 },
    IN: { single: 0, married: 0 },
    IA: { single: 0, married: 0 },
    KS: { single: 6150, married: 12300 },
    KY: { single: 3160, married: 6320 },
    LA: { single: 0, married: 0 },
    ME: { single: 14600, married: 29200 },
    MD: { single: 2350, married: 4700 },
    MA: { single: 0, married: 0 },
    MI: { single: 0, married: 0 },
    MN: { single: 14575, married: 29150 },
    MS: { single: 2300, married: 4600 },
    MO: { single: 13850, married: 27700 },
    MT: { single: 5540, married: 11080 },
    NE: { single: 7900, married: 15800 },
    NV: { single: 0, married: 0 },
    NH: { single: 0, married: 0 },
    NJ: { single: 0, married: 0 },
    NM: { single: 14600, married: 29200 },
    NY: { single: 8000, married: 16050 },
    NC: { single: 12750, married: 25500 },
    ND: { single: 14600, married: 29200 },
    OH: { single: 0, married: 0 },
    OK: { single: 6350, married: 12700 },
    OR: { single: 2605, married: 5495 },
    PA: { single: 0, married: 0 },
    PR: { single: 0, married: 0 },
    RI: { single: 10550, married: 21150 },
    SC: { single: 6925, married: 6925 },
    SD: { single: 0, married: 0 },
    TN: { single: 0, married: 0 },
    TX: { single: 0, married: 0 },
    UT: { single: 0, married: 0 },
    VT: { single: 6500, married: 13050 },
    VA: { single: 8500, married: 17000 },
    WA: { single: 0, married: 0 },
    WV: { single: 0, married: 0 },
    WI: { single: 12760, married: 23620 },
    WY: { single: 0, married: 0 },
  },

  disability: {
    CA: {
      name: "SDI",
      max: 999999999, //maximum cap has been removed starting 2024.
      rate: 1.1 / 100,
    },
    HI: {
      name: "STI",
      max: 71448, //Weekly maximum is $6.87.
      rate: 0.5 / 100,
    },
    NJ: {
      name: "SDI",
      max: 156800,
      rate: 0.14 / 100,
    },
    NY: {
      name: "SDI",
      max: 6240,
      rate: 0.5 / 100,
    },
    RI: {
      name: "SDI",
      max: 81900,
      rate: 1.1 / 100,
    },
  },
};
