import { computed, reactive, watch, watchEffect } from "vue";

let paystub;

export default {
  runner: null,

  init (_paystub, _runner) {
    paystub = _paystub;
    this.runner = _runner;
  },

  totalChanged (stub, earning) {
    const index = paystub.stubs.findIndex(s => s._key === stub._key);
    if (index || paystub.period0Index === -1) return; //we only care about the first stub's income
    earning.priorYTD = + (earning.total * (paystub.period0Index - 1)).toFixed(2);
    earning.nominalPriorYTD = earning.priorYTD;
  },

  //takes a rawEarning, and reactivates it with our custom computeds/watches.
  computify (stub, rawEarning) {
    stub;

    const earning = reactive(rawEarning);

    watch(() => earning.total, () => this.totalChanged(stub, earning));

    //computed:
    earning.total = computed(() => {
      let ret;
      switch (earning.type) {
        case "hourly": ret = + (earning.rate * earning.hours).toFixed(2); break;
        case "salary": ret = + (earning.rate / paystub.details.paymentFrequency).toFixed(2); break;
        case "once"  : ret = + earning.rate.toFixed(2); break;
        default:
            throw new Error("Should not happen: " + earning.type);
      }
      return ret;
    });

    //Regular earning name is changed depending on the situation.
    //Do not use a computed, because the server cannot handle this.
    watchEffect(() => {
      if (! earning.isRegular) return;
      const name = paystub.country === 'uk' ? 'Self-employed' : 'Contractor';
      if (paystub.details.employmentType === 'contractor') return earning.name = name;
      earning.name = paystub.details.paymentType === 'hourly' ? "Regular" : "Salary";
    });

    watchEffect(() => {

      const DAYS_IN_MONTH = 22;
      const hoursPerPeriod = {
        daily: 8,
        weekly: 40,
        "bi-weekly": 80,
        "semi-monthly": 96,
        "4-weekly": 160,
        monthly: DAYS_IN_MONTH * 8, //176,
        quarterly: DAYS_IN_MONTH * 8 * 3,
        "semi-annually": DAYS_IN_MONTH * 8 * 6,
        annually: DAYS_IN_MONTH * 8 * 12,
      }

      const maxHoursInPeriod = {
        daily: 24,
        weekly: 7 * 24,
        "bi-weekly": 14 * 24,
        "semi-monthly": 15 * 24,
        "4-weekly": 28 * 24,
        monthly: 30 * 24,
        quarterly: 30 * 3 * 24,
        "semi-annually": 30 * 6 + 24,
        annually: 365 * 24,
      }

      if (paystub.details.paymentType === 'hourly') { //paymentType is a trigger
        /* const hrs = hoursPerPeriod[paystub.details.paymentPeriodicity];
        const MAX_HRS_RATIO = 50 / 100;
        earning.maxHours = hrs + Math.ceil(hrs * MAX_HRS_RATIO); */
        earning.maxHours = maxHoursInPeriod[paystub.details.paymentPeriodicity];

        if (earning.isRegular) {
          earning.hours = hoursPerPeriod[paystub.details.paymentPeriodicity]; //periodicity is a trigger
        }

      } else {
        earning.hours = 1;
        earning.maxHours = 1;
      }
    });

    //rate and hours. We need to detect changes to these so that consequent stubs also use these values.
    watch(() => earning.rate, () => this.runner.earningRateOrHoursChanged(stub, earning));
    watch(() => earning.hours, () => this.runner.earningRateOrHoursChanged(stub, earning));

    watch(() => earning.ytdTotal, () => this.runner.itemYTDTotalChanged(stub, earning, "earning"));

    //this must come after watch -> earning.ytdTotal
    earning.ytdTotal = computed(() =>  + (earning.priorYTD + earning.total).toFixed(2));
    return earning;
  },

  //also called from Stub.js upon payment type change to find correct regular pay values.
  getRegularEarningDefaults () {
    const paymentType = paystub.details.paymentType;

    return {
      name: "Regular",
      type: paymentType,
      hours: paymentType === "hourly" ? 0 : 1,
      rate: paymentType === "hourly" ? 20: 48000,
      isRegular: true,
      visible: true,
      isCustom: false,
      protoKey: "regular",
      deletable: false,
    }
  },

  //called from Stub.js upon creation of a new stub.
  fillDefaultEarnings (stub) {
    const knownEarnings = [
      this.getRegularEarningDefaults(),
      {name: "Overtime",  type: "hourly", protoKey: "overtime",  isCustom: false, visible: true , deletable: true },
      {name: "Holiday",   type: "hourly", protoKey: "holiday",   isCustom: false, visible: false, deletable: true },
      {name: "Vacation",  type: "hourly", protoKey: "vacation",  isCustom: false, visible: false, deletable: true },
      {name: "Bonus",     type: "once",   protoKey: "bonus",     isCustom: false, visible: false, deletable: true },
      {name: "Tips",      type: "once",   protoKey: "tips",      isCustom: false, visible: false, deletable: true },
    ];

    const otherStubs = paystub.stubs.filter(s => s._key !== stub._key);

    knownEarnings.forEach(earningProto => {
      const earning = this.make(stub, earningProto);
      //earning is forced to be visible when at least one paystub has it visible:
      const exists = !! otherStubs.find(s => {
        return s.earnings.find(e => e.protoKey === earning.protoKey && e.visible);
      });
      if (exists) earning.visible = true;
      stub.earnings.push(earning);
    });
  },

  make (stub, preset) {
    //earning type can be: "salary", "hourly", "once"
    const defaults = {
      name: "",
      type: preset.type,
      isCustom: false,
      isRegular: false,
      rate: 0,
      hours: preset.type === 'hourly' ? 0 : 1,
      maxHours: 0,
      priorYTD: 0,
      ytdTotal: 0,
      visible: false,
      key: Math.random(),
      protoKey: preset.protoKey,
      nominalPriorYTD: 0,
    };

    const rawEarning = Object.assign({}, defaults, preset || {});
    const earning = this.computify(stub, rawEarning);
    return earning;
  },

}