<template>
  <section class="font-proxima">

    <div>
      <header class="psn-gradient-header text-center text-white flex flex-col space-y-4 md:space-y-10 pt-16 pb-32 px-4">

        <h1 class="text-2xl lg:text-5xl font-semibold">
          {{$localize(country, "Generate 100% Legal Pay Stubs")}}
        </h1>

        <p class="text-xl lg:text-3xl font-georgia text-white">Online, Hassle-free! </p>

        <p class="text-sm md:text-base lg:text-lg mb-16 lg:max-w-screen-lg container mx-auto">
          We’re the best {{$localize(country, "pay stub")}} generator for a reason.
          Join thousands of satisfied independent contractors and small business owners and
          get the highest quality
          {{$localize(country, "pay stubs, W2s and 1099s")}}
          — right to your inbox! We make it easy. Guaranteed.
        </p>

      </header>

      <!-- create section -->
      <section class="-mt-20 md:mx-auto mx-2 md:container">
        <!-- chose person -->
        <div class="mb-20 psn-card bg-white flex flex-wrap rounded divide divide-x divide-y divide-gray-200">

          <div class="w-full md:w-1/2" v-for="(block, index) of forWho" :key="index">

            <div class="flex justify-center items-center h-24 md:py-16 lg:py-20 md:border-b"  >
              <h2 class="text-2xl md:text-2xl lg:text-4xl text-center text-blue md:w-4/5">
                <router-link :to="paystubRoute">{{block.header}}</router-link>
              </h2>
            </div>

            <div class="p-10">
              <div class="home-paystub-top-bg" :class="block.bgClass"></div>
              <div class="text-blue leading-10">
                <!-- <h3 class="text-xl md:text-2xl mb-3 whitespace-nowrap" v-if="! index">Show proof of income.</h3> -->
                <!-- <div v-else class="text-xl md:text-2xl mb-3">&nbsp;</div> -->
                <ul class="home-top-list">
                  <li v-for="item of block.items" :key="item">{{item}}</li>
               </ul>
              </div>
              <div class="flex justify-center mt-8" >
                <router-link class="call-to-action-link" :to="paystubRoute">{{block.actionText}}</router-link>
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>

    <ContentProducts />

    <!-- Why choose The Best Paystubs? section-->
    <ContentWhyChoose :items="whyChoose.items" :content="whyChoose.content" />

    <!-- Never been this simple section -->
    <ContentSimple />

    <!-- video -->
    <section class="mt-16 bg-gray-50 md:px-16 md:py-0">

      <div class="py-16 px-8 flex flex-wrap flex-col lg:flex-row container mx-auto gap-8 xl:w-1/2">

        <!-- <div class="md:flex-1 mx-auto">
          <video controls
            :poster="videoPoster"
            src="/media/the-best-paystubs-explainer.mp4">
          </video>
        </div> -->

        <div class="flex-1 md:px-0">
          <h2 class="text-3xl leading-snug psn-light-header text-center">
            The Best Paystubs is the secure way to generate {{$localize(country, "pay stubs")}} and payroll documents.
          </h2>

          <p class="leading-loose psn-light-paragraph mt-4">
            We offer real {{$localize(country, "paycheck stubs")}}
            and the most up-to-date {{$localize(country, "federal and state tax forms")}}.
            Create {{$localize(country, "paystubs")}} and payroll documents quickly.
            You’ll receive an email  in minutes with a secure link to download a print-ready PDF.
          </p>

        </div>

      </div>

    </section>

    <ContentConfidence class="md:mt-24" />

    <!-- Testimonials section -->
    <section class="md:container mx-auto px-2 mt-16">
      <h2 class="content-h2 text-center">Customers trust our check stub maker.</h2>
      <p class="content-italic-subheader text-center">They really do.</p>
      <Testimonials :testimonials="testimonials" />
    </section>

    <!-- Anatomy section -->
    <section class="md:container mx-auto px-4 mt-24 md:mt-32">

      <div class="bg-no-repeat bg-top w-full blob-bg -mt-16 bg-[length:105%] ">

        <h2 class="content-h2 text-center">The Anatomy of a {{$localize(country, "Paystub")}}</h2>
        <p class="text-psn-light-text text-base md:text-xl text-center lg:w-4/5 mx-auto mt-8">
          <template v-if="country === 'us'">
            A whopping 42 percent of U.S. employees say taxes and deductions on their pay stubs are confusing to read and understand.
            We’re breaking down the key elements below so you can better understand your pay stub.
          </template>
          <template v-if="country === 'ca'">
            With a whopping 53 percent of Canadians living paycheque to paycheque, the accuracy of every pay stub is critical.
            We’re breaking down the key elements below so you can better understand your pay stub.
          </template>
          <template v-if="country === 'uk'">
            With a whopping 40% of UK households just one paycheck away from financial ruin, the accuracy of every pay slip is critical.
            We’re breaking down the key elements below so you can better understand your pay slip.
          </template>
        </p>

        <figure>
          <img :src="anatomyImage" class="block w-full h-auto mt-8 lg:mt-16" :alt='`${$localize(country, "pay stub")} anatomy${$localize(country, "anatomy alt")}`' />
        </figure>
      </div>

      <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-20 mt-16 lg:mt-36 text-left">
        <ul v-for="(item,index) in anatomy[country]" :key="index">
          <li>
            <h3 class=" md:text-2xl text-blue font-semibold md:mb-5"> {{item.head}}</h3>
            <p class="md:text-lg text-psn-light-text">{{item.description}}</p>
          </li>
        </ul>
      </div>

      <div class="flex justify-center mt-16 lg:mt-24">
        <router-link :to="paystubRoute" class="call-to-action-link">CREATE YOUR {{$localize(country, "paystub")}} NOW</router-link>
      </div>
    </section>

    <hr class="md:hidden mt-16">

    <!-- Who can use section -->
    <section class="container mx-auto px-4 mt-8 md:mt-24" v-if="country === 'us'">
      <h2 class="content-h2 text-center">Who can use The Best Paystubs?</h2>
      <ul class="grid grid-rows-3 lg:grid-rows-1 lg:grid-cols-3 gap-6 mt-8">
        <li v-for="item of whoCanUse" :key="item.text" class="flex flex-col text-center items-center">
          <div class="landing-bg mx-auto cursor-pointer" :class="item.bg" @click="$router.push({name: item.href})" ></div>
          <h3>
            <router-link :to="{name: item.href}" class="block text-psn-green-text text-2xl max-w-sm font-semibold">
              <span class="right-arrow-green" style="background-size:4%">{{item.text}}</span>
            </router-link>
          </h3>
        </li>
      </ul>
    </section>

    <!-- Not all pay stub generators are created equal. section-->
    <ContentComputer />

    <div class="mt-12">
      <FAQ :faq-data="faqData" v-if="faqData"></FAQ>
    </div>

  </section>
</template>

<script>
// @ is an alias to /src
import pageData from "@/lib/page-data.js";
import ContentComputer from "@/components/content-computer.vue";
import ContentSimple from "@/components/content-simple.vue";
import ContentConfidence from "@/components/content-confidence.vue";
import FAQ from '@/views/FAQ.vue';
import videoPoster from "@/assets/images/home-video-poster.webp";
import anatomyUS from "@/assets/images/anatomy-us.svg";
import anatomyCA from "@/assets/images/anatomy-ca.svg";
import ContentProducts from "@/components/content-products.vue";
import Testimonials from "@/components/psn-testimonials.vue";
import ContentWhyChoose from "@/components/content-why-choose.vue";


export default {
  name: 'Home',
  components:{
    FAQ,
    ContentComputer,
    ContentSimple,
    ContentConfidence,
    ContentProducts,
    Testimonials,
    ContentWhyChoose
},
  data () {
    return {
      isVideoPlayerOpen:false,
    }
  },

  computed: {
    forWho () {
      return [
        {
          header: this.$localize(this.country, "Create a pay stub for yourself."),
          bgClass: "home-paystub-single",
          items: [
            "Rent an apartment", "Qualify for a mortgage", "Request a small business loan",
            "Verify income for child support or alimony",
            this.$localize(this.country, "Apply for health insurance")
          ],
          actionText: `CREATE YOUR ${this.$localize(this.country, "paystub")} NOW`,
        },
        {
          header: this.$localize(this.country, "Create pay stubs for your employees."),
          bgClass: "home-paystub-employee",
          items: [
            "Help employees qualify for loans, housing & more",
            `Comply with ${this.$localize(this.country, "state and local employment laws")}`,
            "Be transparent with compensation",
            `Trust auto-calculation for every ${this.$localize(this.country, "pay stub")}${this.$localize(this.country, ", for every state")}`,
            "Manage all payroll documents in one place"
          ],
          actionText: `CREATE AN EMPLOYEE ${this.$localize(this.country, "paystub")} NOW`,
        }
      ];
    },

    pageData () {
      return pageData;
    },

    country () {
      return this.$route.meta.country;
    },

    paystubRoute () {
      return {name: this.country + "-paystub"};
    },

    faqData () {
      switch (this.country) {
        case "us": return import("./faq-us");
        case "uk": return import("./faq-uk");
        default: return null;
      }
    },

    videoPoster () {
      return videoPoster;
    },

    whoCanUse () {
      return [
        { bg:  "landing-tax-pro", text: "Tax professionals prepare clients for tax season", href: "landing-tax-season"},
        { bg:  "landing-real-estate", text: "Get financial documents for homebuyers and homeowners", href: "landing-real-estate"},
        { bg:  "landing-business", text: "Fast payroll documents for small businesses", href: "landing-small-business"},
      ];
    },

    anatomyImage () {
      return {
        us: anatomyUS, ca: anatomyCA,
      }[this.country];
    },

    anatomy () {
      return {
        us: [
          {head:"Employment Details",description:"Name of employer and address, payment date, etc."},
          {head:"Local Taxes",description:"Some local governments impose income taxes, shown as deductions."},
          {head:"Back Pay",description:"In the event you overpay in taxes, back pay will appear on your pay stub."},
          {head:"Sick/Holiday Pay",description:"Paid sick leave and paid vacation are noted in this section."},
          {head:"Federal Taxes",description:"Federal withholding taxes based on your W-4 form."},
          {head:"Net Salary",description:"Also known as your take home pay, this is the amount that gets paid to you after deductions."},
          {head:"Gross Salary",description:"Your total salary without mandatory deductions."},
          {head:"State Taxes",description:"Some states impose income taxes depending on where you live and work, shown as deductions."},
        ],
        ca: [
          {head: "Employment Details", description:"Name of employer and address, payment date, etc."},
          {head: "Employment Insurance (EI)", description: "Premiums deducted for each dollar of insurable earnings up to an annual maximum."},
          {head: "Other Income", description: "Overtime, sick leave, vacation, bonuses, commissions, etc."},
          {head: "Canada Pension Plan (CPP) or Quebec Pension Plan (QPP)", description: "Premiums deducted typically amount to 4.95% of annual gross income."},
          {head: "Gross Salary", description: "Your total salary before deductions and contributions."},
          {head: "Income Tax", description: "Amounts withheld for both federal and provincial or territorial income tax."},
          {head: "Other Deductions", description: "These could include RRSP contributions, profit sharing, insurance payments and garnishments."},
          {head: "Year-to-Date (YTD)", description: "Cumulative amounts from first payroll of the year up to and including the specified pay period."},
          {head: "Net Salary", description: "Also known as your take home pay, this is the amount that gets paid to you after deductions and contributions."},
        ],
        uk: [
          {head: "Personal Information", description: "Your name, payroll (or employee number) and National Insurance (NI) number."},
          {head: "Base Pay", description: "Also known as gross pay, this is your total salary before deductions and contributions."},
          {head: "Tax Code", description: "This number tells employers how much tax-free pay you receive before tax deductions."},
          {head: "Tax Deductions", description: "Amounts deducted from your gross pay based on your tax code."},
          {head: "Year-to-Date (YTD)", description: "Cumulative amounts from first payroll of the year up to and including the specified pay period."},
          {head: "National Insurance (NI)", description: "Contributions deducted from your pay for pensions and maternity allowance."},
          {head: "Pension Details", description: "Contributions deducted from your pay for any workplace pensions."},
          {head: "Tax Period", description: "The tax year is broken up into different tax periods, which are used to calculate your pay."},
          {head: "Net Pay", description: "Also known as your take home pay, this is the amount that gets paid to you after deductions and contributions."},
        ]
      };
    },

    whyChoose () {
      return {
        content: `
          If you’re an independent contractor, entrepreneur or small business employee,
          you may not have instant access to the ${this.$localize(this.country, "American")}
          ${this.$localize(this.country, "paystubs")} and payroll documents you need for yourself or your employees.
          That’s where we come in — a solution that’s legitimate, accurate and guaranteed (or your money back)!
          ${this.country === 'us' ? 'The Best Paystubs is proud to be an American company.' : ''}`,

        items: [
          {
            title: "Trust a Seamless Process",
            content: `You need ${this.$localize(this.country, "paystubs")} fast. In a few steps, you’ll have legitimate ${this.$localize(this.country, "pay stubs")} that
              show employment details, gross and net salary, payroll tax deductions and more.`
          },
          {
            title: "Select from Professional Templates",
            content: `Looks are important – especially for official documentation. Select from dozens of
              professional templates for your ${this.$localize(this.country, "paystubs and IRS compliant W2 and 1099 forms.")}`
          },
          {
            title: `Avoid Issues with the ${this.$localize(this.country, 'IRS')}`,
            content: `Miscalculations can result in audits and worse, fines. We’re the only ${this.$localize(this.country, "paystub")}
              generator that features auto-calculations based on the latest
              ${this.$localize(this.country, "state and local government")}.`
          },
          {
            title: "Access Support, 24/7",
            content: `Make a mistake? Have a question? We’re here for you.
              Simply contact our helpful support team by email or chat – day or night – for fast, friendly service.`
          }
        ]
      };
    },

    testimonials () {
      switch (this.country) {
        case "ca": {
          const mauve = this.pageData.home.testimonials.find(t => t.name === 'Mauve');
          const nickSaraev = this.pageData.home.testimonials.find(t => t.name === 'Nick Saraev');
          const vincentMereau = this.pageData.home.testimonials.find(t => t.name === 'Vincent Méreau');
          const jayHinge = this.pageData.home.testimonials.find(t => t.name === 'Jay Hinge');
          return [mauve, nickSaraev, vincentMereau, jayHinge]; /* ...this.pageData.home.testimonials.slice(0,1)]; */
        }
        case "uk": {
          const discarded = ["Georgia Austin", "Montreal Williams", "Mauve", "Nick Saraev", "Vincent Méreau"];
          return this.pageData.home.testimonials.filter(t => ! discarded.includes(t.name));
        }
        default: {
          return this.pageData.home.testimonials.slice(0,4);
        }
      }
    }
  },

  async created () {
    await this.$nextTick();
    this.$meta.makeLD_organization();
    this.$meta.makeLD_video(location.origin + this.videoPoster,
      location.origin + "/media/the-best-paystubs-explainer.mp4", "PT1M29S");
  }
}
</script>

<style>
  .p-accordion-header {
    background-color: #fafafa;
    padding: 1.5rem;
    box-shadow: 0 0 15px rgb(0 0 0 / 15%);
    margin-top: 2rem;
  }
</style>

<style scoped>
  .landing-bg {
    @apply bg-center bg-no-repeat w-64 h-64;
  }

  .landing-tax-pro {
    background-image: url("@/assets/images/home-landing-tax-pro.svg");
  }

  .landing-real-estate {
    background-image: url("@/assets/images/home-landing-real-estate.svg");
  }

  .landing-business {
    background-image: url("@/assets/images/home-landing-business.svg");
  }

  .right-arrow-green {
    background-image: url('@/assets/images/right-arrow_green.svg');
    @apply bg-no-repeat bg-right pr-10;
    background-size: 10%;
  }

  .home-paystub-top-bg {
    background-position: center top;
    @apply bg-no-repeat bg-contain h-48 md:h-48 lg:h-64 xl:h-72;
  }

  .home-paystub-single {
    background-image: url('@/assets/images/create_paystube_single.svg');
  }

  .home-paystub-employee {
    background-image: url('@/assets/images/create_paystube_multiple.svg');
  }

  .quote-bg {
    background-image: url('@/assets/images/quote2.svg');
    @apply h-16 w-16 lg:h-10 lg:w-10 bg-no-repeat bg-contain bg-center;
  }

  .check-bg {
    background-image: url('@/assets/images/check-mark.svg');
    @apply block w-14 h-14 mt-2 mr-2 p-2 bg-no-repeat bg-top;
  }

  .check-bg2 {
    background-image: url('@/assets/images/check-mark.svg');
    @apply inline-block w-4 h-4 bg-no-repeat bg-top bg-contain p-2 mt-3;
  }

  .home-top-list li {
    background-image: url('@/assets/images/check-mark.svg');
    @apply bg-no-repeat bg-contain pl-8 pt-0;
    background-position: 0 12px;
    background-size: 18px;
  }

  .arrow-bg {
    background-image: url('@/assets/images/right-arrow_green.svg');
    @apply bg-no-repeat bg-right bg-contain pr-9;
    background-size: 10%;
  }


  .leading-5{
    line-height: 1.76;
  }
  .bg-2{
    background: rgba(0, 0, 0, 0.74);
  }
  .w-100{
    width: 100%;
  }

  @media screen(md) {
    .blob-bg {
      background-image: url("@/assets/images/bg.svg");
    }
  }

  .font-georgia{
    font-style: italic;
  }
  .gray{
    color:#5E5E6C;
  }

  .card{
    width: 100%;
    height: 337px;
    box-shadow: 0px 0px 17px -5px #000000;
    margin: 10px;
    padding: 41px;
    font-size: 20px;
    text-align: right;
    color: #5E5E6C;
    position: relative;
    padding-top:125px ;

  }
  .card p{
    text-align: left;
  }
  .img{
    position: absolute;
    display: flex;
    align-items: flex-end;
    top: -135px;
    }
  .circle{
    border-radius: 249px;
    width: 249px !important;
    height: 249px !important;
    overflow: hidden;
  }

  .psn-light-paragraph {
    color: #5E5E6C;
  }

  .psn-light-header {
    color: #141F4E;
  }
  .text-blue{
    color: #0B4652;
    font-weight: 600;
  }

@media only screen and (max-width: 1460px) {
  .form_info{
    min-height: 310px;
  }
   .card{
    height: 360px;
  }
  .img{
    position: absolute;
    display: flex;
    align-items: flex-end;
    top: -100px;
    }
  .circle{
    border-radius: 249px;
    width: 160px !important;
    height: 160px !important;
    overflow: hidden;
  }
}
@media only screen and (max-width: 1390px) {
  .form_info{
    min-height: 390px;

  }
}
@media only screen and (max-width:1290px) {
  .card{
    height: 410px;
  }
}
@media only screen and (max-width: 1023px) {
  .form_info{
    min-height: 288px;
  }
  .card{
    height: auto;
  }
  .img{
    position: absolute;
    display: flex;
    align-items: flex-end;
    top: -135px;
    }
  .circle{
    border-radius: 249px;
    width: 249px !important;
    height: 249px !important;
    overflow: hidden;
  }
}
@media only screen and (max-width:800px){
  .full-800px{
    width: 100%;
  }
}
@media only screen and (max-width: 700px) {
  .form_info{
    min-height: 180px;
  }


  .card p{
    font-size: 16px;
  }
  .container{
    width: 100% !important;
  }
  .feathers{
    padding: 18px;
  }

  .details,
  .feathers p{
    text-align: left;
  }

}
@media only screen and (max-width: 375px) {
  .img{
    position: absolute;
    display: flex;
    align-items: flex-end;
    top: -100px;
    }
  .circle{
    border-radius: 249px;
    width: 160px !important;
    height: 160px !important;
    overflow: hidden;
  }
}
</style>