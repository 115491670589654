const map = {
  "pay stub": {uk: "pay slip"},
  "Pay stub": {uk: "Pay slip"},
  "Pay Stub": {uk: "Pay Slip"},
  "paystub": {uk: "payslip"},
  "Paystub": {uk: "Payslip"},
  "paystubs": {uk: "payslips"},
  "Paystubs": {uk: "Payslips"},
  "pay stubs": {uk: "pay slips"},
  "Pay stubs": {uk: "Pay slips"},
  "Pay Stubs": {uk: "Pay Slips"},

  "IRS": {ca: "CRA", uk: "HMRC"},
  "American": {ca: "Canadian", uk: "UK"},
  "US": {ca: "Canadian", uk: "UK"},

  "anatomy alt": {
    us: "",
    ca: " Canada",
    uk: " UK",
  },

  "country name": {
    us: "US",
    ca: "Canada",
    uk: "UK"
  },

  "state": {ca: "province", uk: "country"},
  "State": {ca: "Province", uk: "Country"},

  ", for every state": {
    ca: ", for every province",
    uk: "",
  },

  "state and local government": {
    ca: "federal and provincial government",
    uk: "national tax information",
  },

  "federal and state tax forms": {
    ca: "federal and provincial tax forms",
    uk: "tax forms"
  },

  "state and local employment laws": {
    ca: "federal and provincial employment laws",
    uk: "UK employment law",
  },

  "W2 and 1099 forms.": { ca: "T4s.", uk: "P60s." },

  "Generate 100% Legal Pay Stubs": {
    ca: "Generate 100% Legal Pay Stubs in Canada",
    uk: "Generate 100% Legal UK Payslips",
  },

  "pay stubs, W2s and 1099s": {
    ca: "Canadian pay stubs and T4s updated by Canadian tax professionals",
    uk: "UK payslips and P60s",
  },

  "paycheck stubs": {
    ca: "paycheque stubs",
    uk: "payslips, or wage slips,",
  },

  "Create a pay stub for yourself.": {
    uk: "Create Payslips For Yourself",
  },

  "Create pay stubs for your employees.": {
    uk: "Create Payslips For Your Employees",
  },

  "Apply for health insurance": {
    uk: "Apply for medical insurance",
  },

  "paystubs and IRS compliant W2 and 1099 forms.": {
    ca: "paystubs and CRA compliant T4s.",
    uk: "payslips and HMRC compliant P60s.",
  },

  "Headquartered in Texas,": {
    ca: "Headquartered in North America,",
    uk: "And"
  },

  "contractors": {
    uk: "the self-employed"
  },

  "contractor": {
    uk: "self-employed"
  },

  "employee": {}, //Do not delete. Used in Paystub/details contractor options.

  "State to be used in tax calculations": {
    ca: "Province to be used in tax calculations",
    uk: "Country of tax residence"
  },

  "blog heading": {
	us: "The Best Paystubs Blog | United States",
	ca: "The Best Paystubs Blog | Canada",
	uk: "The Best Paystubs Blog | United Kingdom"
  }

}

export default {
  localize (country, exp) {
    if (! (exp in map)) {
      const err = `Expression key '${exp}' not found in localization map.`;
      alert(err)
      throw new Error(err);
    }
    return map[exp][country] === undefined ? exp : map[exp][country];
  }
}
