<template>

  <input type="text" :value="val" @input="changed" />
    
</template>

<script>

export default {

  props: ["modelValue", "mode"], //mode is either "proper" (default) or "caps"
  emits: ['update:modelValue'],

  data () {
    return {
      val: this.process(this.modelValue),
    }
  },

  watch: {
    modelValue () {
      this.val = this.process(this.modelValue);
    },
  },

  methods: {
    changed (e) {
      this.val = this.process(e.target.value); //this is needed because capitalizeWords trims
      this.$emit('update:modelValue', this.val);
    },
    
    process (str) {
      if (! this.mode || this.mode === 'proper') return this.capitalizeWords(str);
      return this.capitalizeAll(str);
    },

    capitalizeFirst (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    
    capitalizeWords (str) {
      return str.trim().split(' ')
        .filter(s => s !== '')
        .map(s => this.capitalizeFirst(s))
        .join(" ");
    },

    capitalizeAll (str) {
      return str.toUpperCase();
    },
  }

}
</script>
