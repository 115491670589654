export default {
  provinceTaxBrackets: {

    ON: [//Ontario
      { max: 49231,			rate: 5.05,		constant: 0		},
      { max: 98463,			rate: 9.15,		constant: 2018	},
      { max: 150000,		rate: 11.16,	constant: 3998	},
      { max: 220000,		rate: 12.16,	constant: 5498	},
      { max: 9999999999,	rate: 13.16,	constant: 7698	},
    ],

    QC: [ //Quebec //https://www.revenuquebec.ca/en/businesses/source-deductions-and-employer-contributions/employers-kit/principal-changes-for-2023/
      { max: 49275,			rate: 15,		constant: 0		},
      { max: 98540,			rate: 20,		constant: 2463	},
      { max: 119910,		rate: 24,		constant: 6405	},
      { max: 9999999999,	rate: 25.75,	constant: 8503	},
    ],

    NS: [ //Nova Scotia //rates did not change in 2023 compared to 2022.
        { max: 29590,		rate: 8.79,		constant: 0		},
        { max: 59180,		rate: 14.95,	constant: 1823	},
        { max: 93000,		rate: 16.67,	constant: 2841	},
        { max: 150000,		rate: 17.5,		constant: 3613	},
        { max: 9999999999,	rate: 21,		constant: 8863	},
    ],

    NB: [ //New Brunswick
        { max: 47715,		rate: 9.40,		constant: 0 	},
        { max: 95431,		rate: 14,		constant: 2195 	},
        { max: 176756,		rate: 16,		constant: 4104 	},
        { max: 9999999999,	rate: 19.5,		constant: 10290	},
    ],

    MB: [ //Manitoba
        { max: 36842,     	rate: 10.8, 	constant: 0		},
        { max: 79625,     	rate: 12.75,	constant: 718	},
        { max: 9999999999,	rate: 17.4, 	constant: 4421 	},
    ],

    BC: [ //British Columbia
        { max: 45654,		rate: 5.06,     constant: 0 	},
        { max: 91310,		rate: 7.7,      constant: 1205 	},
        { max: 104835,		rate: 10.5,     constant: 3762 	},
        { max: 127299,		rate: 12.29,    constant: 5638 	},
        { max: 172602,		rate: 14.7,     constant: 8706 	},
        { max: 240716,		rate: 16.8,     constant: 12331 },
        { max: 9999999999,	rate: 20.5,     constant: 21238 },
    ],

    PE: [ //Prince Edward Island //rates did not change in 2023 compared to 2022.
        { max: 31984,		rate: 9.8,		constant: 0		},
        { max: 63969,		rate: 13.8,		constant: 1279	},
        { max: 9999999999,	rate: 16.7,		constant: 3134	},
    ],

    SK: [ //Saskatchewan
        { max: 49720,		rate: 10.5,		constant: 0		},
        { max: 142058,		rate: 12.5,		constant: 994	},
        { max: 9999999999,	rate: 14.5,		constant: 3836	},
    ],

    AB: [ //Alberta
        { max: 142292,		rate: 10,	constant: 0 	},
        { max: 170751,		rate: 12,	constant: 2846 	},
        { max: 227668,		rate: 13,	constant: 4553 	},
        { max: 341502,		rate: 14,	constant: 6830 	},
        { max: 9999999999,	rate: 15,	constant: 10245	},
  ],

    NL: [ //Newfoundland and Labrador
        { max: 41457,		rate: 8.7,		constant: 0		},
        { max: 82913,		rate: 14.5,		constant: 2405 	},
        { max: 148027,		rate: 15.8,		constant: 3482 	},
        { max: 207239,		rate: 17.8,		constant: 6443 	},
        { max: 264750,		rate: 19.8,		constant: 10588	},
        { max: 529500,		rate: 20.8,		constant: 13235	},
        { max: 1059000,		rate: 21.3,		constant: 15883	},
        { max: 9999999999,	rate: 21.8,		constant: 21178	},
    ],

    NT: [ //Northwest Territories
        { max: 48326,		rate: 5.9,		constant: 0		},
        { max: 96655,		rate: 8.6,		constant: 1305	},
        { max: 157139,		rate: 12.2,		constant: 4784	},
        { max: 9999999999,	rate: 14.05,	constant: 7691	},
    ],

    YT: [ //Yukon
        { max: 53359,		rate: 6.4,		constant: 0		},
        { max: 106717,		rate: 9,		constant: 1387	},
        { max: 165430,		rate: 10.9,		constant: 3415	},
        { max: 500000,		rate: 12.8,		constant: 6558	},
        { max: 9999999999,	rate: 15,		constant: 17558	},
    ],

    NU: [ //Nunavut
        { max: 50877,		rate: 4,	constant: 0		},
        { max: 101754,		rate: 7,	constant: 1526	},
        { max: 165429,		rate: 9,	constant: 3561	},
        { max: 9999999999,	rate: 11.5,	constant: 7697	},
    ],
  },

  provinceMinTaxable: {
    ON: 17290, //Ontario
    QC: 0, //Quebec //not yet provided
    NS: 12144, //Nova Scotia
    NB: 11450.5, //New Brunswick
    MB: 10727.6, //Manitoba
    BC: 20630, //British Columbia
    PE: 11918.4, //Prince Edward Island
    SK: 17703.4, //Saskatchewan
    AB: 20675, //Alberta
    NL: 10358.4, //Newfoundland and Labrador
    NT: 16614, //Northwest Territories
    YT: 15314, //Yukon
    NU: 17973.8, //Nunavut
  },

  provincialSurtaxBrackets: {
    PE: [
      { max: 12500, 		rate: 0,	constant: 0 },
      { max: 9999999999,	rate: 10,	constant: 0 },
    ],

    ON: [
      { max: 5315,			rate: 0,	constant: 0		},
      { max: 6802,			rate: 20,	constant: 0		},
      { max: 9999999999,	rate: 36,	constant: 279.2	}, //Found by (6387 * 0,2) This constant amount will be added to ((basic payable tax - 6387) * 0,36)
    ],
  },

  provincialHealthPremiumBrackets: { //health premium only available in Ontario. Constant amounts are fixed amounts, no percentages.
    ON: [
      { max: 20000,       constant: 0 	},
      { max: 36000,       constant: 300 },
      { max: 48000,       constant: 450 },
      { max: 72000,       constant: 600 },
      { max: 200000,      constant: 750 },
      { max: 9999999999,  constant: 900 },
    ]
  },

  provincialTaxReductionBrackets: { //only available in BC. Reduction will be lower as income raises.
    BC: [
      { max: 23179,			rate: 0,    	constant: 521 	},//Tax reduction amount is 521 fixed in this bracket
      { max: 37814,			rate: 3.56, 	constant: 0 	}, //Tax Reduction in this bracket is 521 - ((35.659 - yearlyRegular) * 0,0356)
      { max: 9999999999,	rate: 0,    	constant: 0 	},//No tax reduction in this bracket
    ]
  },

}