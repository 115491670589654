<template>
    <section class="container mx-auto mt-12 md:mt-18 px-4 text-center">
      <h2 class="content-h2 text-center max-w-3xl mx-auto">{{title}}</h2>
      <p class="content-italic-subheader">Seriously!</p>

      <ul class="flex flex-col gap-12 md:grid grid-cols-3 md:gap-0 mt-16 items-center md:items-start">
        <li class="flex-1 text-center flex flex-col md:grid grid-flow-row justify-center" 
          v-for="(item, index) of items" :key="index">

          <div class="simple-bg" :class="'simple-' + (index + 1)"></div>        

          <h3 class="text-2xl font-semibold mt-8 text-psn-green-text">{{item.title}}</h3>

          <p class="px-2 leading-loose text-psn-light-text max-w-sm mt-4">{{item.p}}</p>

        </li>
      </ul>
      
      <router-link :to="{name: country + '-paystub'}" class="mt-12 call-to-action-link">GET STARTED NOW</router-link>
    </section>
</template>

<script>
export default {
  computed: {
    country () {
      return this.$route.meta.country;
    },

    title () {
      const title = {        
        "landing-tax-season": "Creating paystubs and key tax documents has never been this simple.",
        "landing-real-estate": "Producing proof of income documents has never been this simple.",
        "landing-small-business": "Creating paystubs and key tax documents has never been this simple.",
      }[this.$route.name];
      
      //Missing title means homepage.
      return title || `Creating ${this.$localize(this.country, "paystubs")} and payroll documents has never been this simple.`;
    },

    items () { 
      let usOnly = 'We’ll print and mail your pay stubs and W2s';
      if (this.$route.name.includes('landing')) usOnly += " for a fee";
      const extra = this.country === 'us' ? `${usOnly}.` : '';

      return [
        { title: "Add your info", p: "Use our secure online forms to enter your personal details in the fields provided."},
        { title: "Preview your document", p: "Make edits before you order. Get documents delivered to your inbox in minutes."},
        { title: `Download your ${this.$localize(this.country, "pay stubs")}`, p: "Download and print the secure PDF. " + extra},
      ];
    } ,

  },
  
}
</script>

<style scoped>
  .simple-bg {    
    height: 105px;
    @apply bg-no-repeat bg-center;
  }

  .simple-1 {
    background-image: url('@/assets/images/home-easy-1.svg');
  }

  .simple-2 {
    background-image: url('@/assets/images/home-easy-2.svg');
  }

  .simple-3 {
    background-image: url('@/assets/images/home-easy-3.svg');
  }
</style>