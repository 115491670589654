<template>

  <div>

    <p class="mb-8"><strong><span class="text-psn-blue">*</span> indicates required field.</strong></p>

    <psn-two-side-container>

      <!-- left side -->
      <template v-slot:left>

        <div class="mb-8">
          <p class="font-bold text-psn-light-header mb-3">Payment Type</p>

          <SelectButton v-model="d.paymentType" :options="['hourly', 'salary']" :unselectable="false">
            <template #option="slotProps">
              <psn-option-button class="mr-6"
                :text="slotProps.option"
                :checked="slotProps.option === d.paymentType" />
            </template>
          </SelectButton>
        </div>

        <div class="mb-8">
          <p class="font-bold text-psn-light-header mb-3">Employment Type
            <psn-tooltip>
              <template #content>
                <p>For Employees, standard tax deductions will be held depending on the state of the user address.</p>
                <p>For {{$localize(country, 'contractors')}}, no deductions will be held.</p>
              </template>
            </psn-tooltip>
          </p>

          <SelectButton v-model="d.employmentType" :options="['employee', 'contractor']" :unselectable="false">
            <template #option="slotProps">
              <psn-option-button class="mr-6"
                :text="$localize(country, slotProps.option)"
                :checked="slotProps.option === d.employmentType" />
            </template>
          </SelectButton>
        </div>

        <div class="mb-8" v-if="['ca'/* , 'uk' */].includes(country) && d.employmentType === 'employee'">
          <p class="font-bold text-psn-light-header mb-3">Employee / Employer copies
            <psn-tooltip>
              <template #content>
                <p>The employer copy has a dedicated <b>employer contributions</b> section for certain withholdings.</p>
                <p>If you require both copies you will receive a zip file containing 2 documents, <b>at no additional price.</b></p>
              </template>
            </psn-tooltip>
          </p>

          <SelectButton v-model="d.copies" :options="['both', 'employee']" :unselectable="false">
            <template #option="slotProps">
              <psn-option-button class="mr-6"
                :text="slotProps.option === 'employee' ? 'Employee only' : 'Both copies'"
                :checked="slotProps.option === d.copies" />
            </template>
          </SelectButton>
        </div>

        <div>
          <label class="block font-bold text-psn-light-header mb-3" for="paystub-details-email">Email address *
            <psn-tooltip content="We will send your document to this email address." />

          </label>
          <input type="email" name="email" autocomplete="email" id="paystub-details-email" required v-model="d.email" class="w-full" />
          <!-- <p class="text-sm italic mt-1">We will send your document to this address.</p> -->
        </div>

      </template>

      <template v-slot:right>

        <div class="mb-8">
          <label for="paystub-details-how-often-paid" class="block font-bold text-psn-light-header mb-3">Payment frequency</label>
          <select id="paystub-details-how-often-paid" v-model="d.paymentPeriodicity" class="md:w-3/5">

            <option value="daily">Daily</option>
            <option value="weekly">Weekly (ex: every Friday)</option>
            <option value="bi-weekly">Bi-Weekly (ex: every other Wednesday)</option>
            <option value="semi-monthly">Semi-Monthly (ex: 1st and 15th)</option>
            <option v-if="country === 'uk'" value="4-weekly">4-Weekly</option>
            <option value="monthly">Monthly (ex: 1st of month only)</option>
            <option value="quarterly">Quarterly</option>
            <option value="semi-annually">Semi-Annually</option>
            <option value="annually">Annually</option>
          </select>
        </div>

        <div class="mb-8">
          <label for="paystub-details-quantity" class="block font-bold text-psn-light-header mb-3">
            Number of {{$localize(country, 'paystubs')}} required
          </label>

          <select id="paystub-details-quantity" v-model="d.quantity" class="md:w-3/5" >
            <option v-for="i in maxStubs" :value="i" :key="i">
              {{i}} {{$localize(country, 'paystub')}}{{i === 1 ? '' : 's'}}
            </option>
          </select>
        </div>

        <div v-if="d.employmentType === 'employee'">
          <label for="paystub-details-state" class="block font-bold text-psn-light-header mb-3">
            {{$localize(country, "State to be used in tax calculations")}}

            <psn-tooltip :content="'Your taxes and deductions will be calculated based on this '
              + $localize(country, 'state') + '.'" />
          </label>
          <psn-state-select id="paystub-details-state" :required="true" v-model="d.state" :country="country" />
        </div>

      </template>

    </psn-two-side-container>

  </div>

</template>


<script>
import SelectButton from 'primevue/selectbutton';

export default {
  components: { SelectButton },

  props: ["details", "country"],

  data () {
    return {
      d: {
        autoCalculator: this.details.autoCalculator,
        paymentType: this.details.paymentType,
        employmentType: this.details.employmentType,
        paymentPeriodicity: this.details.paymentPeriodicity,
        paymentFrequency: this.details.paymentFrequency,
        quantity: this.details.quantity,
        state: this.details.state,
        email: this.details.email,
        copies: this.details.copies,
      }

    }
  },

  computed: {
    maxStubs () {
      return this.d.paymentFrequency > 52 ? 52 : this.d.paymentFrequency;
    }
  },

  watch: {
    //Sync payment frequency with periodicity. Prevent quantity exceeding max stubs.
    "d.paymentPeriodicity" () {
      const map = {
        daily:          260,
        weekly:         52,
        "bi-weekly":    26,
        "semi-monthly": 24,
        "4-weekly":     13,
        monthly:        12,
        quarterly:      4,
        "semi-annually":2,
        annually:       1,
      };
      this.d.paymentFrequency = map[this.d.paymentPeriodicity];
      if (this.d.quantity > this.maxStubs) this.d.quantity = this.maxStubs;
    },

    d: {
      handler () {
        this.$emit('update:details', this.d)
      }, deep: true,
    },

    "d.employmentType" () { //both copies is meaningless for contractor as there are no withholdings.
      if (this.d.employmentType === 'contractor') {
        this.d.copies = "employee";
      }
    },
  },

  async mounted () {
    await this.$nextTick();
    //this.d.quantity = 2;
  }

}
</script>