<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <tr class="block md:table-row">
    <td class="px-0 md:px-4 mt-6">
      <div class="font-semibold md:w-32 mb-4 md:pt-2 relative">
        <button v-if="deletable" 
          class="hidden md:block absolute -left-6 top-1 md:top-3 h-4 w-4 text-psn-blue bg-white text-sm" 
          @click="$emit('remove-earning')" type="button" title="Remove earnings"><span class="pi pi-times"></span></button>

        {{earning.name}}

          <button v-if="deletable" 
          class="md:hidden inline-block h-4 w-4 text-psn-blue bg-white text-sm" 
          @click="$emit('remove-earning')" type="button" title="Remove earnings"><span class="pi pi-times"></span></button>

        <psn-tooltip v-if="earning.isRegular && paystub.details.paymentType === 'salary'" placement="right" >
          <template #content>
            <div style="width: 250px">
              Please enter your <b>yearly</b> income.
              <br><br>
              For example, if you earn $2,000/month, you should enter $24,000 in the "Rate" box.              
            </div>
          </template>
        </psn-tooltip>          
      </div>      
    </td>
    <td>
      <p class="md:hidden font-semibold">Rate</p>
      <div class="border-b-4 border-psn-blue">        
        <InputMoney v-model="earning.rate" />
      </div>
    </td>
    <td>
      <p class="md:hidden font-semibold">Hours</p>
      <div :class="earning.type === 'hourly' ? 'border-b-4 border-psn-blue' : ''">        
        <!-- <input v-if="earning.type === 'hourly'" type="number" v-model="earning.hours" 
           class="w-full text-right p-8" /> -->
        <InputHours v-if="earning.type === 'hourly'" v-model="earning.hours" 
           class="w-full text-right p-8" :max="earning.maxHours" />
        <input v-else placeholder="-" type="text" class="text-center w-full" disabled />
      </div>
    </td>
    <td>
      <p class="md:hidden font-semibold">Total</p>
      <InputMoney v-model="earning.total" :disabled="true" />
    </td>
    <td>
      <p class="md:hidden font-semibold">Prior YTD</p>
      <div :class="! stubIndex ? 'border-psn-blue border-b-4' : ''">
        <InputMoney v-model="earning.priorYTD" :disabled="stubIndex" />
      </div>
    </td>
    <td>
      <p class="md:hidden font-semibold">YTD Total</p>
      <InputMoney v-model="earning.ytdTotal" :disabled="true" />
    </td>
  </tr>
</template>

<script>
import InputHours from '@/components/input-hours.vue';
export default {
  components: { InputHours },
  props: ["earning", "stubIndex", "paystub", "deletable", "country"],
  emits: ["remove-earning"],  
}
</script>

<style scoped>
td {
  @apply border-0 md:border border-gray-300 grid grid-cols-2 md:table-cell py-0;
}

td p {
  @apply pt-2.5 uppercase text-psn-light-header;
}
</style>