import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/home/Home.vue';
import Paystub from "../views/paystub/0-Paystub.vue";
import HreflangMap from "./hreflangMap.js";
import Meta from "@/lib/meta.js";
import logo from "@/assets/images/logo-compressed.svg";
import Ajax from '@/lib/ajax.js';

const hreflangMap = HreflangMap.build();

const noIndex = {name: "robots", content: "noindex"};

/************************************** CA **************************************/
/************************************** CA **************************************/
/************************************** CA **************************************/
/************************************** CA **************************************/
/************************************** CA **************************************/

const ca = [
  { path: "/ca/", name: "ca", component: Home, meta: {
      title: "Pay Stub Generator - Check Stub Maker, Paycheck Stub Creator | The Best Paystubs in Canada",
      tags: [
        {name: "description", content: `The Best Paystubs in Canada | Generate 100% Legal Pay Stubs and T4 Slip |
          Verified by Canadian tax professionals | Access Support 24/7 | We trust in quality`},
      ],
      country: "ca",
      page: "home",
    }
  },

  { path: '/ca/about/', name: 'ca-about', component: () => import('../views/About.vue'),
    meta: {
      title: "Learn All About The Best Paystubs | Canada",
      page: "about",
      country: "ca",
      tags: [
        {name: "description", content: "Learn all about The Best Paystubs | №1 destination in Canada for the highest quality payroll documents"},
      ],
    }
  },

  { path: "/ca/terms-and-conditions/", name: "ca-terms", component: () => import('../views/ca/Terms.vue'),
    meta: {
      title: "Canada - Terms and Conditions",
      tags: [
        {name: "description", content: `These Terms govern your use of and access to thebestpaystubs.com in Canada
          and Software for the purpose of providing tools that allow managing and creating Documents.`}
      ],
      country: "ca",
      page: "terms",
    }
  },

  { path: "/ca/privacy-policy/", name: "ca-privacy", component: () => import('../views/ca/Privacy.vue'),
    meta: {
      title: "Canada - Privacy Policy",
      tags: [
        {name: "description", content: `This Privacy Policy describes the information about you we collect,
          how we collect it, and how we use it, and forms a binding agreement between you and The Best Paystubs in Canada.`}
      ],
      country: "ca",
      page: "privacy",
    }
  },

  { path: "/ca/faq/", name: "ca-faq", component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue'),
    meta: {
      title: "FAQ | The Best Paystubs Canada",
      tags: [
        {name: "description", content: `Got any questions regarding our products or business in Canada?
Our FAQ page should have you covered! Our Customer Support is ready to help 24/7`}
      ],
      country: "ca",
      page: "faq",
    }
  },

  { path: '/ca/pricing/', name: 'ca-pricing', component: () => import('../views/Pricing.vue'),
    meta: {
      title: "The Best Paystubs Pricing in Canada | Check Our Prices and Get Your First Document",
      tags: [
        {name: "description", content: "The Best Paystubs Canada pricing means you can instantly create documents with no hidden fees | Verified by Canadian tax professionals | Access Support 24/7"}
      ],
      country: "ca",
      page: "pricing",
    },
   },

  { path: "/ca/paystub-generator/",  component: Paystub, name: "ca-paystub",
    meta: {
      title: "Create Pay Stubs Online | The Best Paystubs in Canada",
      tags: [
        {name: "description", content: `The Best Paystubs in Canada | Generate 100% Legal Paystubs |
Verified by Canadian tax professionals | Access Support 24/7 | We make it easy`}
      ],
      country: "ca",
      page: "paystub",
    },
  },

  {
    path: "/ca/t4-slip/", component: () => import("../views/t4/T4.vue"), name: "ca-t4",
    meta: {
      title: "T4 Summary Fillable - T4 Tax Form Slip Online | The Best Paystubs in Canada",
      tags: [
        {name: "description", content: `The Best Paystubs in Canada | Generate 100% Legal T4 slip |
Verified by Canadian tax professionals | Access Support 24/7 | We make it easy`}
      ],
      country: "ca",
      page: "t4",
    },
  },

  { path: "/ca/reviews/", name: "ca-reviews", component: () => import(/* webpackChunkName: "reviews" */ '../views/Reviews.vue'),
    meta: {
      title: "Canada Reviews | The Best Paystubs",
      tags: [
        {name: "description", content: `The Best Paystubs Customer Experience | We are proud by the TBP Customer Support Services |
          Verified by Canadian tax professionals | Access Support 24/7`}
      ],
      country: "ca",
      page: "reviews",
    }
  },

  { path: "/ca/blog/", name: "ca-blog", component: () => import(/* webpackChunkName: "blog" */ '../views/Blog/BlogList.vue'),
    meta: {
      title: "The Best Paystubs Blog | Canada",
      country: "ca",
      page: "blog",
    }
  },

  { path: "/ca/blog/:slug/", name: "ca-blog-post", component: () => import(/* webpackChunkName: "blog" */ '../views/Blog/Blog.vue'),
    meta: { //Title and description meta tages are dynamic
      title: "",
      country: "ca",
    }
  },
];

/************************************** UK **************************************/
/************************************** UK **************************************/
/************************************** UK **************************************/
/************************************** UK **************************************/
/************************************** UK **************************************/

const uk = [
  { path: "/uk/", name: "uk", component: Home,
    meta: {
      title: "Pay Slip Generator - Salary Slip Maker, P60 Creator | The Best Paystubs",
      tags: [
        {name: "description", content: `The Best Paystubs | Generate 100% Legal Payslips and P60 form |
        Verified by UK tax professionals | Access Support 24/7 | We make it easy`},
      ],
      country: "uk",
      page: "home",
    }
  },

  { path: '/uk/about/', name: 'uk-about', component: () => import('../views/About.vue'),
    meta: {
      title: "Learn All About The Best Paystubs | United Kingdom",
      page: "about",
      country: "uk",
      tags: [
        {name: "description", content: "Learn all about The Best Paystubs | №1 destination in the UK for the highest quality payroll documents"},
      ],
    }
  },

  { path: "/uk/faq/", name: "uk-faq", component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue'),
    meta: {
      title: "FAQ | The Best Paystubs | United Kingdom",
      tags: [
        {name: "description", content: `Got any questions regarding our products or business in the UK?
        Our FAQ page should have you covered! Our Customer Support is ready to help 24/7`}
      ],
      country: "uk",
      page: "faq",
    }
  },

  { path: "/uk/terms-and-conditions/", name: "uk-terms", component: () => import('../views/uk/Terms.vue'),
    meta: {
      title: "United Kingdom - Terms and Conditions",
      tags: [
        {name: "description", content: `These Terms govern your use of and access to thebestpaystubs.com in the UK
        and Software for the purpose of providing tools that allow managing and creating Documents.`}
      ],
      country: "uk",
      page: "terms",
    }
  },

  { path: "/uk/privacy-policy/", name: "uk-privacy", component: () => import( '../views/uk/Privacy.vue'),
    meta: {
      title: "United Kingdom - Privacy Policy",
      tags: [
        {name: "description", content: `This Privacy Policy describes the information about you we collect,
        how we collect it, and how we use it, and forms a binding agreement between you and The Best Paystubs in the UK.`}
      ],
      country: "uk",
      page: "privacy",
    }
  },

  { path: "/uk/payslip-generator/", component: Paystub, name: "uk-paystub",
    meta: {
      title: "Generate Payslips and Salary Slips Online | The Best Paystubs",
      tags: [
        {name: "description", content: `The Best Paystubs | Create Professional Payslips and Salary Slips
        | Verified by UK tax experts | Access Support 24/7 | We make it easy `}
      ],
      country: "uk",
      page: "paystub",
    },
  },

  { path: "/uk/p60/", component: () => import("../views/p60/P60.vue"), name: "uk-p60",
    meta: {
      title: "P60 Form Online Generator - Make Your P60 in minutes | The Best Paystubs",
      tags: [
        {name: "description", content: `The Best Paystubs | Our online forms make it simple to generate
        and print P60 by using our online P60 generator `}
      ],
      country: "uk",
      page: "p60",
    },
  },

  { path: '/uk/pricing/', name: 'uk-pricing', component: () => import('../views/Pricing.vue'),
    meta: {
      title: "The Best Paystubs Pricing in UK | Check Our Prices and Get Your First Document",
      tags: [
        {name: "description", content: `The Best Paystubs pricing means you can instantly create
          documents with no hidden fees | Verified by UK tax professionals`}
      ],
      country: "uk",
      page: "pricing",
    },
  },

  { path: "/uk/reviews/", name: "uk-reviews", component: () => import('../views/Reviews.vue'),
    meta: {
      title: "United Kingdom Reviews | The Best Paystubs",
      tags: [
        {name: "description", content: `The Best Paystubs Customer Experience | We are proud by the
        TBP Customer Support Services | Verified by UK tax professionals`}
      ],
      country: "uk",
      page: "reviews",
    }
  },

 { path: "/uk/blog/", name: "uk-blog", component: () => import(/* webpackChunkName: "blog" */ '../views/Blog/BlogList.vue'),
   meta: {
     title: "The Best Paystubs Blog | United Kingdom",
     tags: [{name: "description", content: `Read our customer blog and see what our customers have to say about The Best Paystubs in the UK`}],
     country: "uk",
     page: "blog",
   }
 },

 { path: "/uk/blog/:slug/", name: "uk-blog-post", component: () => import(/* webpackChunkName: "blog" */ '../views/Blog/Blog.vue'),
   meta: { //Title and description meta tages are dynamic
     title: "",
     country: "uk",
   }
 },
];

/************************************** US **************************************/
/************************************** US **************************************/
/************************************** US **************************************/
/************************************** US **************************************/
/************************************** US **************************************/

const us = [
  { path: '/', name: 'us', component: Home, meta: {
      title: "Pay Stub Generator - Check Stub Maker, Paycheck Stub Creator | The Best Paystubs",
      tags: [
        {name: "description", content: `The Best Paystubs | Generate 100% Legal Pay Stubs, W-2 and 1099 MISC |
          Verified by US tax professionals | Access Support 24/7 | We make it easy`},
      ],
      country: "us",
      page: "home",
    }
  },

  { path: '/about/', name: 'us-about', component: () => import('../views/About.vue'),
    meta: {
      title: "Learn All About The Best Paystubs",
      page: "about",
      country: "us",
      tags: [
        {name: "description", content: "Learn all about The Best Paystubs | №1 destination for the highest quality payroll documents."},
      ],
    }
  },

  { path: '/pricing/', name: 'us-pricing', component: () => import('../views/Pricing.vue'),
    meta: {
      title: "The Best Paystubs Pricing | Check Our Prices and Get Your First Document",
      tags: [
        {name: "description", content: "The Best Paystubs pricing means you can instantly create documents with no hidden fees | Verified by US tax professionals | Access Support 24/7"}
      ],
      country: "us",
      page: "pricing",
    },
  },

  { path: "/paystub-generator/",  component: Paystub, name: "us-paystub",
    meta: {
      title: "Create Pay Stubs Online | The Best Paystubs",
      tags: [
        {name: "description", content: `The Best Paystubs | Create Professional Paystubs |
Verified by US tax experts | Access Support 24/7 | We make it easy`}
      ],
      country: "us",
      page: "paystub",
    },
  },

  { path: "/w-2-form-generator/", component: () => import("../views/w2/W2.vue"), name: "us-w2",
    meta: {
      title: "W-2 Form Online Generator - Make Your W2 in minutes | The Best Paystubs",
      tags: [
        {name: "description", content: `The Best Paystubs | Our online forms make it simple to
generate and print W-2 by using our online W2 generator | Access Support 24/7`}
      ],
      country: "us",
      page: "w2"
    }
  },

  { path: "/1099-misc-tax-form-generator/", name: "us-1099-misc", component: () => import('../views/1099/1099.vue'),
    meta: {
      title: "1099 MISC Form Generator - Create 1099 Employee Pay Stub Online | The Best Paystubs",
      tags: [
        {name: "description", content: "The Best Paystubs | Generate 1099 MISC | Verified by US tax professionals | Access Support 24/7 | We make it easy"}
      ],
      country: "us",
      page: "1099-misc",
    },
  },

  { path: "/1099-nec-tax-form-generator/", name: "us-1099-nec", component: () => import('../views/1099-nec/1099-nec.vue'),
    meta: {
      title: "1099 NEC Form Generator - Create 1099 Employee Pay Stub Online | The Best Paystubs",
      tags: [
        {name: "description", content: "The Best Paystubs | Generate 1099 NEC | Verified by US tax professionals | Access Support 24/7 | We make it easy"}
      ],
      country: "us",
      page: "1099-nec",
    },
  },

  { path: "/portal/sign-in", name: "sign-in", component: () => import('../views/portal/sign-in.vue'),
    meta: {
      title: "Sign In",
      tags: [
        {name: "description", content: ""}
      ],
      country: "us",
      page: "sign-in",
    },
  },
  { path: "/portal/sign-up", name: "sign-up", component: () => import('../views/portal/sign-up.vue'),
    meta: {
      title: "Sign Up",
      tags: [
        {name: "description", content: ""}
      ],
      country: "us",
      page: "sign-up",
    },
  },

  { path: "/us/faq/", name: "us-faq", component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue'),
    meta: {
      title: "FAQ | The Best Paystubs",
      tags: [
        {name: "description", content: `Got any questions regarding our products or business? Our FAQ page should have you covered!
Our Customer Support is ready to help 24/7`}
      ],
      country: "us",
      page: "faq",
    }
  },

  { path: "/us/terms-and-conditions/", name: "us-terms", component: () => import('../views/us/Terms.vue'),
    meta: {
      title: "US - Terms and Conditions",
      tags: [
        {name: "description", content: `These Terms govern your use of and access to thebestpaystubs.com and
          Software for the purpose of providing tools that allow managing and creating Documents.`}
      ],
      country: "us",
      page: "terms",
    }
  },

  { path: "/us/privacy-policy/", name: "us-privacy", component: () => import('../views/us/Privacy.vue'),
    meta: {
      title: "US - Privacy Policy",
      tags: [
        {name: "description", content: `This Privacy Policy describes the information about you we collect,
          how we collect it, and how we use it, and forms a binding agreement between you and The Best Paystubs.`}
      ],
      country: "us",
      page: "privacy",
    }
  },

  { path: "/reviews/", name: "us-reviews", component: () => import(/* webpackChunkName: "reviews" */ '../views/Reviews.vue'),
    meta: {
      title: "US Reviews | The Best Paystubs",
      tags: [
        {name: "description", content: "The Best Paystubs Customer Experience | We are proud by the TBP Customer Support Services | Verified by US tax professionals | Access Support 24/7"}
      ],
      country: "us",
      page: "reviews",
    }
  },

  { path: "/blog/", name: "us-blog", component: () => import(/* webpackChunkName: "blog" */ '../views/Blog/BlogList.vue'),
    meta: {
      title: "The Best Paystubs Blog",
      country: "us",
      page: "blog",
    }
  },

  { path: "/blog/:slug/", name: "us-blog-post", component: () => import(/* webpackChunkName: "blog" */ '../views/Blog/Blog.vue'),
    meta: { //Title and description meta tages are dynamic
      title: "",
      country: "us",
    }
  },

  {
    path: "/tax-season/", name: "landing-tax-season", component: () => import('../views/landing/Landing.vue'),
    meta: {
      title: "Tax Season: What is it, Examples and How It Works | The Best Paystubs",
      country: "us",
      tags: [
        {name: "description", content: "What is tax season, when tax season start and end and how you can prepare for this with The Best Paystubs | Access Support 24/7 | We make it easy"}
      ]
    }
  },

  {
    path: "/documents-for-homebuyers-and-homeowners/", name: "landing-real-estate", component: () => import('../views/landing/Landing.vue'),
    meta: {
      title: "Proof of income documents for homebuyers and homeowners | The Best Paystubs",
      country: "us",
      tags: [
        {name: "description", content: "Make proof of income documentation for homebuyers and homeowners, etc. with The Best Paystubs | Access Support 24/7 | We make it easy"}
      ]
    }
  },

  {
    path: "/payroll-documentation-for-self-employed-and-small-business/", name: "landing-small-business", component: () => import('../views/landing/Landing.vue'),
    meta: {
      title: "Payroll Documentation for self-employed and small business | Qualifying support by The Best Paystubs",
      country: "us",
      tags: [
        {name: "description", content: "Qualifying support by The Best Paystubs for preparation payroll documentation for self-employed and small business | Access Support 24/7 | We make it easy"}
      ]
    }
  },

];

const commonInternational = [

  { path: "/thank-you/:file", component: () => import("../views/ThankYou.vue"), name: "thank-you",
    meta: {
      title: "Thank you for your purchase",
      tags: [ noIndex ], //prevent indexing
    },
  },

  { path: "/checkout/:paymentResult?/:orderId?", name:"checkout", component: () => import("../views/checkout/Checkout.vue"),
    meta: {
      title: "Checkout",
      tags: [ noIndex ], //prevent indexing
    }
  },
];

//These are global without country suffix. 3ds-response is a popup iframe and calls parent window.
//Never manipulate /3ds-response/ as this is called from the payment processor. Also name is required, without it it stopped working.
const globalRoutes = [
  { path: "/3ds-response/", component: () => import("../views/checkout/3DS-response.vue"), name: "3ds-response",
    meta: {
      country: "us",
      dontRedirect: true, //prevent country caching and redirection
      title: "3DS Response",
      tags: [noIndex]
    }
  },

  { path: '/:pathMatch(.*)', component: () => import("../views/404.vue"),  name: "404",
    meta: {
      country: "us",
      dontRedirect: true, //prevent country caching and redirection
      title: "404 - Page not found",
      tags: [ noIndex ],
    }
  },

  { path: '/5xx/', component: () => import("../views/5xx.vue"),  name: "5xx",
    meta: {
      country: "us",
      dontRedirect: true, //prevent country caching and redirection
      title: "505 - Server error",
      tags: [ noIndex ],
    }
  },
];

//duplicate common routes for each country
["us", "ca", "uk"].forEach(country => {
  commonInternational.forEach(item => {
    const meta = JSON.parse(JSON.stringify(item.meta)); //copy meta
    meta.country = country; //customize for country

    const countryItem = {
      path: country === 'us' ? item.path : `/${country}${item.path}`, //suffix for non-us
      component: item.component,
      name: `${country}-${item.name}`,
      meta,
    }

    if (country === "us") us.push(countryItem);
    if (country === "ca") ca.push(countryItem);
    if (country === "uk") uk.push(countryItem);
  });
});

const routes = [
  ...us,
  ...ca,
  ...uk,
  ...globalRoutes
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    if (to.hash) return { el: to.hash, behavior: "smooth" };

    //TODO: return { x: 0, y: 0 } doesn't work, remove the temporary solution when resolved.
    //https://github.com/vuejs/vue-router/issues/3451

    window.scrollTo(0, 0);
    return { x: 0, y: 0 };
  }
});


//Page titles and meta. Partially adapted from
//https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
router.afterEach((to/* , from */) => {

  //Disable uk links and redirect them to home as requested by Deniz.
  if (to.meta.country === 'uk') return router.replace({name: 'us'});

  if (to.name !== '404') {  //handle case sensitivity and missing slash for SEO.
    let clean = to.path.replace(/\/+/g, "/").toLowerCase();
    if (clean.substring(clean.length - 1, clean.length) !== "/") clean += "/";
    if (to.path !== clean) {
      const redir = location.origin + clean + location.search;
      Meta.makeRedirect(redir);
    }
  }

  if (to.meta.title) document.title = to.meta.title;

  //handle hreflangs and canonical
  Meta.reset();
  const hreflangSet = hreflangMap[to.meta.page];
  if (hreflangSet) {
    Meta.makeCanonical(hreflangSet[to.meta.country]);
    Meta.makeDefaultAlternate(hreflangSet.us || hreflangSet.ca || hreflangSet.uk); //default "en" -> "us", but look for ca too (t4 etc)
    Object.keys(hreflangSet).forEach(key => Meta.makeAlternate(key, hreflangSet[key])); //country specific
  }

  if (to.meta && to.meta.tags) {
    const descItem = to.meta.tags.find(tag => tag.name === 'description');
    if (descItem) Meta.makeDescription(descItem.content);

    const ni = to.meta.tags.find(tag => tag.name === 'robots' && tag.content === 'noindex');
    if (ni)
      Meta.makeNoIndex();
    else {
      const locale = to.meta.country === 'uk' ? 'gb' : to.meta.country;
      Meta.makeOgTag("locale", "en_" + ( locale.toUpperCase()));
      Meta.makeOgTag("type", "website");
      if (to.meta.title) Meta.makeOgTag("title", to.meta.title);
      if (descItem) Meta.makeOgTag("description", descItem.content);
      if (hreflangSet) Meta.makeOgTag("url", hreflangSet[to.meta.country]);
      Meta.makeOgTag("image", location.origin + logo);
    }
  }
});

//reset 5xx
router.beforeEach( () => {
  Ajax.is5xx.value = false;
});

export default router;
