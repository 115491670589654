<template>
  <section class="md:container mx-auto mt-24 px-4">
    <h2 class="content-h2 text-center">Why choose The Best Paystubs?</h2>
    <p class="content-italic-subheader text-center">We’re literally the best.</p>
    <p class="text-psn-light-text text-xl mt-8 lg:mt-16" v-html="content"></p>

    <ul class="grid md:grid-cols-2 gap-6 md:gap-12 pr-14 mt-8 lg:mt-16">
      <li class="flex" v-for="item of items" :key="item.title">
        <span class="check-bg"></span>          
        <div>
          <h3 class="text-2xl text-psn-green-text mb-2 font-semibold">{{item.title}}</h3>
          <span class="text-psn-light-text md:text-lg">{{item.content}}</span>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  props: ["content", "items"],  
}
</script>

<style scoped>
  .check-bg {
    background-image: url('@/assets/images/check-mark.svg');
    @apply block w-14 h-14 mt-2 mr-2 p-2 bg-no-repeat bg-top;	
  }
</style>