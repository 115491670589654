

const map = {
  home: { us: "", ca: "ca", uk: "uk" },
  pricing: { us: "pricing", ca: "ca/pricing", uk: "uk/pricing" },
  paystub: { us: "paystub-generator", ca: "ca/paystub-generator", uk: "uk/payslip-generator" },
  faq: { us: "us/faq", ca: "ca/faq", uk: "uk/faq" },
  terms: { us: "us/terms-and-conditions", ca: "ca/terms-and-conditions", uk: "uk/terms-and-conditions" },
  privacy: { us: "us/privacy-policy", ca: "ca/privacy-policy", uk: "uk/privacy-policy" },
  about: { us: "about", ca: "ca/about", uk: "uk/about" },
  reviews: { us: "reviews", ca: "ca/reviews", uk: "uk/reviews" },
  //blog: { us: "blog", ca: "ca/blog" },

  //us specific
  w2: { us: "w-2-form-generator" },
  "1099-misc": { us: "1099-misc-tax-form-generator" },
  "1099-nec": { us: "1099-nec-tax-form-generator" },

  //ca specific
  t4: { ca: "ca/t4-slip" },

  //uk specific
  p60: { uk: "uk/p60" },
}

//This line is required to easily disable uk, as index uses this map
//to generate alternate links for SEO purposes.
Object.keys(map).forEach(k => delete map[k].uk);

export default {

  build () {

    for (const page in map) {
      Object.keys(map[page]).forEach(country => {
        map[page][country] = location.origin + "/" + map[page][country];
        if (! (page === 'home' && country === 'us')) map[page][country] += "/";
      });
    }

    return map;

  }

}