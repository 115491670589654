<template>
  <div class="font-proxima">

    <PageHeader :title="'Create your ' + $localize(country, 'paystub')"
      :text="`Get your custom ${$localize(country, 'paystub')} with our accurate and dependable
        ${$localize(country, 'paystub')} generator.`" />

    <div class="psn-content-under-gradient bg-white" v-if="ready">

      <div class="md:container md:mx-auto p-0 pt-2 px-2 md:p-4">

        <form @submit.prevent="submitAll">
          <Panel :index="1" :total="6" title="Template Selection" :active="true">
            <Template :paystub="servicePaystubs.template" :country="country"
              @update:logos="paystub.template.logos = $event"
              @update:selectedTemplateIndex="paystub.template.selectedTemplateIndex = $event"
              @update:colors="paystub.template.colors = $event"
              @update:color-options="colorOptions = $event"
              :color-options="colorOptions"
              />
          </Panel>

          <Panel :index="2" :total="6" :title="$localize(country, 'Paystub') + ' Details'" :active="true">
            <Details :details="paystub.details" @update:details="paystub.details = $event" :country="country" />
          </Panel>

          <Panel :index="3" :total="6" title="Company Information" :active="true">
            <Company :company="paystub.company" @update:company="paystub.company = $event" :country="country"  />

          </Panel>

          <Panel :index="4" :total="6" title="Employee Information" :active="true">
            <Employee :employee="paystub.employee" @update:employee="paystub.employee = $event" :country="country"
              :details="paystub.details" />
          </Panel>

          <Panel :index="5" :total="6" title="Earnings Statement" :active="true">
            <Stubs :paystub="paystub" :max-periods="runner.maxPeriods" :stubs-runner="runner" :country="country" />
          </Panel>

          <Panel :index="6" :total="6" title="Review" :active="true">
            <Review :paystub="servicePaystubs.preview" :country="country"
              :colors="paystub.template.colors" :color-options="colorOptions"
              @update:colors="paystub.template.colors = $event"
              @update:fields="(key, fields) => runner.setStubFields(key, fields)"
              @update:selectedTemplateIndex="paystub.template.selectedTemplateIndex = $event"
              />
          </Panel>

          <div class="text-right">
            <psn-sharp-button :submit="true" text="Checkout" id="paystub-checkout-button" />
          </div>
        </form>

      </div>

    </div>

  </div>
</template>

<script>
import PageHeader from "@/components/psn-page-header.vue";
import Template from "./1-Template.vue";
import Details from "./2-Details.vue";
import Company from "./3-Company.vue";
import Employee from "./4-Employee.vue";
import Stubs from "./5a-Stubs.vue";
import Review from "./6-Review.vue";
import cache from "../../lib/cache.js";

import StubsRunner from "./cls/StubsRunner";

import Panel from "@/components/psn-form-panel.vue";
import utils from "./templates/utils.js";

export default {
  components: {
    PageHeader, Panel,
    Template, Details, Company, Employee, Stubs, Review,
  },

  data () {
    return {
      orderId: null,
      devMode: false, //To work on internal calculations.
      ready: false,
      abandonedCoupon: null, //Abandoned cart retrievals come with a coupon, we need to keep.
      colorOptions: null,
      runner: null,

      paystub: {
        country: "",
        period0Index: -1,
        template: {
          logos: {},
          selectedTemplateIndex: null,
          colors: null,
        },
        details: {
          autoCalculator: true,
          paymentType: 'hourly',
          employmentType: 'employee', //other is contractor
          paymentFrequency: 26,
          paymentPeriodicity: "bi-weekly",
          quantity: 1,
          state: "",
          email: "",
          copies: "both", //other is "employee"
        },
        company: {
          name: "",
          phone: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
        },
        employee: {
          name: "",
          ssn: "",
          id: "",
          phone: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",

          exemptions: 0,
          maritalStatus: "single",
          title: "", //ca only
          department: "", //ca only
          directDeposit: "no",
          bank: "", //ca only
          accountNumber: "",

          //uk only:
          taxCode: "1257L",
          isBlindPerson: false,
          NICategory: "A",
          NIClass3Amount: 0,
          yearlyProfit: 0,
        },
        stubs: [],
      },
    }
  },

  methods: {
    adjustDates (paystub) {
      paystub.stubs.forEach(stub => {
        stub.payDate = utils.absoluteDate(stub.payDate);
        stub.payPeriod.start = utils.absoluteDate(stub.payPeriod.start);
        stub.payPeriod.end = utils.absoluteDate(stub.payPeriod.end);
      });
    },

    submitAll () {
      //correct date strings for all dates:
      this.adjustDates(this.paystub);
      cache.setOrder(this.country + "-paystub", this.paystub, this.paystub.details.email, this.country, this.orderId);

      const hash = this.abandonedCoupon ? ('#coupon-' + this.abandonedCoupon) : "";
      this.$router.push({name: this.country + "-checkout", hash});
    },

    //order is either from cache, dummy data or server
    fillFromOrder (order) {
      ["company", "employee", "template", "details", "stubs"].forEach(f => {
        if (f in order) Object.assign(this.paystub[f], order[f]);
      });

      ["period0Index"].forEach(f => {
        if (f in order) this.paystub[f] = order[f];
      });

      //following line is needed because we added the country key later.
      if (! this.paystub.country) this.paystub.country = this.country;

      //deserialize dates:
      this.paystub.stubs.forEach(stub => {
        stub.payPeriod.start = new Date(stub.payPeriod.start);
        stub.payPeriod.end = new Date(stub.payPeriod.end);
        stub.payDate = new Date(stub.payDate);
      });
    },
  },

  computed: {
    country () {
      return this.$route.meta.country;
    },

    servicePaystubs () {
      const template = utils.getPaystubValues(this.paystub, this.country, "template");
      const preview = utils.getPaystubValues(this.paystub, this.country, "preview");
      return {template, preview}
    },
  },

  watch: {
    "paystub.details.state" () {
      if (this.country !== 'us') return; //CA states are optional
      if (this.paystub.company.state === '') this.paystub.company.state = this.paystub.details.state;
      if (this.paystub.employee.state === '') this.paystub.employee.state = this.paystub.details.state;
    },

    "paystub.details.employmentType" () {
      if (this.country === 'uk') {
        this.paystub.employee.NIClass = this.paystub.details.employmentType === 'contractor' ? "" : "1";
        this.paystub.employee.NIOption = this.paystub.details.employmentType === 'contractor' ? "" : "A";
      }
    },

    "paystub.template.selectedTemplateIndex": {
      handler () {
        this.colorOptions = null;
      }
    },

    colorOptions: {
      handler () {
        if (! this.colorOptions) return;
        this.paystub.template.colors = this.colorOptions[0];
      }
    }
  },

  async created () {
    await this.$nextTick();
    //const desc = "A written document that details gross earnings, net pay, deductions, etc. for an employee.";
    //const name = "Paystub - " + this.country.toUpperCase();
    //this.$meta.makeLD_product(name, desc, 7.99, this.country === 'us' ? 29.99 : 7.99);
    this.$meta.makeLD_product(this.country, "paystub");

    let order;
    this.abandonedCoupon = "";

    if (this.$route.query.fill === 'reset') { //hidden api to destroy cached order

      cache.resetTypedOrder(this.country + "-paystub");
      this.$router.replace({name: this.country + "-paystub"});

    } else if (this.$route.query.fill === 'normal') { //test run

      const dummyContentProvider = (await import("./test-dummy.js")).default;
      order = dummyContentProvider.testData[this.country];

    } else if (this.$route.query.id) { //abandoned cart

      const ret = await this.$get("/api/orders/retrieve/" + this.$route.query.id);
      //we have ret.data.info -> {country, type: "paystub", coupon: "abandoned cart coupon code"}

      if (ret.message)
        alert(ret.message); //TODO: Make a proper popup.
      else {

        order = ret.data.document;
        this.orderId = this.$route.query.id;
        this.abandonedCoupon = ret.data.info.coupon;

      }

    } else { //check if local storage has something

      //order = null; //temporary disable
      order = cache.getTypedOrder(this.country + "-paystub");
      //if (order) console.log("AHANDA VARMIŞ ORDER: ", order);
    }

    if (order) this.fillFromOrder(order);

    //from this point on everything is new:
    this.paystub.country = this.country;
    this.runner = new StubsRunner(this.paystub, this.country);
    this.runner.init(! this.paystub.stubs.length);

    await this.$nextTick();
    this.ready = true;
  }

}

</script>
