<template>
  <button class="bg-psn-blue text-white px-6 py-2 text-xl uppercase font-bold disabled:opacity-50"
    :disabled="isDisabled" :type="submit ? 'submit': 'button'" ref="button">

    <svg v-if="isBack" class="inline-block mr-2 mb-0.5" xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 7 14">
      <path d="M0 6.97L6.98 13.95L6.98 10.46L3.49 6.97L6.98 3.49L6.98 0L0 6.97Z" fill="#ffffff"></path>
    </svg>
    
    <span v-if="! loading">{{text}}</span>
    <i v-else class="pi pi-spin pi-spinner text-base" style="font-weight: bold"></i>
    
    <svg v-if="isNext && ! loading" class="inline-block ml-2 mb-0.5" 
      xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 7 14">
      <path d="M7 6.97L0.02 13.95L0.02 10.46L3.51 6.97L0.02 3.49L0.02 0L7 6.97Z" fill="#ffffff"></path>
    </svg>

  </button>
</template>

<script>
export default {
  props: {
    isNext: Boolean,
    isBack: Boolean,
    text: String,
    loading: Boolean,  
    disabled: Boolean,
    submit: Boolean,
  },

  computed: {
    isDisabled () {
      return this.disabled || this.loading;
    }
  },
}
</script>