<template>
  <div>

    <!-- Slider for desktop-->
    <div class="carousel hidden lg:block">            
        <swiper
          :slidesPerView="2"
          :spaceBetween="30"
          :pagination="{
            clickable: true,
          }"
          :modules="modules"
          class="mySwiper"
        >
          <swiper-slide v-for="(t, i) of testimonials" :class="!i ? 'mx-auto' : ''" :key="t.name" >
            <div class="card">
              <div class="img"> 
                <div class="circle">
                  <img :src="t.pic" :alt="'Picture of ' + t.name">
                </div>
                <div class="quote-bg"></div>
              </div>
              <p>{{t.p}}</p>
              <span>— {{t.name}}</span>
            </div>
          </swiper-slide>
          
        </swiper>
    </div>

    <!-- Slider for smaller screens -->
    <div class="carousel lg:hidden">
      
      <swiper
          :slidesPerView="1"
          :spaceBetween="30"
          :pagination="{
            clickable: true,
          }"
          :modules="modules"
          class="mySwiper"
        >
        <swiper-slide v-for="(t, i) of testimonials" :class="!i ? 'mx-auto' : ''" :key="t.name" >
            <div class="card">
              <div class="img"> 
                <div class="circle">
                  <img :src="t.pic" :alt="'Picture of ' + t.name">
                </div>
                <div class="quote-bg"></div>
              </div>
              <p>{{t.p}}</p>
              <span>— {{t.name}}</span>
            </div>
        </swiper-slide>              
      </swiper>
    </div>

  </div>
</template>

<script>
  import { Swiper, SwiperSlide } from "swiper/vue";
  import "swiper/css";
  import "swiper/css/pagination";
  import { Pagination } from "swiper";

  export default {
    components:{
      Swiper, SwiperSlide,
    },

    props: ["testimonials"],

    setup() {
      return {
        modules: [Pagination],
      }
    },
  }  
</script>

<style scoped>
.quote-bg {
  background-image: url('@/assets/images/quote2.svg');
  @apply h-16 w-16 lg:h-10 lg:w-10 bg-no-repeat bg-contain bg-center;
}

.carousel{
  margin: 40px 0;
}

.swiper {
  height: auto;
  width: 100%;
  padding-top: 160px;
  @apply pb-16 md:pb-24;
}

:deep(.swiper-pagination-bullet-active)  {
  background-color: #00C0A2 !important; 
}
:deep(.swiper-pagination-bullet){
  height: 20px;
  width: 20px;
} 
.swiper-slide {
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 18px;
  background: #fff;
    display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.card{
  width: 100%;
  height: 317px;
  box-shadow: 0px 0px 17px -5px #000000;
  margin: 10px;
  padding: 41px;
  font-size: 20px;
  text-align: right;
  color: #5E5E6C;
  position: relative;
  padding-top:125px ;

}
.card p{
  text-align: left;
}

.img{
  position: absolute;
  display: flex;
  align-items: flex-end;
  top: -135px;
  }
.circle{
  border-radius: 249px;
  width: 249px !important;
  height: 249px !important;
  overflow: hidden;
}

@media only screen and (max-width: 1440px) {
  .card{
    height: 360px;
  }
  .img{
    position: absolute;
    display: flex;
    align-items: flex-end;
    top: -100px;
    }
  .circle{
    border-radius: 249px;
    width: 160px !important;
    height: 160px !important;
    overflow: hidden;
  }
}

@media only screen and (max-width: 1390px) {
  .form_info{
    min-height: 260px;
    
  }
}
@media only screen and (max-width:1220px) {
  .card{
    height: 390px;
  }
}
@media only screen and (max-width: 1144px) {

  .card{
    height: 410px;
  }
}
@media only screen and (max-width:1220px) {
  .card{
    height: 390px;
  }
}
@media only screen and (max-width: 1144px) {

  .card{
    height: 410px;
  }
}
@media only screen and (max-width: 1023px) {

  .card{
    height: auto;
  }
  .img{
    position: absolute;
    display: flex;
    align-items: flex-end;
    top: -135px;
    }
  .circle{
    border-radius: 249px;
    width: 249px !important;
    height: 249px !important;
    overflow: hidden;
  }
}
@media only screen and (max-width: 700px) {
  
  .carousel_head h3{
    font-size: 30px;
  }
  
  .carousel_head p{
    font-size: 18px;
  }
  .card p{
    font-size: 16px;
  }
  .card{
    text-align: right !important;
  }

}
@media only screen and (max-width: 375px) {
  .img{
    position: absolute;
    display: flex;
    align-items: flex-end;
    top: -100px;
    }
  .circle{
    border-radius: 249px;
    width: 160px !important;
    height: 160px !important;
    overflow: hidden;
  }
}
</style>