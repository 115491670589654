<template>
<!-- eslint-disable vue/no-mutating-props -->

  <div>

    <Accordion class="psn-earnings-accordion" :activeIndex="ui.activeStubIndex" >
      <AccordionTab v-for="(stub, index) of paystub.stubs" :key="stub._key">

        <!-- accordion header -->
        <template #header>

          <div class="clear-both w-full">
            <p class="float-left text-lg md:text-xl font-bold text-white">
              <span class="uppercase">{{$localize(country, 'paystub')}}</span> #{{index + 1}} <span class="hidden md:inline"> - </span>
              <span class="block mt-2 md:mt-0 md:inline text-sm md:text-xl">
                Pay period: {{dayFormat(stub.payPeriod.start)}} to {{dayFormat(stub.payPeriod.end)}}
              </span>
            </p>   
            
            <!-- expand arrow -->
            <div class="float-right pt-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="10" viewBox="0 0 49.999 20">
                <path d="M20,20,0,40V30L10,20,0,10V0Z" transform="translate(39.999) rotate(90)" fill="white"></path>
              </svg>
            </div>  
          </div>
    
        </template>

        <!-- accordion body -->
        <div>

          <p class="my-8">
            <span class="inline-block h-4 border-b-4 border-psn-blue w-16 md:w-32"></span>
            indicates editable field. All other fields are auto-filled, please be cautious if overwriting.
          </p>
          
          <div v-if="! index" class="mb-8">
            <label class="font-bold block" for="paystub-stubs-period0Index">Pay period number</label>
            <psn-form-select id="paystub-stubs-period0Index" v-model.number="paystub.period0Index" 
              class="border-b-4 border-psn-blue inline-block" :required="true" >
              <option v-for="i of maxPeriods" :key="i" :value="i">Pay period #{{i}}</option>
            </psn-form-select>
            <psn-tooltip>
              <template #content>
                <p>This marks how many pay periods have passed since the beginning of the year.</p>
                <p>When you create multiple {{$localize(country, 'paystubs')}}, 
                  keep in mind that {{$localize(country, 'Paystub')}} #1 is the <b>oldest</b> {{$localize(country, 'paystub')}}. 
                  Pay period number will automatically increase starting from {{$localize(country, 'Paystub')}} #1. </p>
                <p><b>This will affect your earnings and tax deductions.</b></p>
              </template>
            </psn-tooltip>                 
          </div>

          <Stub :paystub="paystub" :stub="stub" :stub-index="index" 
            :stubs-runner="stubsRunner" :country="country"
            @period-changed="(what, date) => periodChanged(index, what, date)"            
          />

        </div>
        
      </AccordionTab>
    </Accordion>


  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props -- because we want stubs to be manipulated everywhere */
import Accordion from 'primevue/accordion/sfc';
import AccordionTab from 'primevue/accordiontab/sfc';

import Stub from "./5b-Stub.vue";
import dayjs from 'dayjs';

export default {
  components: { Accordion, AccordionTab, Stub },

  props: ["paystub", "maxPeriods", "stubsRunner", "country"],

  data () {
    return {
      ui: {
        activeStubIndex: 0,
      },
    }
  },

  methods: {
    dayFormat (day) {
      switch (this.country) {
        case "us": return dayjs(day).format('MM/DD/YYYY');
        case "ca": return dayjs(day).format('YYYY/MM/DD');
        case "uk": return dayjs(day).format('DD/MM/YYYY');
        default: return "";
      }
    },

    periodChanged (stubIndex, which, day) {
      this.stubsRunner.stubPeriodChanged(stubIndex, which, day);
    },
  },

}
</script>

<style scoped>

.psn-earnings-accordion :deep(.p-accordion-header) {
  @apply bg-psn-blue;
}

.psn-earnings-accordion :deep(.p-accordion-toggle-icon) {
  display: none;
}

</style>