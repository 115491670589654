<template>

  <div>

    <p class="mb-4"><strong><span class="text-psn-blue">*</span> indicates required field.</strong></p>

    <psn-two-side-container>

      <!-- left side -->
      <template v-slot:left>

        <div v-for="field of fields" :key="field.field" class="mb-4">

          <!-- proper input -->
          <psn-input-proper v-if="field.type === 'proper'" type="text" v-model="e[field.field]"
            :pattern="field.pattern" :mode="field.properMode || 'proper'"
            :placeholder="field.label" :required="field.req" class="w-full" />

          <!-- regular input -->
          <input v-if="field.type === 'text'" type="text" v-model="e[field.field]"
            :pattern="field.pattern"
            :placeholder="field.label" :required="field.req" class="w-full" />

          <!-- mask -->
          <InputMask v-else-if="field.type === 'mask'" v-model="e[field.field]"
            :mask="field.mask" :pattern="field.pattern" :required="field.req"
            :placeholder="field.label" class="w-full" />

          <!-- state -->
          <StateSelect v-else-if="field.type === 'state' && country !== 'uk'" v-model="e.state"
            :required="field.req" class="w-full" :country="country" />

          <!-- phone -->
          <PhoneNumber v-else-if="field.type === 'phone' && country !== 'uk'" v-model="e[field.field]"
            :placeholder="field.label" class="w-full" :country="country" />

          <!-- zip -->
          <PsnZip v-else-if="field.type === 'zip'" v-model="e[field.field]" :required="field.req"
            class="w-full" :country="country" />
        </div>
      </template>

      <!-- right side -->
      <template v-slot:right>

        <div class="mb-12" v-if="country === 'us'">
          <label for="paystub-employee-exemptions" class="font-bold mb-2 block">
            Exemptions *
          </label>
          <select required id="paystub-employee-exemptions" v-model.number="e.exemptions" class="w-full">
            <option v-for="i of 9" :key="i" :value="i- 1">{{i - 1}}</option>
          </select>
        </div>

        <div class="mb-12" v-if="country === 'us'">
          <p class="font-bold text-psn-light-header mb-3">Marital Status</p>

          <SelectButton v-model="e.maritalStatus" :options="['single', 'married']" :unselectable="false">
            <template #option="slotProps">
              <psn-option-button class="mr-6"
                :text="slotProps.option"
                :checked="slotProps.option === e.maritalStatus" />
            </template>
          </SelectButton>
        </div>

        <!-- UK has blind person / national insurance for employees -->
        <template v-if="country === 'uk'">
          <div class="mb-6">
            <label class="psn-block-label">
              Tax code
              <input type="text" v-model="e.taxCode" required />
            </label>
          </div>

          <div class="mb-6" v-if="details.employmentType === 'contractor'">
            <label class="psn-block-label">
              Yearly profits subject to National Insurance contributions
              <InputMoney v-model="e.yearlyProfit" />
            </label>
          </div>

          <div class="mb-6" v-if="details.employmentType === 'employee'">
            <label class="psn-block-label">National Insurance Class 1 options
              <select data-hj-allow class="w-full" v-model="e.NICategory">
                <option v-for="(text, code) in nationalSecurityOptions" :value="code" :key="code">{{code}} - {{text}}</option>
              </select>
            </label>
          </div>

          <div class="mb-6">
            <label class="psn-block-label">NI Class 3 yearly contribution amount
              <input-money v-model="e.NIClass3Amount" class="w-full" />
            </label>
          </div>

          <div class="mb-6">
            <label class="font-bold flex gap-2 items-center cursor-pointer">
              <input type="checkbox" v-model="e.isBlindPerson" class="w-5 h-5" /> Eligible for blind person's allowance
            </label>
          </div>
        </template>

        <!-- Canada templates #4 and #5 special fields -->
        <template v-if="['ca', 'uk'].includes(country)">
          <div class="mb-4">
            <label class="psn-block-label">
              Job Title <psn-tooltip content="This field appears only on templates #4 and #5" />
              <input type="text" v-model="e.title" class="w-full" data-hj-allow />
            </label>
          </div>

          <div class="mb-4">
            <label class="psn-block-label">
              Department <psn-tooltip content="This field appears only on templates #4 and #5" />
              <input type="text" v-model="e.department" class="w-full" data-hj-allow />
            </label>
          </div>
        </template>

        <!-- Direct deposit section -->
        <div class="mb-4">
          <p class="font-bold text-psn-light-header mb-3">
            Direct Deposit
            <psn-tooltip v-if="country === 'ca'" content="Bank and account number fields appear only on templates #4 and #5." />
          </p>

          <SelectButton v-model="e.directDeposit" :options="['no', 'yes']" :unselectable="false">
            <template #option="slotProps">
              <psn-option-button class="mr-6"
                :text="slotProps.option"
                :checked="slotProps.option === e.directDeposit" />
            </template>
          </SelectButton>
        </div>

        <template v-if="e.directDeposit === 'yes'">

          <div v-if="country === 'ca'" class="mb-4">
            <input type="text" v-model="e.bank" class="w-full"
              placeholder="Bank" />
          </div>

          <div>
            <InputMask v-model="e.accountNumber" required
              placeholder="Account number (last 4 digits)" mask="9999" pattern="[0-9]{4}"
              :auto-clear="false" class="w-full" />
          </div>

        </template>

      </template>

    </psn-two-side-container>

  </div>

</template>

<script>
import SelectButton from 'primevue/selectbutton';
import StateSelect from "/src/components/psn-state-select";
import PhoneNumber from "/src/components/phone-number";
import InputMask from 'primevue/inputmask';
import PsnZip from '@/components/psn-zip.vue';
import InputMoney from '@/components/input-money.vue';

export default {

  components: { SelectButton, StateSelect, PhoneNumber, InputMask, PsnZip, InputMoney },

  props: ["employee", "country", "details"],

  data () {
    return {
      e: this.employee,
    }
  },

  computed: {
    fields () {
      const ssnSettings = {
        us: {
          label: "Employee Social (last 4 digits)",
          pattern: "[0-9]{4}",
          mask: "9999",
          type: "mask",
        },
        ca: {
          label: "Employee SIN (last 3 digits)",
          pattern: "[0-9]{3}", //canada uses last 3 digits.
          mask: "999",
          type: "mask",
        },
        uk: {
          label: "Employee NI number",
          type: "proper",
          properMode: "caps",
          pattern: `(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\\s*\\d\\s*){6}([A-D]|\\s)`,
        }
      };

      const ssn = Object.assign({field: "ssn", req: false}, ssnSettings[this.country]);

      return [
        {field: "name", label: "Employee Name *", req: true, type: "proper"},
        ssn,
        {field: "id", label: "Employee ID", req: false, type: "text"},
        {field: "phone", label: "Employee Phone Number", req: false, type: "phone"},
        {field: "address1", label: "Employee Address 1 " + (this.country === 'us' ? '*' : ''), req: this.country === 'us', type: "proper"},
        {field: "address2", label: "Employee Address 2", req: false, type: "proper"},
        {field: "city", label: "City " + (this.country === 'us' ? ' *' : ''), req: this.country === 'us', type: "proper"},
        {field: "state", req: this.country === 'us', type: "state"},
        {field: "zip", req: this.country === 'us', type: "zip"},
      ];
    },

    nationalSecurityOptions () { //UK only
      return { //National insurance employee categories
        A: "All employees apart from those in groups B, C, H, J, M, V and Z in this table", //This must be the default when page opens.
        B: "Married women and widows entitled to pay reduced National Insurance",
        C: "Employees over the State Pension age",
        F: "All employees who work in freeports, apart from those in groups I, L, and S in this table",
        H: "Apprentices under 25",
        I: "Married women and widows who work in freeports and are entitled to pay reduced National Insurance",
        J: "Employees who can defer National Insurance because they're already paying it in another job",
        L: "Employees who work in freeports and can defer National Insurance because they're already paying it in another job",
        M: "Employees under 21",
        S: "Employees who work in freeports and are over the State Pension age",
        V: "Employees who are working in their first job since leaving the armed forces (veterans)",
        X: "For employees who do not have to pay National Insurance, for example because they're under 16",
        Z: "Employees under 21 who can defer National Insurance because they're already paying it in another job",
      }
    }
  },

  watch: {
    e: {
      handler () {
        this.$emit('update:employee', this.e);
      }, deep: true,
    }
  },

}
</script>


