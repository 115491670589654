<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div>

    <div class="flex flex-wrap gap-8">

      <div>
        <label class="block font-bold mb-2">Pay period
          <psn-tooltip>
            <template #content>
              <p>This will be the <b>first pay period</b> that you have worked.</p>
              <p>If you select more than 1 {{$localize(country, 'paystub')}}, the
                {{$localize(country, 'paystubs')}} will automatically back track from the
                <b>first</b> {{$localize(country, 'paystub')}}.
              </p>
            </template>
          </psn-tooltip>
        </label>


        <div class="flex">
          <Calendar class="border-b-4 border-psn-blue"
            :manualInput="false"
            v-model="stub.payPeriod.start"
            :dateFormat="dateFormat"
            :minDate="minMaxDates.min"
            @date-select="periodChanged('start', $event)"
          />

          <span class="px-4 pt-2">-</span>

          <Calendar disabled
            :manualInput="false"
            v-model="stub.payPeriod.end"
            :dateFormat="dateFormat"
            :maxDate="minMaxDates.max"
            @date-select="periodChanged('end', $event)"
          />
        </div>

      </div>

      <div>
        <label for="paystub-paydate" class="block font-bold mb-2">Pay date
          <psn-tooltip>
            <template #content>
              <p>The pay date is the day you get paid.</p>
              <p>By default, all {{$localize(country, 'paystubs')}} will have the same amount of days between the <b>pay date</b> and the <b>last pay period day</b>.</p>
              <p>If your last pay period day is January 15 and pay date is January 22, all subsequent {{$localize(country, 'paystubs')}} will have 7 days between these dates by default.</p>
              <p><b>This default calculation is only active when you make changes for {{$localize(country, 'Paystub')}} #1.</b>
                For other {{$localize(country, 'paystubs')}}, you may customize your pay dates and other {{$localize(country, 'paystubs')}} won't be affected.
              </p>
            </template>
          </psn-tooltip>
        </label>

        <Calendar
          class="border-b-4 border-psn-blue"
          :manualInput="false"
          id="paystub-paydate"
          v-model="stub.payDate"
          :dateFormat="dateFormat"
          data-hj-allow
        />
      </div>

    </div>

    <!-- earnings table -->
    <table class="block md:table border-collapse psn-earnings-grid table-auto
      bg-transparent md:bg-white w-full mt-8">
      <thead class="hidden md:table-header-group">
        <tr>
          <th class="text-left">Earnings</th>
          <th>Rate</th>
          <th>Hours</th>
          <th>Total</th>
          <th>Prior YTD
            <psn-tooltip>
              <template #content>
                <p class="text-left">This is the amount you have earned since January 1.</p>
                <p class="text-left">Our system automatically calculates this based on the pay period, pay period number and rate you provide.</p>
                <p class="text-left">You may customize the Prior YTD field <b>only in {{$localize(country, 'Paystub')}} #1</b> to your needs.</p>
              </template>
            </psn-tooltip>

          </th>
          <th>YTD Total</th>
        </tr>
      </thead>
      <tbody class="flex flex-col gap-8 divide-y md:table-row-group w-full">
        <template v-for="(earning, index) of stub.earnings" :key="earning.key">
          <Earning v-if="stubsRunner.isEarningVisible(stub, earning)"
              :deletable="stubsRunner.isEarningDeletable(stub, earning)"
              :earning="earning" :stub-index="stubIndex" :paystub="paystub"
              @remove-earning="removeEarning(earning, index)" :country="country"
            />
        </template>
      </tbody>
      <tfoot class="psn-earnings-tfoot hidden md:table-footer-group">
        <tr>
          <td class="p-4">
            <small>
              <a
                role="button"
                class="text-psn-blue cursor-pointer"
                @click="ui.addEarningVisible = true"
              >+ Add earnings</a>
            </small>
          </td>
          <td colspan="2" class="text-right uppercase p-4 font-bold">Gross pay</td>
          <td>
            <InputMoney v-model="stub.total" :disabled="false" />
          </td>
          <td>
            <InputMoney v-model="stub.priorYTD" :disabled="true" />
          </td>
          <td>
            <InputMoney v-model="stub.ytdTotal" :disabled="true" />
          </td>
        </tr>
      </tfoot>
    </table>

    <!-- mobile -->
    <div class="md:hidden">
      <hr class="mt-8" />

      <p class="mt-4">
        <a role="button" class="text-psn-blue cursor-pointer" @click="ui.addEarningVisible = true">+ Add earnings</a>
      </p>

      <hr class="mt-4" />

      <p class="uppercase text-center font-semibold text-psn-light-header text-xl mt-8">Gross pay</p>

      <table class="mt-8 w-full">
        <thead>
          <tr>
            <th>Total</th>
            <th>Prior YTD</th>
            <th>YTD Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <InputMoney v-model="stub.total" :disabled="false" />
            </td>
            <td>
              <InputMoney v-model="stub.priorYTD" :disabled="true" />
            </td>
            <td>
              <InputMoney v-model="stub.ytdTotal" :disabled="true" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- deductions table -->
    <!-- deductions table -->
    <!-- deductions table -->
    <table v-for="cat of deductionCategories" :key="cat.key"
        class="block md:table border-collapse psn-deductions-table table-auto
          bg-transparent md:bg-white w-full mt-8 md:w-4/5 psn-earnings-grid">
      <thead class="hidden md:table-header-group" >
        <tr>
          <th class="text-left">{{cat.title}}</th>
          <th>Total</th>
          <th>Prior YTD</th>
          <th>YTD Total</th>
        </tr>
      </thead>
      <tbody v-if="paystub.details.employmentType === 'employee'" class="flex flex-col gap-8 divide-y md:table-row-group w-full">
        <tr v-for="(deduction) of cat.deductions" :key="deduction.key" class="block md:table-row">
          <td class="px-0 md:px-4 mt-6">
            <div class="font-semibold mb-4 md:pt-2 relative">
              <button v-if="deduction.isCustom"
                class="hidden md:block absolute -left-6 top-1 md:top-3 h-4 w-4 text-psn-blue bg-white text-sm"
                @click="removeDeduction(deduction)" type="button" title="Remove deduction"><span class="pi pi-times"></span></button>

              {{deduction.name}}

              <button v-if="deduction.isCustom"
                class="md:hidden h-4 w-4 text-psn-blue bg-white text-sm"
                @click="removeDeduction(deduction)" type="button" title="Remove deduction"><span class="pi pi-times"></span></button>
            </div>

              <!-- <button
                class="absolute -left-2 top-4 h-4 w-4 text-psn-blue bg-white text-sm pi pi-times"
                @click="removeDeduction(deduction, index)"
                title="Remove deduction"
              ></button>
              <input type="text" placeholder="Enter deduction" v-model="deduction.name" class="p-0 -ml-2" /> -->
          </td>
          <td>
            <p class="md:hidden font-semibold">TOTAL</p>
            <div class="border-psn-blue border-b-4">
              <InputMoney v-model="deduction.amount" />
            </div>
          </td>
          <td>
            <p class="md:hidden font-semibold">PRIOR YTD</p>
            <div :class="! stubIndex || deduction.isCustom ? 'border-psn-blue border-b-4' : ''">
              <InputMoney v-model="deduction.priorYTD" :disabled="stubIndex && ! deduction.isCustom" />
            </div>
          </td>
          <td>
            <p class="md:hidden font-semibold">YTD TOTAL</p>
            <div>
              <InputMoney :disabled="true" v-model="deduction.ytdTotal" />
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot class="psn-earnings-tfoot hidden md:table-footer-group">
        <tr v-if="paystub.details.employmentType === 'employee'">
          <td class="p-4" colspan="4">
            <small>
              <a
                role="button"
                class="text-psn-blue cursor-pointer"
                @click="customDeductionName = ''; customDeductionNature = cat.nature; ui.addDeductionsVisible = true"
              >+ Add {{cat.nature}}</a>
            </small>
          </td>
        </tr>
        <tr>
          <td class="py-2 px-4" style="min-width: 162px" >{{cat.totals.header}}</td>
          <td v-if="paystub.details.employmentType === 'contractor'" colspan="3" class="text-center">
            <i>No deductions are calculated for {{$localize(country, 'contractors')}}.</i>
          </td>
          <template v-else>
            <td>
              <InputMoney v-model="cat.totals.total" :disabled="true" />
            </td>
            <td>
              <InputMoney v-model="cat.totals.priorYTD" :disabled="true" />
            </td>
            <td>
              <InputMoney v-model="cat.totals.ytdTotal" :disabled="true" />
            </td>
          </template>
        </tr>
        <tr v-if="cat.key === 'employee'">
          <td class="py-2 px-4">Net Pay</td>
          <td>
            <InputMoney v-model="stub.netPayTotal" :disabled="true" />
          </td>
          <td>
            <InputMoney v-model="stub.netPayPriorYTD" :disabled="true" />
          </td>
          <td>
            <InputMoney v-model="stub.netPayYTDTotal" :disabled="true" />
          </td>
        </tr>
      </tfoot>
    </table>

    <!-- mobile -->
    <div class="md:hidden">
      <hr class="mt-8" />

      <p v-if="paystub.details.employmentType !== 'contractor'" class="mt-4">
        <a role="button" class="text-psn-blue cursor-pointer" @click="customDeductionName = ''; ui.addDeductionsVisible = true">+ Add deduction</a>
      </p>
      <p class="mt-8 text-center" v-else>
        <i>No deductions are calculated for {{$localize(country, 'contractors')}}.</i>
      </p>
    </div>

    <!-- Add earnings dialogue -->
    <PsnDialog header="Add earnings" v-model="ui.addEarningVisible"
      :closeable="true" @submit="addCustomEarning"
      :submit-text="additionalEarningIsCustom ? 'Add' : undefined"
      >

      <div class="mt-4">
        <ul>

          <li class="add-item-row" v-for="earningProto of stubsRunner.getAvailableEarningProtos(stub)"
            :key="earningProto.key">
            <a class="pi pi-plus-circle" title="Add earnings"
              @click="additionalEarningIsCustom = false; addNonRegularEarning(earningProto)"></a>
            {{earningProto.name}}
          </li>

          <li class="font-semibold mb-2">
            <div class="add-item-row">
              <a class="pi pi-plus-circle" title="Add custom earning"
                @click="additionalEarningIsCustom = true"></a>

              Custom
            </div>

            <div class="p-4 border border-gray-300 mt-2" v-if="additionalEarningIsCustom">
              <p class="font-semibold text-xl">Custom earnings</p>

              <p class="my-2">
                <input type="text" v-model="customEarningName" required
                  placeholder="Earnings title *" />
              </p>

              <SelectButton v-model="customEarningType" :options="['hourly', 'once']" :unselectable="false">
                <template #option="slotProps">
                  <psn-option-button class="mr-6"
                    :text="slotProps.option === 'hourly' ? 'Hourly' : 'One-time'"
                    :checked="slotProps.option === customEarningType" />
                </template>
              </SelectButton>

            </div>
          </li>
        </ul>

      </div>
    </PsnDialog>

    <!-- Add deductions dialogue -->
    <PsnDialog :header="'Add ' + customDeductionNature"
      :closeable="true"
      submit-text="Add"
      v-model="ui.addDeductionsVisible"
      @submit="addCustomDeduction">

      <div class="mt-4 flex items-end gap-4">
        <label>
          <span class="block mb-2 font-bold capitalize">{{customDeductionNature}} title: </span>
          <input autofocus type="text" v-model="customDeductionName" required />
        </label>
      </div>
    </PsnDialog>

  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props -- because we want stubs to be manipulated everywhere */
import dayjs from "dayjs";
import Calendar from 'primevue/calendar';
import Earning from "./5c-Earning.vue";
import SelectButton from "primevue/selectbutton";
import PsnDialog from "@/components/psn-dialog.vue";

export default {
  components: { Calendar, Earning, SelectButton, PsnDialog},

  props: ["paystub", "stub", "stub-index", "stubs-runner", "country"],
  emits: ['period-changed', "add-earning"],

  data () {
    return {
      ui: {
        addEarningVisible: false,
        addDeductionsVisible: false,
      },
      additionalEarningIsCustom: false,
      customEarningType: "hourly",
      customEarningName: "",
      customDeductionName: "",
      customDeductionNature: "deduction" //other is contribution
    }
  },

  computed: {
    dateFormat () {
      return "M d, yy";
    },

    minMaxDates () {
      return {
        min: dayjs("2021-01-01").toDate(),
        max: dayjs().endOf("year").toDate(),
      }
    },

    deductionCategories () {
      const cats = [
        {
          key: "employee", deductions: this.stub.employeeDeductions, title: "DEDUCTIONS",
          nature: "deduction",
          totals: {
            header: "Deduction Total",
            total: this.stub.deductionTotal, priorYTD: this.stub.deductionPriorYTD, ytdTotal: this.stub.deductionYTDTotal,
          }
        }
      ];

      if (["ca", "uk"].includes(this.country) && this.paystub.details.copies === 'both')
        cats.push(
          {
            key: "employer", deductions: this.stub.employerDeductions, title: "EMPLOYER CONTRIBUTIONS",
            nature: "contribution",
            totals: {
              header: "Employer Contribution Total",
              total: this.stub.employerTotal, priorYTD: this.stub.employerPriorYTD, ytdTotal: this.stub.employerYTDTotal,
            }
          }
        );
      return cats;
    },
  },

  methods: {
    addCustomDeduction () {
      this.stubsRunner.addDeduction(this.stub, this.customDeductionName, this.customDeductionNature);
      this.ui.addDeductionsVisible = false;
    },

    addCustomEarning () {
      this.stubsRunner.addCustomEarning(this.stub, this.customEarningName, this.customEarningType);
      this.ui.addEarningVisible = false;
      this.additionalEarningIsCustom = false;
    },

    addNonRegularEarning (earningProto) {
      this.stubsRunner.addEarning(this.stub, earningProto);
      this.ui.addEarningVisible = false;
    },

    removeEarning (earning) {
      this.stubsRunner.removeEarning(this.stub, earning);
    },

    //TODO: Likewise, consider moving the implementation into Stub.js
    removeDeduction (deduction) {
      const index = this.stub.deductions.findIndex(d => d.key === deduction.key);
      this.stub.deductions.splice(index, 1);
    },

    periodChanged(which, day) {
      this.$emit('period-changed', which, day);
    },
  },

}
</script>

<style scoped>
.psn-earnings-grid :deep(input), .psn-deductions-table :deep(input){
  @apply p-2.5 bg-transparent md:bg-white
      border md:border-0 rounded-none
      text-psn-light-text font-bold
      focus:outline-none focus:ring-0 focus:border-transparent;
}

.psn-deductions-table tbody td {
  @apply border-0 md:border border-gray-300 grid grid-cols-2 md:table-cell py-0;
}

.psn-deductions-table tbody td p {
  @apply pt-2.5 uppercase text-psn-light-header;
}

.psn-earnings-grid th/* , .psn-earnings-grid td */ {
  @apply border border-gray-300;
}
.psn-deductions-table td {
  @apply border border-gray-300;
}

.psn-earnings-grid tfoot td {
  @apply border border-gray-300;
}

.psn-earnings-grid th {
  @apply px-4 py-3 uppercase;
}

.add-item-row {
  @apply font-semibold mb-2 flex items-center gap-2;
}

.add-item-row a {
  @apply cursor-pointer text-psn-blue text-xl;
}
</style>
