<template>
  <button type="button" :class="classes">
    {{text}}    
  </button>
</template>

<script>
export default {
  props: ["text", "checked"],

  computed: {
    classes () {
      const def = "py-2 text-sm rounded uppercase font-bold".split(' ');
      if (! this.checked) {
        def.push("bg-white text-psn-blue border-2 border-psn-blue px-4");        
      } else {
        def.push("bg-psn-blue text-white border-2 border-transparent bg-no-repeat psn-button-with-bg pl-10 pr-4");
      }
      return def;
    }
  }
}
</script>

<style scoped>
  .psn-button-with-bg {
    background-image: url("../assets/images/check-mark-white.svg");    
    background-size: 1.1rem auto;
    background-position: 0.7rem 0.75rem;
    
  }
</style>
