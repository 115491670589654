export default {
  provinceTaxBrackets: {

    ON: [//Ontario
      { max: 51446,			rate: 5.05,		constant: 0		},
      { max: 102894,		rate: 9.15,		constant: 2109	},
      { max: 150000,		rate: 11.16,	constant: 4177	},
      { max: 220000,		rate: 12.16,	constant: 5677	},
      { max: 9999999999,	rate: 13.16,	constant: 7877	},
    ],

    QC: [ //Quebec //https://www.revenuquebec.ca/en/businesses/source-deductions-and-employer-contributions/employers-kit/principal-changes-for-2023/
      { max: 49275,			rate: 15,		constant: 0		},
      { max: 98540,			rate: 20,		constant: 2463	},
      { max: 119910,		rate: 24,		constant: 6405	},
      { max: 9999999999,	rate: 25.75,	constant: 8503	},
    ],

    NS: [ //Nova Scotia //rates did not change in 2023 compared to 2022.
        { max: 29590,		rate: 8.79,		constant: 0		},
        { max: 59180,		rate: 14.95,	constant: 1823	},
        { max: 93000,		rate: 16.67,	constant: 2841	},
        { max: 150000,		rate: 17.5,		constant: 3613	},
        { max: 9999999999,	rate: 21,		constant: 8863	},
    ],

    NB: [ //New Brunswick
        { max: 49958,		rate: 9.40,		constant: 0 	},
        { max: 99916,		rate: 14,		constant: 2298 	},
        { max: 185064,		rate: 16,		constant: 4296 	},
        { max: 9999999999,	rate: 19.5,		constant: 10774	},
    ],

    MB: [ //Manitoba
        { max: 47000,     	rate: 10.8, 	constant: 0		},
        { max: 100000,     	rate: 12.75,	constant: 917	},
        { max: 9999999999,	rate: 17.4, 	constant: 5567 	},
    ],

    BC: [ //British Columbia
        { max: 47937,		rate: 5.06,     constant: 0 	},
        { max: 95875,		rate: 7.7,      constant: 1266 	},
        { max: 110076,		rate: 10.5,     constant: 3950 	},
        { max: 133664,		rate: 12.29,    constant: 5920 	},
        { max: 181232,		rate: 14.7,     constant: 9142 	},
        { max: 252752,		rate: 16.8,     constant: 12948 },
        { max: 9999999999,	rate: 20.5,     constant: 22299 },
    ],

    PE: [ //Prince Edward Island //rates did not change in 2023 compared to 2022.
        { max: 32656,		rate: 9.65,		constant: 0		},
        { max: 64313,		rate: 13.63,	constant: 1300	},
        { max: 105000,		rate: 16.65,	constant: 3242	},
        { max: 140000,		rate: 18.8,		constant: 4659	},
        { max: 9999999999,	rate: 18.75,	constant: 5709	},
    ],

    SK: [ //Saskatchewan
        { max: 52057,		rate: 10.5,		constant: 0		},
        { max: 148734,		rate: 12.5,		constant: 1041	},
        { max: 9999999999,	rate: 14.5,		constant: 4016	},
    ],

    AB: [ //Alberta
        { max: 148269,		rate: 10,	constant: 0 	},
        { max: 177922,		rate: 12,	constant: 2965 	},
        { max: 237230,		rate: 13,	constant: 4745 	},
        { max: 355845,		rate: 14,	constant: 7117 	},
        { max: 9999999999,	rate: 15,	constant: 10675	},
  ],

    NL: [ //Newfoundland and Labrador
        { max: 43198,		rate: 8.7,		constant: 0		},
        { max: 86395,		rate: 14.5,		constant: 2505 	},
        { max: 154244,		rate: 15.8,		constant: 3629 	},
        { max: 215943,		rate: 17.8,		constant: 6713 	},
        { max: 275870,		rate: 19.8,		constant: 11032	},
        { max: 551739,		rate: 20.8,		constant: 13791	},
        { max: 1103478,		rate: 21.3,		constant: 16550	},
        { max: 9999999999,	rate: 21.8,		constant: 22067	},
    ],

    NT: [ //Northwest Territories
        { max: 50597,		rate: 5.9,		constant: 0		},
        { max: 101198,		rate: 8.6,		constant: 1366	},
        { max: 164525,		rate: 12.2,		constant: 5009	},
        { max: 9999999999,	rate: 14.05,	constant: 8053	},
    ],

    YT: [ //Yukon
        { max: 55867,		rate: 6.4,		constant: 0		},
        { max: 111733,		rate: 9,		constant: 1453	},
        { max: 173205,		rate: 10.9,		constant: 3575	},
        { max: 500000,		rate: 12.8,		constant: 6866	},
        { max: 9999999999,	rate: 15,		constant: 17866	},
    ],

    NU: [ //Nunavut
        { max: 53268,		rate: 4,	constant: 0		},
        { max: 106537,		rate: 7,	constant: 1598	},
        { max: 173205,		rate: 9,	constant: 3729	},
        { max: 9999999999,	rate: 11.5,	constant: 8059	},
    ],
  },

  provinceMinTaxable: {
    ON: 17290, //Ontario
    QC: 0, //Quebec //not yet provided
    NS: 12144, //Nova Scotia
    NB: 11450.5, //New Brunswick
    MB: 10727.6, //Manitoba
    BC: 20630, //British Columbia
    PE: 11918.4, //Prince Edward Island
    SK: 17703.4, //Saskatchewan
    AB: 20675, //Alberta
    NL: 10358.4, //Newfoundland and Labrador
    NT: 16614, //Northwest Territories
    YT: 15314, //Yukon
    NU: 17973.8, //Nunavut
  },

// Surtax system in PE as been removed starting 2024.
  provincialSurtaxBrackets: {
//    PE: [
//      { max: 12500, 		rate: 0,	constant: 0 },
//      { max: 9999999999,	rate: 10,	constant: 0 },
//    ],

    ON: [
      { max: 5554,			rate: 0,	constant: 0		},
      { max: 7108,			rate: 20,	constant: 0		},
      { max: 9999999999,	rate: 36,	constant: 310.8	}, //Found by (6387 * 0,2) This constant amount will be added to ((basic payable tax - 6387) * 0,36)
    ],
  },

  provincialHealthPremiumBrackets: { //health premium only available in Ontario. Constant amounts are fixed amounts, no percentages.
    ON: [
      { max: 20000,       constant: 0 	},
      { max: 36000,       constant: 300 },
      { max: 48000,       constant: 450 },
      { max: 72000,       constant: 600 },
      { max: 200000,      constant: 750 },
      { max: 9999999999,  constant: 900 },
    ]
  },

  provincialTaxReductionBrackets: { //only available in BC. Reduction will be lower as income raises.
    BC: [
      { max: 24338,			rate: 0,    	constant: 547 	},//Tax reduction amount is 521 fixed in this bracket
      { max: 39703,			rate: 3.56, 	constant: 0 	}, //Tax Reduction in this bracket is 521 - ((35.659 - yearlyRegular) * 0,0356)
      { max: 9999999999,	rate: 0,    	constant: 0 	},//No tax reduction in this bracket
    ]
  },

}