<template>
  <div>
    <nav class="hidden lg:flex flex-row items-center lg:gap-12 gap-3 px-12 mb-8 pt-8">
      <router-link :to="{ name: country }">
        <img class="psn-logo" src="@/assets/images/logo-compressed.svg" width="120" alt="The Best Paystubs" />
      </router-link>

      <ul class="flex flex-row flex-grow font-proxima tracking-wide lg:gap-4 xl:gap-10 gap-2
        place-content-end font-semibold content-center lg:text-sm text-xs">

        <li>
          <router-link :class="{ 'psn-active-menu': false }" :to="{ name: country + '-paystub' }">
            <span class="flex items-center">{{$localize(country, "Paystub")}}</span>
          </router-link>
        </li>

        <li class="group relative">
          <span class="items-center"><span class="items-center"> Documents</span><span
            class="psn-dropdown-menu bg-no-repeat bg-right"></span></span>
          <div class="absolute w-36 z-10 hidden group-hover:block pt-6">
            <ul style="box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.1);margin-left: -0.75rem;">
              <li v-for="doc of docs" :key="doc.route">
                <h3>
                  <router-link class="pl-3" active-class="psn-active-menu"
                    :to="{ name: doc.route }">{{ doc.text }}</router-link>
                </h3>
              </li>
            </ul>
          </div>
        </li>

        <li>
          <router-link :class="{ 'psn-active-menu': false }" :to="{ name: country + '-pricing' }"><span
              class="flex items-center"> Pricing</span></router-link>
        </li>

        <li class="group relative">
          <span>
            <span>About Us</span>
            <span class="psn-dropdown-menu bg-no-repeat bg-right"></span>
          </span>
          <div class="absolute w-32 z-10 hidden group-hover:block pt-6">
            <ul style="box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.1);margin-left: -0.75rem;">
              <li>
                <router-link class="pl-3" active-class="psn-active-menu"
                  :to="{ name: country + '-about' }">About Us</router-link>
              </li>
              <li>
                <router-link class="pl-3" active-class="psn-active-menu"
                  :to="{ name: country + '-reviews' }">Reviews</router-link>
              </li>
              <li>
                <router-link class="pl-3" active-class="psn-active-menu" :to="resolveUri('faq')">FAQ</router-link>
              </li>
              <li>
                <router-link class="pl-3" active-class="psn-active-menu"
                  :to="{ name: country + '-blog' }">Blog</router-link>
              </li>
            </ul>
          </div>
        </li>

        <li class="group relative">
          <span>
            <span>
              <template v-for="(item, cntr) of countries" :key="cntr">
                <span v-if="country === cntr" class="bg-contain bg-no-repeat w-5 h-5 bg-center"
                  :class="'flag-' + cntr"></span>
              </template>
              &nbsp; {{ country.toUpperCase() }}
            </span>
            <span class="psn-dropdown-menu bg-no-repeat bg-right"></span>
          </span>

          <div class="absolute w-36 z-10 hidden group-hover:block pt-6">
            <ul style="box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.1); margin-left: -0.75rem;">
              <li class="pt-1 uppercase">
                <span class="text-xs border-b border-gray-200 pl-3">Select country</span>
              </li>
              <li v-for="(item, cntr) in countries" :key="cntr" :class="{ 'psn-active-menu': country === cntr }">
                <router-link class="pl-3 flex items-center" :to="{ name: cntr }">
                  <img class="w-6 h-3 mr-1" :src="item.flag" :alt="item.name" />
                  {{ item.name }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>
      </ul>

      <!-- href="mailto:support@thebestpaystubs.com -->
      <a href="javascript:void(0);" @click="$otherAPI.initChat()"
        class="tracking-wide bg-psn-blue lg:px-6 px-2 rounded text-white uppercase py-2.5 text-center">
        Get support
      </a>
    </nav>

    <!-- phone nav -->
    <!-- phone nav -->
    <!-- phone nav -->
    <nav class="py-4 px-2 lg:hidden">
      <div class="flex relative items-center justify-between">
        <div class="flex">
          <div class="p-2 pt-1 w-12" role="navigation" @click="ui.menuActive = !ui.menuActive">
            <span class="border-black border-b-2 block w-7 mb-1.5"></span>
            <span class="border-black border-b-2 block w-7 mb-1.5"></span>
            <span class="border-black border-b-2 block w-7"></span>
          </div>
          <router-link :to="{ name: country }" class="block flex-grow text-center">
            <img width="95" class="inline-block" src="@/assets/images/logo-compressed.svg" alt="The Best Paystubs" />
          </router-link>
        </div>

        <nav class="flex items-center">
          <ul class="flex flex-row flex-grow font-proxima tracking-wide lg:gap-10 gap-3
            place-content-end font-semibold content-center lg:text-sm text-xs">
            <li class="group relative">
              <span @click="isShowCountry = !isShowCountry">
                <span class="text-base">
                  <template v-for="(item, cntr) in countries" :key="cntr">
                    <span v-if="country === cntr" class="bg-contain bg-no-repeat w-5 h-5 bg-center"
                      :class="'flag-' + cntr"></span>
                  </template>
                  &nbsp; {{ country.toUpperCase() }}
                </span>
                <span class="psn-dropdown-menu bg-no-repeat bg-right"></span>
              </span>

              <div class="absolute w-32 z-10 group-hover:block pt-2" :class="[isShowCountry ? 'block' : 'hidden']">
                <ul style="box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.1); margin-left: -0.75rem;">
                  <li class="pt-1 uppercase">
                    <span class="text-xs border-b border-gray-200 pl-3">Select country</span>
                  </li>
                  <li v-for="(item, cntr) of countries" :key="cntr" :class="{ 'psn-active-menu': country === cntr }">
                    <router-link class="pl-3 flex items-center" :to="{ name: cntr }" @click="isShowCountry = false">
                      <img class="w-6 h-3 mr-1" :src="item.flag" :alt="item.name" />
                      {{ item.name }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>

          <a class="tracking-wide bg-psn-blue h-8 px-4 rounded text-white uppercase text-sm pt-1.5 ml-3"
            href="javascript:void(0);" @click="$otherAPI.initChat()">
            Support
          </a>
        </nav>
      </div>

      <ul class="bg-white uppercase text-base font-proxima top-14 p-2 pr-2 -left-2 psn-phone-nav z-50"
        v-show="ui.menuActive" style="width: 100%">

        <li @click="ui.menuActive = !ui.menuActive" class="group relative border-t border-grey">
          <router-link :to="{ name: country + '-paystub' }">
            <span>{{$localize(country, "Paystub")}}</span>
          </router-link>
        </li>

        <li class="group relative border-t border-grey">
          <span class="flex items-center justify-between" @click="isDocument = !isDocument">
            <span>Documents</span>
            <span class="psn-dropdown-menu bg-no-repeat bg-right" :class="isDocument ? 'rotate' : ''"></span>
          </span>

          <div v-show="isDocument">
            <ul style="line-height: 1">
              <li v-for="doc of docs" :key="doc.route" @click="isDocument = !isDocument; ui.menuActive = !ui.menuActive;">
                <h3>
                  <router-link style="font-size: 1rem" class="pl-2" active-class="psn-active-menu"
                    :to="{ name: doc.route }">{{ doc.text }}
                  </router-link>
                </h3>
              </li>
            </ul>
          </div>
        </li>

        <li @click="ui.menuActive = !ui.menuActive" class="group relative border-t border-grey">
          <router-link :to="{ name: country + '-pricing' }"><span> Pricing</span></router-link>
        </li>

        <li class="group relative border-t border-grey">
          <span class="flex items-center justify-between" @click="isAbout = !isAbout">
            <span>About Us</span>
            <span class="psn-dropdown-menu bg-no-repeat bg-right" :class="isAbout ? 'rotate' : ''"></span>
          </span>

          <div v-show="isAbout">
            <ul style="line-height: 1">
              <li @click="isAbout = !isAbout; ui.menuActive = !ui.menuActive;">
                <router-link style="font-size: 1rem" class="pl-2" active-class="psn-active-menu"
                  :to="{ name: country + '-about' }">About Us</router-link>
              </li>
              <li @click="isAbout = !isAbout; ui.menuActive = !ui.menuActive;">
                <router-link style="font-size: 1rem" class="pl-2" active-class="psn-active-menu"
                  :to="{ name: country + '-reviews' }">Reviews</router-link>
              </li>
              <li @click="isAbout = !isAbout; ui.menuActive = !ui.menuActive;">
                <router-link style="font-size: 1rem" class="pl-2" active-class="psn-active-menu"
                  :to="resolveUri('faq')">FAQ</router-link>
              </li>
              <li @click="isAbout = !isAbout; ui.menuActive = !ui.menuActive;">
                <router-link style="font-size: 1rem" class="pl-2" active-class="psn-active-menu"
                  :to="{ name: country + '-blog' }">Blog</router-link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import caFlag from "@/assets/images/ca.svg";
import usFlag from "@/assets/images/us.svg";
//import ukFlag from "@/assets/images/uk.svg";


export default {
  emits: ["init-chat"],

  data() {
    return {
      isShowCountry: false,
      isCountry: false,
      isDocument: false,
      isAbout: false,
      ui: {
        menuActive: false,
      },
    };
  },

  computed: {
    country() {
      return this.$route.meta.country;
    },

    countries() {
      return {
        us: { flag: usFlag, name: "United States" },
        ca: { flag: caFlag, name: "Canada" },
        //uk: { flag: ukFlag, name: "United Kingdom" },
      };
    },

    docs() {
      switch (this.country) {
        case "us":
          return [
            { route: "us-w2", text: "W-2" },
            //text: "Free Invoice"
            { route: "us-1099-misc", text: "1099-MISC" },
            //{ route: "us-1099-nec", text: "1099-NEC" },
          ];
        case "ca":
          return [ { route: "ca-t4", text: "T4" } ];
        case "uk":
          return [ {route: "uk-p60", text: "P60" } ];
        default:
          return null;
      }
    },
  },

  methods: {
    resolveUri(name) {
      switch (name) {
        case "faq":
          return "/" + this.country + "/faq/";
      }
    },
  },
};
</script>

<style scoped>
nav {
  color: #141f4e;
  font-size: 14px;
}

nav>ul>li {
  @apply uppercase;
}

nav>ul>li a,
nav>ul>li span {
  @apply flex h-full pr-2 py-2;
}

nav>ul>li a {
  @apply cursor-pointer;
}

nav ul ul {
  @apply bg-white font-normal normal-case tracking-normal;
  /*Spec here: https://app.asana.com/0/0/1201605861578234/1201660218495124/f */
}

nav ul ul li {
  color: #666;
}

nav ul ul li a:hover {
  @apply bg-psn-blue text-white;
}

.psn-active-menu {
  background-color: #f7f8fa;
}

.psn-dropdown-menu {
  background-size: 10px;
  background-image: url(../assets/images/caret-down.png);
  background-position: center;
}

.rotate {
  transform: rotate(-90deg);
}

.psn-phone-nav li {
  @apply mb-2;
}

.flag-ca {
  background-image: url("@/assets/images/ca.svg");
}

.flag-us {
  background-image: url("@/assets/images/us.svg");
}

.flag-uk {
  /* background-image: url("@/assets/images/uk.svg"); */
}
</style>