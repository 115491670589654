"use strict";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import IncomeTax from "./incomeTaxUK-2022-2023";
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

const personalAllowance = { //same logic as standard deduction. Same for all GB.
  "2019_2020": 12500,
  "2020_2021": 12500,
  "2021_2022": 12570,
  "2022_2023": 12570,
  "2023_2024": 12570, //Tuna edit this when the value is known.
}

const personalAllowanceThreshold = { //The Personal Allowance goes down by £1 for every £2 of income above the £100,000 limit. It can go down to zero. Same for all GB.
  "2019_2020": 100000,
  "2020_2021": 100000,
  "2021_2022": 100000,
  "2022_2023": 100000,
  "2023_2024": 100000, //Tuna edit this when the value is known.
}

const blindPersonsAllowance = {
  "2019_2020": 2450,
  "2020_2021": 2500,
  "2021_2022": 2520,
  "2022_2023": 2600,
  "2023_2024": 2600,  //Tuna edit this when the value is known.
}

export default {
  //status can be either "single" or "married"
  calcFederal (yearlyRegular, periodTotal, freq, period, state, additionalData) {

    const {isBlindPerson, insuranceCategory, employmentType,
        yearlyProfit, paymentPeriodicity, NIClass3Amount} = additionalData;

    //- extract tax year
    let year = dayjs(period.end).year();
    const target = `${year}-04-06`; //1st day of the next tax year.
    const isNextYear = dayjs(period.end).isSameOrAfter(target);
    const yearKey = isNextYear ? `${year}_${year + 1}` : `${year - 1}_${year}`;

    //- Calculate allowance:
    let actualPersonalAllowance;
    if (yearlyRegular > personalAllowanceThreshold[yearKey]) {
      //Personal allowance is reduced by 1 pound for each 2 pounds above the threshold:
      actualPersonalAllowance = personalAllowance[yearKey] - (yearlyRegular - personalAllowanceThreshold[yearKey]) / 2;
      //Limit the actualPersonalAllowance so that it won' exceed the personal allowance.
      if (actualPersonalAllowance >= personalAllowance[yearKey] * 2) actualPersonalAllowance = 0;
    } else {
      actualPersonalAllowance = personalAllowance[yearKey];
    }

    //-. tax bracket is found using the yearly regular:
    let taxable = yearlyRegular - actualPersonalAllowance;
    if (isBlindPerson) taxable -= blindPersonsAllowance[yearKey];
    if (taxable < 0) taxable = 0;

    //- income tax:
    let effectiveIncomeTaxRate = 0;
    if (state) {
      const brackets = IncomeTax.incomeTaxBrackets[state];
      const bracketIndex = brackets.findIndex(b => taxable <= b.max);
      const bracket = brackets[bracketIndex];
      const prevBracket = brackets[bracketIndex - 1];
      const prevCeil = prevBracket ? prevBracket.max : 0;

      const yearlyNominalTax = (taxable - prevCeil) * (bracket.rate / 100) + bracket.plus;
      effectiveIncomeTaxRate = (yearlyNominalTax / yearlyRegular) || 0; //sometimes comes NaN hence the trick.
    }

    const ret = [{name: "PAYE Tax", amount:  + (periodTotal * effectiveIncomeTaxRate).toFixed(2), key: "income"}];

    //class 3 yearly contribution:
    if (NIClass3Amount) {
      ret.push({
        name: "National Insurance Class 3 Contribution",
        amount: + (NIClass3Amount / freq).toFixed(2),
        isEmployer: false
      });
    }

    //- National insurance:

    const freqToNIBracketMap = {
      daily: {wm: "weekly", co: 1/7},
      weekly: {wm: "weekly", co: 1},
      "bi-weekly": {wm: "weekly", co: 2},
      "4-weekly": {wm: "weekly", co: 4},
      "semi-monthly": {wm: "monthly", co: 1/2},
      monthly: {wm: "monthly", co: 1},
      quarterly: {wm: "monthly", co: 3},
      "semi-annually": {wm: "monthly", co: 6},
      annually: {wm: "monthly", co: 12},
    }

    const incomePeriod = freqToNIBracketMap[paymentPeriodicity].wm;
    const yearConversionCo = incomePeriod === "weekly" ? 52 : 12;

    //find insurance class:
    const INSURANCE_TAX_NAME = 'Nat. Ins.';
    let insuranceClass;
    if (employmentType === 'employee') {
      insuranceClass = '1';
    } else {
      //contractor:

      //too low earners don't pay ni tax:
      if (yearlyProfit <= IncomeTax.NIClass2Amounts.threshold) {
        const tax = {
          name: INSURANCE_TAX_NAME,
          amount: (0).toFixed(2),
          isEmployer: false
        }

        ret.push(tax);
        return ret;
      } else {
        insuranceClass = yearlyProfit <= IncomeTax.NIClass4Rates[0].max ? '2' : '4';
      }
    }

    //apply found insurance class to calculate NI tax.
    if (insuranceClass === '1' && insuranceCategory) {

      const incomePerPeriod = yearlyRegular / yearConversionCo;

      ["employee", "employer"].forEach(who => {
        const brackets = IncomeTax.NIClass1Brackets[who][incomePeriod][insuranceCategory];
        const bracketIndex = brackets.findIndex(b => incomePerPeriod <= b.max);
        const bracket = brackets[bracketIndex];
        const prevBracket = brackets[bracketIndex - 1];
        const prevCeil = prevBracket ? prevBracket.max : 0;

        const bracketYear = {
          prevCeil: prevCeil * yearConversionCo,
          plus: bracket.plus * yearConversionCo,
        };

        const yearlyNominalNI = (taxable - bracketYear.prevCeil) * (bracket.rate / 100) + bracketYear.plus;
        const effectiveIncomeNIRate = (yearlyNominalNI / yearlyRegular) || 0; //sometimes comes NaN hence the trick.

        const key = who === 'employee' ? "national-insurance" : "employer-national-insurance";
        const tax = {name: INSURANCE_TAX_NAME, amount:  + (periodTotal * effectiveIncomeNIRate).toFixed(2),
          key, isEmployer: who === 'employer'};

          //TODO: Some values come negative.
          //https://wwwpaystubsnowcom.slack.com/archives/C04BSTFB6TG/p1676502510875379?thread_ts=1676497860.639369&cid=C04BSTFB6TG
          console.log("aha: ", tax, yearlyNominalNI, effectiveIncomeNIRate, taxable, bracketYear.prevCeil);
        ret.push(tax);
      });

    } else if (insuranceClass === '2') {

      const fixedAmount = freqToNIBracketMap[paymentPeriodicity].co * IncomeTax.NIClass2Amounts[incomePeriod];
      ret.push({
        name: INSURANCE_TAX_NAME,
        amount:  fixedAmount.toFixed(2),
        isEmployer: false
      });

    } else if (insuranceClass === '4') {
      const brackets = IncomeTax.NIClass4Rates;
      const bracketIndex = brackets.findIndex(b => yearlyProfit <= b.max);
      const bracket = brackets[bracketIndex];
      const prevBracket = brackets[bracketIndex - 1];
      const prevCeil = prevBracket ? prevBracket.max : 0;

      const yearlyNominalNI = (yearlyProfit - prevCeil) * (bracket.rate / 100) + bracket.plus;
      const effectiveIncomeNIRate = (yearlyNominalNI / yearlyProfit) || 0; //sometimes comes NaN hence the trick.

      ret.push({
        name: INSURANCE_TAX_NAME,
        amount:  + (periodTotal * effectiveIncomeNIRate).toFixed(2),
        isEmployer: false
      });
    }

    return ret;
  },

  createCustomDeduction (name, isEmployer = false) {
    return {
      name,
      amount: 0,
      key: "custom-" + name,
      priorYTD: 0,
      ytdTotal: 0,
      isCustom: true,
      isEmployer,
    }
  },

  //calculated how many days since the 6th April
  ytdDays (period) {
    let year = dayjs(period.end).year();
    const target = `${year}-04-06`; //1st day of the next tax year.
    const crossesApril6th = dayjs(target).isBetween(period.start, period.end, "day", '[]');
    if (crossesApril6th) return 0;

    let diff;
    if (dayjs(period.end).isAfter(target)) {
      diff = dayjs(period.end).diff(target, "days");
    } else {
      const prevYearStart = `${year - 1}-04-06`;
      diff = dayjs(period.end).diff(prevYearStart, "day");

    }
    return diff;
  },

  ytdCoefficient (period, freq) {
    const days = this.ytdDays(period);
    return days / (365 / freq);
  },

}

