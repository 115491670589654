import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js"; //DO NOT REMOVE .js, server breaks.
import { defineAsyncComponent } from "vue";
dayjs.extend(utc);

import converter from "number-to-words";
//NEVER DELETE THE COMMENT IN THE LINE BELOW. PROCESSED BY THE template2pdf.js FOR THE SERVER.
import templatePaths from "./template-paths.json" /* assert { type: "json" } */ ;

export default {

  templatePaths,

  //called from Template.vue and Preview.vie
  loadTemplates (country) {

    return this.templatePaths[country].map((path, index) => {
      const fName = path.split('/').pop() + ".vue";
      return {
        component: defineAsyncComponent({
            loader: () => import(`@/views/paystub/templates/${path}/${fName}`), ///* webpackChunkName: "atemplate" */
          }),
        id: path,
        index
      };
    });
  },

  globalColorOptions () {
    return [
      {id: "gray", chrome: "#DEDEDE", text: "#333333"},
      {id: "t4-blue", chrome: "#97b2f9", text: "white"},
      {id: "t4-green", chrome: "#71E080", text: "white"},
      {id: "google-green", chrome: "#549F55", text: "white"}, //google green
      {id: "notes-yellow", chrome: "#F7D04D", text: "#333"}, //notes yellow
      {id: "firefox-orange", chrome: "#EB504B", text: "white"}, //firefox orange
      {id: "firebrick", chrome: "firebrick", text: "white"},
    ];
  },

  //TODO: This is super messy! Cleanup and organize.
  //Separate optionals from required staff, so that we do not show optional dummy values in preview.
  //This changes depending on the country, so a "field list" for each country would be great.
  getPaystubValues (origPaystub, country, mode) {
    if (mode === 'pdf') return origPaystub; //this should never be called, but just in case.

    //create a copy and trim all employee and employer fields
    const paystub = JSON.parse(JSON.stringify(origPaystub));
    if (country === 'ca') {
      paystub.details.copies = "both";
    } else {
      paystub.details.copies = "employee";
    }

    Object.keys(paystub.company).forEach(key => paystub.company[key] = paystub.company[key].trim());
    //delete paystub.employee.exemptions; //this is numeric
    Object.keys(paystub.employee).forEach(key => {
      if (! ["number", "boolean"].includes(typeof paystub.employee[key]))
        paystub.employee[key] = paystub.employee[key].trim();
    });

    //set default values when fields are blank
    const companyDefaults = {
      name: "Company Name", address1: "Company address", address2: "",
      phone: "",
      city: "City",
      state: country === 'us' ? "State" : "Province",
      zip: country === 'us' ? "Zip" : "Postal",
    }

    //Same for employee, but it also has a .id field with a default.
    const employeeDefaults = JSON.parse(JSON.stringify(companyDefaults).replace(/Company/g, "Employee"));
    employeeDefaults.id = "123456789";


    //if (country === 'ca') {
      if (mode === 'template') {
        employeeDefaults.bank = "TD-Canada Trust";
        employeeDefaults.title = "Support Worker";
        employeeDefaults.department = "Field Staff";
      } else {
        employeeDefaults.bank = "";
        employeeDefaults.title = "";
        employeeDefaults.department = "";
      }
    //}

    //create company and employees, either using user-enetered values, or defaults that we set above.
    const company = {}, employee = {};

    Object.keys(companyDefaults).forEach(key => {
      company[key] = paystub.company[key] !== "" ? paystub.company[key] : companyDefaults[key];
    });
    Object.keys(employeeDefaults).forEach(key => {
      employee[key] = paystub.employee[key] !== "" ? paystub.employee[key] : employeeDefaults[key];
    });

    Object.assign(paystub.company, company);
    Object.assign(paystub.employee, employee);


    if (mode === 'preview') {
      if (country === 'ca') {
        ["address1", "address2", "phone", "city", "state", "zip"].forEach(key => {
          paystub.employee[key] = origPaystub.employee[key] || "";
          paystub.company[key] = origPaystub.company[key] || "";
        });
      }

      paystub.employee.id =  origPaystub.employee.id || "";

    }

    return paystub;
  },

  amountToTextParts (amount) {
    if (! amount) amount = 0;
    const [int, dec] = amount.toFixed(2).split('.');
    const intPart = converter.toWords(int);
    const decPart = dec + "/100";
    return [intPart, decPart];
  },

  capitalizeFirst (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  capitalizeWords (str) {
    return str.trim().split(' ')
      .filter(s => s !== '')
      .map(s => this.capitalizeFirst(s))
      .join(" ");
  },

  absoluteDate (dateStr) {
    //works with both strings and dates. Main paystub unit sends dates,
    //debugger sends strings.
    return dayjs(dateStr).format("YYYY-MM-DD");
  },

  dateFormat (date, country = 'us', from) {
    from;
    const iso = new Date(date).toISOString();
    switch (country) {
      case "ca": return dayjs(iso).format('YYYY/MM/DD'); //return new Date(date).toLocaleDateString('en-CA');
      case "uk": return dayjs(iso).format('DD/MM/YYYY');
      default: return dayjs(iso).format('MM/DD/YYYY');
    }
  },

  getDeductions (deductions) {
    return deductions.filter(d => ! d.isEmployer);
    //Harris wanted to display all taxes even when they are zero. Jun 2022.
    //return deductions.filter(d => ["federal", "state"].includes(d.key) || d.amount || d.priorYTD);
  },

  getEmployerContributions (deductions) {
    return deductions.filter(d => d.isEmployer);
  },

  //combines deductions and contribs into a single table with total, ytd columns
  getCartesianDeductions (deductions) {
    const items = [];
    const contribs = this.getEmployerContributions(deductions);

    //deductions
    this.getDeductions(deductions).forEach(d => {
      const contrib = contribs.find(c => c.boundTo === d.key);

      items.push({
        name: d.name,
        employee: d.amount,
        employeeYTD: d.ytdTotal,
        employer: contrib ? contrib.amount : '',
        employerYTD: contrib ? contrib.ytdTotal : '',
      });
    });

    //custom contributions:
    const customContribs = contribs.filter(d => ! d.boundTo);
    customContribs.forEach(c => {
      items.push({
        name: c.name,
        employee: '',
        employeeYTD: '',
        employer: c.amount,
        employerYTD: c.ytdTotal,
      });
    });

    const totals = {
      employee: items.reduce((acc, item) =>     acc + (item.employee    || 0), 0),
      employeeYTD: items.reduce((acc, item) =>  acc + (item.employeeYTD || 0), 0),
      employer: items.reduce((acc, item) =>     acc + (item.employer    || 0), 0),
      employerYTD: items.reduce((acc, item) =>  acc + (item.employerYTD || 0), 0),
    };

    return { items, totals };
  },

  getEarnings (earnings) {
    return earnings.filter(e => e.total || e.priorYTD);
  },

  getPayRate (earnings, country) {
    const regular = earnings.find(e => e.isRegular);
    if (! regular) return null;
    const amount = this.moneyFormat(regular.rate, country);
    const unit = regular.type === 'hourly' ? 'hr' : 'yr';
    return amount + "/" + unit;
  },

  getSSN (ssn, mode, country) {
    const trimmed = ssn.trim();

    //Note that no CA templates use ssn.
    switch(country) {
      case "uk": {
        if (trimmed) return trimmed;
        if (mode === 'template') return "QQ 12 34 56 A";
        break;
      } default: {
        const real = `XXX-XX-${trimmed}`;
        if (trimmed) return real;
        return mode === 'template' ? "123-45-6789" : "";
      }
    }
  },

  moneyFormat (amount, country) {
    if (! country) country = "us";

    const localeMap = {
      "us": "en-US",
      "ca": "en-CA",
      "uk": "en-GB"
    };

    const curMap = {
      'en-US': 'USD',
      'en-CA': 'CAD',
      'en-GB': 'GBP',
    };

    const locale = localeMap[country];
    const currency = curMap[locale];
    const val = new Intl.NumberFormat(locale, { style: 'currency', currency}).format(amount);
    return val.substring(1);
  },

  generateFieldVals (fieldDefs) {
    const ret = {}
    fieldDefs.forEach(f => {
      let val = this.generateRandomString(f.numeric, f.len);
      ret[f.name] = val;
    });
    if ("adviceNumber" in ret) ret.adviceNumber = "000" + ret.adviceNumber;
    return ret;
  },

  //adapted from Bhavesh's code: https://nimconsulting.slack.com/archives/D02CPB8PR98/p1642481823000600
  generateRandomString (numericOnly = false, length = 10) {
    const characters = numericOnly ? '0123456789' : '0123456789abcdefghjkmnopqrstuvwxyz';
    const len = characters.length;
    let randomString = '';
    for (let i = 0; i < length; i++) {
      randomString += characters[Math.floor(Math.random() * len)];
    }
    return randomString;
  },

  //scaling related functions

  registerScaleFn (templateContainer, fieldsContainer, cb) {

    const scaleFn = () => {
      const ow = templateContainer.offsetWidth;
      if (ow === 0) return; //invisible
      const TEMPLATE_WIDTH = parseInt(templateContainer.firstElementChild.style.width);
      if (isNaN(TEMPLATE_WIDTH)) throw new Error("Template has no css width.");
      let ratio = ow < TEMPLATE_WIDTH ? ow / TEMPLATE_WIDTH : 1;
      templateContainer.style.transform = `scale(${ratio})`;
      //rest is about emitting to parent for height. We can't do it, but the inner template can:
      let fieldHeight = fieldsContainer ? fieldsContainer.getBoundingClientRect().height : 0;
      let height = templateContainer.getBoundingClientRect().height + fieldHeight;
      cb(height);
    }

    window.addEventListener("resize", scaleFn);
    scaleFn();
    return scaleFn;
  },

  //TODO: executing removeEventListener breaks the functionality. Understand why. We are likely leaking memory.
  unregisterScaleFn (scaleFn) {
    window.removeEventListener("resize", scaleFn);
  }

}