<template>
  <footer class="mt-16 font-proxima">

    <div class="bg-psn-blue hidden md:block">
      <ul class="grid grid-flow-col  gap-0 text-white text-center text-sm md:text-base lg:text-2xl divide-x divide-gray-400">
        <li v-for="item in thickFooter" :key="item.route" class="p-8 lg:p-10">
          <router-link :to="{name: item.route}" class="whitespace-nowrap">
            {{item.text}} <span class="right-arrow-white"></span>
          </router-link>
        </li>
      </ul>
    </div>

    <!-- footer nav -->
    <div class="flex flex-wrap md:container mx-auto mt-16 px-4 justify-center">

      <div class="w-1/3 lg:w-1/4" v-for="(navItem, title) of nav" :key="title">
        <p class="font-bold psn-light-header">{{title}}</p>
        <ul class="mt-2 text-psn-light-text">
          <li class="mt-2" v-for="(item, index) of navItem" :key="index">
            <router-link :to="item.to">{{item.title}}</router-link>
          </li>
        </ul>
      </div>

      <div class="w-1/3 lg:w-1/4">
        <p class="font-bold psn-light-header">Get Support</p>
        <ul class="mt-2 text-psn-light-text">
          <li class="mt-2">
            <a href="mailto:support@thebestpaystubs.com">Send us a message</a>
          </li>
          <li class="mt-2">
            <a class="cursor-pointer" @click.prevent="$otherAPI.initChat()">Chat</a>
          </li>
        </ul>
      </div>

      <div class="mt-16 lg:mt-0 text-center w-full md:w-1/2 lg:w-1/4 mx-auto ">
        <p class="font-bold psn-light-header mb-2">Let's Get Social</p>
        <ul class="flex gap-4 justify-center md:justify-between mt-4">
          <li class="w-8" v-for="item of pageData.main.social" :key="item.title">
            <a :href="item.url" :title="item.title" class="text-center" target="_blank">
              <i :class="'pi text-psn-blue text-xl ' + item.icon"></i>
              <span class="invisible">{{item.title}}</span>
            </a>
          </li>
        </ul>

        <div class="psn-light-header text-sm text-psn-light-text pr-4">
          <address class="mt-2">
            3420 Bristol St., Suite 600, Costa Mesa, CA 92626
          </address>
          <div class="mt-2">
            NEED SERVICE?

            <a class="text-psn-blue" href="tel:+1 (817) 523-8255">+1 (817) 523-8255</a>
          </div>
        </div>

      </div>
    </div>

    <!-- copyright, privacy, terms and conditions -->
    <div class="md:container mx-auto mt-8 mb-8 px-4 md:px-0">
      <hr />

      <div class="block md:flex md:flex-row pt-8 text-psn-light-text">
        <p class="text-center md:text-left md:flex-grow">
          <small>Copyright © 2023 The Best Paystubs LLC. All Rights Reserved.</small>
        </p>

        <div class="mt-8 md:mt-0 flex gap-4 text-sm justify-end">
          <router-link :to="{name: legal.privacy}" class="uppercase border-r-2 border-gray-200 pr-4">Privacy</router-link>
          <router-link :to="{name: legal.terms}" class="uppercase">Terms</router-link>
        </div>

      </div>
    </div>


  </footer>
</template>

<script>
import pageData from "@/lib/page-data.js";

export default {
  computed: {
    pageData () {
      return pageData;
    },

    legal () {
      return {
        privacy:  this.country + "-privacy",
        terms:    this.country + "-terms",
      }
    },

    country () {
      return this.$route.meta.country || 'us';
    },

    thickFooter () {
      switch (this.country) {
        case "us": return [
            { route: "us-paystub", text: "Create your paystub" },
            { route: "us-w2", text: "Create your W-2" },
            { route: "us-1099-misc", text: "Create your 1099-MISC" },
          ];
        case "ca": return [
            { route: "ca-paystub", text: "Create your paystub" },
            { route: "ca-t4", text: "Create your T4" }
          ];
        default: return null;
      }
    },

    nav () {
      const generators = {
        us: [
          {title: "Paystubs", to: {name: 'us-paystub'}},
          {title: "W-2", to: {name: 'us-w2'}},
          {title: "1099-MISC", to: {name: 'us-1099-misc'}},
          //{title: "1099-NEC", to: {name: 'us-1099-nec'}},
        ],
        ca: [
          {title: "Paystubs", to: {name: 'ca-paystub'}},
          {title: "T4", to: {name: 'ca-t4'}},
        ],
        uk: [
          {title: "Payslip", to: {name: 'uk-paystub'}},
          {title: "P60", to: {name: 'uk-p60'}},
        ],
      };

      const common = [
        {title: "About Us", to: {name: this.country + "-about"}},
        {title: "Pricing", to: {name: this.country + "-pricing"}},
        {title: "Reviews", to: {name: this.country + "-reviews"}},
        {title: "FAQ", to: {name: this.country + '-faq'}},
        {title: "Blog", to: {name: this.country + '-blog'}},
      ];

      return {
        "Generators": generators[this.country],
        "About Us": common,
      };
    },

  },

}
</script>

<style scoped>
  .right-arrow-white {
    background-image: url('@/assets/images/right-arrow.svg');
    @apply bg-no-repeat bg-center pl-10;
  }
</style>