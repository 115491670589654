<template>
  <div class="overflow-hidden">

      <div class="text-center">
        <label class="font-bold mb-2 block" for="psn-template-template-select">Select template</label>
        <select id="psn-template-template-select" class="w-full md:w-auto mx-auto"
          :value="paystub.template.selectedTemplateIndex"
          @input="$emit('update:selectedTemplateIndex', $event.target.value)">
          <option v-for="tc of templateComponents" :key="tc.id" :value="tc.id">Template #{{tc.index + 1}}</option>
        </select>
      </div>

      <!-- color options -->
      <ColorOptions :colorOptions="colorOptions" v-if="colorOptions"
        @update:colors="$emit('update:colors', $event)" :colors="paystub.template.colors" />

      <!-- template preview -->
      <div class="mt-8 overflow-hidden" ref="templateContainer">
        <component v-if="paystub.template.selectedTemplateIndex"
          :is="templateComponents.find(tc => tc.id === paystub.template.selectedTemplateIndex).component"
          :country="country"
          :fields="paystub.stubs[0].fields"
          :paystub="paystub" :stub="paystub.stubs[0]" mode="template"
          @logo-jump-top="ui.logoJumpTop = $event"
          @temp-height="adjustTemplateHeight"
          :logos="{payroll: logos.payroll.cropped, company: logos.company.cropped}"
          :showEmployerContribs="paystub.details.copies === 'both'"
          @remove-logo="removeLogo"
          @color-options="$emit('update:colorOptions', $event)"
        />

      </div>

      <!-- file upload section -->
      <section class="mt-8 mx-auto w-full md:w-2/3 lg:w-1/3">

        <div v-for="upload of logoUploadDefs" :key="upload.field">

          <div class="text-psn-light-header mb-4 font-semibold text-sm md:text-base">{{upload.title}} Logo
            <psn-tooltip v-if="upload.field === 'payroll'">
              <template #content>
                <p>This should be the logo of the processing company, for example, ADP.</p>
                <p>This can be the same as the employer in some instances.</p>
                <p>It is recommended to use a PNG image type with a transparent background.</p>
              </template>
            </psn-tooltip>

            <psn-tooltip v-else>
              <template #content>
                <p>This should be the logo of the employer. </p>
                <p>It is recommended to use a PNG image type with a transparent background.</p>
              </template>
            </psn-tooltip>
          </div>

          <div class="border-2 border-dashed border-gray-300 rounded-sm h-16 md:h-32 text-center font-semibold
            text-psn-blue pt-5 md:pt-10 cursor-pointer" v-bind="logos[upload.field].dz.getRootProps()">
            <input v-bind="logos[upload.field].dz.getInputProps()" />
            <i class="pi pi-upload text-2xl"></i>
            <p class="mt-1 inline-block md:block ml-4 md:ml-0 ">Upload Image File</p>
          </div>

        </div>

      </section>

      <!-- cropper dialog -->
      <Dialog appendTo="body" position="top" :draggable="false" :dismissableMask="true" :modal="true"
        v-model:visible="ui.cropperModalVisible" :closable="false">

        <template #header>
          <p class="text-xl font-bold text-psn-light-header" >Crop image</p>
        </template>

        <div>
          <p class="mb-8">Drag the box and the handles to adjust the image. Use scroll-wheel or pinch to zoom.</p>
          <Cropper ref="cropper" class="cropper" :src="logos[ui.logoField].src" @change="cropperChanged"
            :canvas="{
              minHeight: 0,
              minWidth: 0,
              maxHeight: 185,
              maxWidth: 130,
            }"
             /> <!-- :stencil-props="{ aspectRatio: 9/12 }" -->
        </div>

        <template #footer>
          <psn-button text="Cancel" @click="logos[ui.logoField].cropped = ''; ui.cropperModalVisible = false" />
          <psn-button :dark="true" text="Apply" autofocus @click="crop" class="ml-4" />
        </template>
      </Dialog>

  </div>
</template>

<script>
import { useDropzone } from "vue3-dropzone";
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import Dialog from 'primevue/dialog';
import utils from "./templates/utils";
import ColorOptions from "./color-options.vue";

export default {
  components: { Cropper, Dialog, ColorOptions },

  props: ["paystub", "country", "colorOptions"],

  emits: ["update:logos", "update:selectedTemplateIndex",
    "update:colors", "update:colorOptions"],

  data () {
    return {
      logos: {
        payroll: {
          dz: null,
          src: '',
          cropped: '',
        },
        company: {
          dz: null,
          src: '',
          cropped: '',
        }
      },

      ui: {
        logoField: '', //payroll or company
        cropperModalVisible: false,
        logoJumpTop: {company: 0, payroll: 0},
      },
    };
  },

  computed: {
    logoUploadDefs () {
      return [{field: 'company', title: 'Company'}];
      /* let all = [{field: 'payroll', title: 'Payroll Processor'}, {field: 'company', title: 'Company'}];
      if (["ca", "uk"].includes(this.country)) all = all.slice(1); //CA and UK does not have payroll
      return all; */
    },

    templateComponents () {
      const ret = utils.loadTemplates(this.country);
      this.$emit('update:selectedTemplateIndex', ret[0].id);
      return ret;
    }
  },

  methods: {
    crop () {
      //const { canvas } = this.$refs.cropper.getResult();
      this.ui.cropperModalVisible = false;
    },

    onDrop (field, files, /* rejectedFiles */) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        this.logos[field].src = reader.result;
        this.ui.logoField = field;
        window.scrollTo({top: this.ui.logoJumpTop[field], left: 0, behavior: 'smooth'});
        setTimeout(() => this.ui.cropperModalVisible = true, 380); //some smaller values caused a bug on firefox.
      };
    },

    cropperChanged ({ coordinates, canvas }) {
      coordinates; //prevent unused-error
      this.logos[this.ui.logoField].cropped = canvas.toDataURL();
    },

    init () {
      ["company", "payroll"].forEach(field => {
        const options = {
          onDrop: (acceptedFiles, rejectedFiles) => {
            this.onDrop (field, acceptedFiles, rejectedFiles);
          }
        };

        this.logos[field].dz = useDropzone(options);
      });
    },

    emitLogoChanges () {
      const logos = {
        payroll: this.logos.payroll.cropped,
        company: this.logos.company.cropped,
      }

      this.$emit('update:logos', logos);
    },

    adjustTemplateHeight (e) {
      this.$refs.templateContainer.style.height = (e + 50) + "px";
    },

    removeLogo (which) {
      this.logos[which].cropped = null;
      this.emitLogoChanges();
    },
  },

  watch: {
    "logos.payroll.cropped" : {
      handler () {
        this.emitLogoChanges();
      },
    },

    "logos.company.cropped" : {
      handler () {
        this.emitLogoChanges();
      },
    },
  },

  async created () {
    this.init();
  },
}
</script>

<style scoped>
.cropper {
	height: 400px;
  width: 100%;
	background: #DDD;
}
</style>
<style>
.p-dialog {
  @apply w-full md:w-2/3 font-proxima
}
</style>