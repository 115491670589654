<template>
  <Dialog v-bind="$attrs" appendTo="body" :draggable="false" :dismissableMask="true" 
    :modal="true" v-model:visible="shown" class="psn-dialog" >

    <template #header>  
      <h6 class="text-2xl font-bold">
        <slot name="header">{{header}}</slot>
      </h6>          
    </template>

    <form @submit.prevent="$emit('submit')">
      <slot style="padding-bottom: 0px"></slot>
            
      <footer class="flex items-end justify-end p-2 mt-6 gap-2">
        <slot name="footer">
          <psn-button v-if="submitText != undefined" type="submit" :text="submitText" class="mt-4" dark />
        </slot>
        <psn-button v-if="closeable" text="Close" @click="close" />        
      </footer>
    </form>

    <template #footer></template>

  </Dialog>  
</template>

<script>
import Dialog from 'primevue/dialog'
export default {
  components: { Dialog },
  props: ["modelValue", "closeable", "header", "submitText"],
  emits: ["submit", "update:modelValue"],

  data () {
    return {
      shown: !! this.modelValue,
    }
  },

  methods: {
    close () {      
      this.$emit('update:modelValue', false);
    }
  },

  watch: {
    modelValue () {
      this.shown = this.modelValue;
    },

    //this is needed because of the X close button on the top
    shown () {
      if (! this.shown)
        this.$emit("update:modelValue", this.shown);
    },
  },
}
</script>
