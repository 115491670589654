<template>
  <!-- Hardcoded "hover", "openDelay" and "closeDelay" -->
  <Popper arrow v-bind="$attrs" hover :placement="$attrs.placement || 'top'" openDelay="200" closeDelay="100">

    <button type="button" class="
      block w-4 h-4 ml-2
      p-1 pt-0 pb-0
      text-center
      bg-psn-blue
      text-white
      text-xs
      leading-tight
      font-medium
      rounded-full
      shadow-md
      hover:shadow-lg
      focus:shadow-lg focus:outline-none focus:ring-0
      active:shadow-lg      
    ">
      ?
    </button>

    <template #content="props">
      <slot name="content" v-bind="props" />
    </template>  
  
  </Popper>
</template>

<script>
  import { defineComponent } from "vue";
  import Popper from "vue3-popper";

  export default defineComponent({
    name: "psn-tooltip",
    components: {
      Popper,
    },
  });
</script>

<style>
  :root {
    --popper-theme-background-color: white;
    --popper-theme-background-color-hover: white;

    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 16px; 
    /* below is Armine's **/
    --popper-theme-box-shadow: 0 0 10px 0 rgba(1, 3, 2, 0.1);
  }
</style>

<style scoped>
:deep(.popper) {
  @apply font-normal text-sm normal-case;
   border: solid rgb(235, 234, 234);
   max-width: 400px;

}

:deep(.popper) p:not(:last-of-type) {
  @apply mb-3;
}
</style>