<template>
  <div class="top-0 left-0 absolute w-full h-full bg-no-repeat bg-contain t1-watermark opacity-20"
    :style="'background-image: url(' + watermark + ');'" ></div>
</template>

<script>
import watermark from "@/assets/images/watermark.png";

export default {
  computed: {
    watermark () {
      return watermark;
    },
  },
}
</script>