import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import ajax from "./lib/ajax.js";
import meta from "./lib/meta.js";
import otherApi from "./lib/other-api.js";
import localization from './lib/localization.js';

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

//prime vue is currently used for accordeon on home page.
import PrimeVue from 'primevue/config';

import './assets/tailwind.css';
/* import 'primevue/resources/themes/tailwind-light/theme.css'; */
import './assets/psn.css';
import 'primeicons/primeicons.css';
import 'primevue/resources/primevue.min.css';

const app = createApp(App);
//https://stackoverflow.com/questions/65184107/how-to-use-vue-prototype-or-global-variable-in-vue-3
app.config.globalProperties.$get = ajax.get;
app.config.globalProperties.$post = ajax.post;
app.config.globalProperties.$ajax = ajax;
app.config.globalProperties.$meta = meta;
app.config.globalProperties.$otherAPI = otherApi;
app.config.globalProperties.$localize = localization.localize;

//sentry integration:
if (process.env.NODE_ENV !== 'development') {

  Sentry.init({
    app,
    dsn: "https://800543180217e1cc580ef6fe05b3d35a@o4506685744218112.ingest.sentry.io/4506798760394752",
    environment: process.env.NODE_ENV,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "thebestpaystubs.dev", "thebestpaystubs.com", /^\//],
      }),
    ],
    logErrors: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });  
}

import psnInputProper from "./components/input-proper.vue";
import psnButton from "./components/psn-button";
import psnSharpButton from "./components/psn-sharp-button";
import psnOptionButton from "./components/psn-option-button";
import psnFormSelect from "./components/psn-form-select";
import psnStateSelect from "./components/psn-state-select";
import psn2SideContainer from "./components/psn-two-side-container";
import inputMoney from "./components/input-money";
import psnFormAccordion from "./components/psn-form-accordion2.vue";
import psnFormAccordionTab from "./components/psn-form-accordion-tab";
import psnWatermark from "./components/psn-watermark";
import psnTooltip from "./components/psn-tooltip";

app.component('psn-input-proper', psnInputProper);
app.component('psn-button', psnButton);
app.component('psn-sharp-button', psnSharpButton);   
app.component('psn-option-button', psnOptionButton);
app.component('psn-form-select', psnFormSelect);
app.component('psn-state-select', psnStateSelect);
app.component('psn-two-side-container', psn2SideContainer);
app.component('InputMoney', inputMoney);
app.component("PsnFormAccordion", psnFormAccordion);
app.component("PsnFormAccordionTab", psnFormAccordionTab);
app.component("psn-watermark", psnWatermark);
app.component("psn-tooltip", psnTooltip);

app.use(router);
app.use(PrimeVue);

app.mount('#app');