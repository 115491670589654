<template>

  <input type="text" :value="val" @input="input" 
    :min="0" :max="9000000" @blur="blur" @focus="focus"
    ref="input"
    :class="{'psn-invalid': invalid}"
    :inputmode="inputMode" data-hj-allow />

</template>

<script>

const isTouchDevice = (('ontouchstart' in window) ||  
    (navigator.maxTouchPoints > 0) ||  
    (navigator.msMaxTouchPoints > 0));  

const MIN_AMOUNT = 0;
const MAX_AMOUNT = 9000000;

export default {

  props: ["modelValue"],
  emits: ['update:modelValue'],

  data () {
    return {
      val: this.moneyFormat(this.modelValue),
      invalid: false,
      hasFocus: false,
    }
  },

  computed: {
    inputMode () {
      return (navigator.language || "").toLowerCase() === "en-us" ? "decimal" : "text";
    }
  },

  watch: {
    modelValue (v) {
      this.val = this.hasFocus ? ("" + v) : this.moneyFormat(this.modelValue);
    }
  },

  methods: {
    blur () {
      this.val = this.moneyFormat(this.modelValue);
      this.hasFocus = false;
      this.invalid = false;
    },

    async focus (e) {
      isTouchDevice;
      this.val = +(this.modelValue);
      setTimeout(() => e.target.select(), 30)
      e.target.select();
      this.hasFocus = true;
    },

    input (e) {
      let val = e.target.value.replace(/,/, '');
      val = parseFloat(val);
      this.invalid = isNaN(val) || val < MIN_AMOUNT || val > MAX_AMOUNT;
      if (! this.invalid) 
        this.$emit('update:modelValue', val);
      else 
        this.val = e.target.value;
//      this.$emit('update:modelValue', val)
    },

    moneyFormat (amount, locale = 'en-US') {
      const curMap = {
        'en-US': 'USD',
      };
      const currency = curMap[locale];      
      const val = new Intl.NumberFormat('en-US', { style: 'currency', currency}).format(amount);
      return val.substring(1);      
    },
  }
}
</script>

<style scoped >
input[type=text] {
  @apply text-right w-full
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
</style>
