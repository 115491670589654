<template>
  <section class="bg-white psn-card border border-gray-200 container mx-auto">
    <ul class="flex flex-row flex-wrap ">
      
      <li v-for="(item, index) of headerDocs[country]" :key="index"
        class="flex-1 psn-home-doc-item" :class="index === 1 ? 'border border-y-0' : ''">
        
        <p class="text-3xl lg:text-4xl text-center text-psn-green-text p-4 border-b px-6 border-gray-200 font-bold">
          <router-link :to="{name: item.to}">{{item.mobileHeader}}</router-link>
        </p>

        <div class="p-6 pt-10" style="background: linear-gradient(315deg, #FFFFFF 0%, #F7F8FA 100%);">
          <router-link :to="{name: item.to}" class="product-bg-link" :class="'product-' + (index + 1)" />              
        </div>

        <div class="text-center text-lg lg:text-2xl font-semibold text-white py-6 px-2" :class="'bg_G' + index">
          <router-link :to="{name: item.to}" class="whitespace-nowrap">
            {{item.header}} <span class="right-arrow-white"></span>
          </router-link>
        </div>

      </li>

    </ul>
  </section>  
</template>

<script>
import pageData from "@/lib/page-data";
export default {
  computed: {
    pageData: () => pageData,

    country () {
      return this.$route.meta.country;
    },

    headerDocs () {
      return {
        us: [
          { header: "Create your paystub", to: "us-paystub", mobileHeader: "Paystub" },
          { header: "Create your W-2", to: "us-w2", mobileHeader: "W-2" },
          { header: "Create your 1099-MISC", to: "us-1099-misc", mobileHeader: "1099-MISC" },
        ],
        ca: [
          { header: "Create your paystub", to: "ca-paystub", mobileHeader: "Paystub" },
          { header: "Create your T4", to: "ca-t4", mobileHeader: "T4" },
        ],
        uk: [
          { header: "Create your payslip", to: "uk-paystub", mobileHeader: "Payslip" },
          { header: "Create your P60", to: "uk-p60", mobileHeader: "P60" },
        ]
      }
    },
  }
}
</script>

<style>
  .product-bg-link {
    @apply block w-full h-32 md:h-52 lg:h-64 bg-no-repeat bg-contain bg-center;
  }

  .product-1 {
    background-image: url('@/assets/images/home-header-doc-1.svg');
  }

  .product-2 {
    background-image: url('@/assets/images/home-header-doc-2.svg');
  }

  .product-3 {
    background-image: url('@/assets/images/home-header-doc-3.svg');
  }

  .bg_G0{
    background-color: #01B49A;
  }
  .bg_G1{
    background-color: #049183;
  }
  .bg_G2{
    background-color: #076E6C;
  }    

  .right-arrow-white {
    background-image: url('@/assets/images/right-arrow.svg');
    @apply bg-no-repeat bg-center pl-10;
  }  
</style>