"use strict";

const cache = {
  products: {
    "us-paystub": null,
    "ca-paystub": null,
    "w-2": null,
    "1099-misc": null,
  },
    
  lastProductType: null, //--> this indexes into products key
  email: null,  
  country: null,
  orderId: null,
};

export default {

  setOrder (productType, order, email, country, orderId) {
    cache.products[productType] = order;
    cache.email = email;
    cache.lastProductType = productType;
    cache.country = country;
    cache.orderId = orderId;
    localStorage.setItem("cache", JSON.stringify(cache));
  },

  getOrder () {
    const sCache = this.loadCache();
    const ret = {
      productType: sCache.lastProductType,
      email: sCache.email,
      product: sCache.products[sCache.lastProductType],
      country: sCache.country,
      orderId: sCache.orderId,
    };
    return ret.product ? ret : null;    
  },

  resetOrder () {
    const sCache = this.loadCache();
    if (! sCache) return;
    if (sCache.lastProductType) {      
      sCache.products[sCache.lastProductType] = null;
      sCache.lastProductType = null;
      localStorage.setItem("cache", JSON.stringify(sCache));
    }
  },

  resetTypedOrder (type) {
    const sCache = this.loadCache();
    sCache.products[type] = null;
    if (sCache.lastProductType === type)
      sCache.lastProductType = null;
    localStorage.setItem("cache", JSON.stringify(sCache));
  },

  loadCache () {
    let sCache = localStorage.getItem("cache");
    if (! sCache) return null;
    return JSON.parse(sCache);
  },

  getTypedOrder (type) {
    const sCache = this.loadCache();
    return sCache ? sCache.products[type] : null;
  }

}