"use strict";

export default {

  brackets: {

    AL: {
      single: [
        { rate: 2, max: 500, 			plus: 0 	},
        { rate: 4, max: 3000, 			plus: 10 	},
        { rate: 5, max: 99999999999999, plus: 110 	}
      ],
      married: [
        { rate: 2, max: 1000, 			plus: 0		},
        { rate: 4, max: 6000, 			plus: 20	},
        { rate: 5, max: 99999999999999, plus: 220 	}
      ]
    },
    AK: { single: [], married: [] },
    AZ: {
      single: [
        { rate: 2.55,	max: 28653, 			plus: 0 	},
        { rate: 2.98,  	max: 99999999999999, 	plus: 731	},
      ],
      married: [
        { rate: 2.55,	max: 57305,				plus: 0		},
        { rate: 2.98,	max: 99999999999999,	plus: 1461	},
      ]
    },
    AR: {
      single: [
        { rate: 0,    	max: 5100, 				plus: 0 	},
        { rate: 2,    	max: 10300, 			plus: 0 	},
        { rate: 3,    	max: 14700, 			plus: 104 	},
        { rate: 3.4,	max: 24300,				plus: 236 	},
        { rate: 4.7,  	max: 99999999999999, 	plus: 562.4	}
      ],
      married: [
        { rate: 0,    	max: 10200,				plus: 0 		},
        { rate: 2,    	max: 20600, 			plus: 0 		},
        { rate: 3,    	max: 29400, 			plus: 208 		},
        { rate: 3.4,	max: 46600,				plus: 472 		},
        { rate: 4.7,  	max: 99999999999999, 	plus: 1056.8	}
      ]
    },
    CA: {
      single: [
        { rate: 1.1,    max: 10099, 			plus: 0 		},
        { rate: 2.2,    max: 23942, 			plus: 111.09 	},
        { rate: 4.4,    max: 37788, 			plus: 415.64 	},
        { rate: 6.6,    max: 52455, 			plus: 1024.86 	},
        { rate: 8.8,    max: 66295, 			plus: 1992.88 	},
        { rate: 10.23,  max: 338639, 			plus: 3210.8 	},
        { rate: 11.33, 	max: 406364, 			plus: 31071.59 	},
        { rate: 12.43, 	max: 677275, 			plus: 38744.83 	},
        { rate: 13.53, 	max: 1000000,	 		plus: 72419.07 	},
        { rate: 14.63, 	max: 99999999999999,	plus: 116083.76 }
      ],
      married: [
        { rate: 1.1,    max: 20198, 			plus: 0 		},
        { rate: 2.2,    max: 47884, 			plus: 222.18 	},
        { rate: 4.4,    max: 75576, 			plus: 831.27 	},
        { rate: 6.6,    max: 104910, 			plus: 2049.72 	},
        { rate: 8.8,    max: 132590, 			plus: 3985.76 	},
        { rate: 10.23,  max: 677278, 			plus: 6421.6 	},
        { rate: 11.33, 	max: 812728, 			plus: 62143.18 	},
        { rate: 12.43, 	max: 1000000, 			plus: 77489.67 	},
        { rate: 13.53, 	max: 1354550,	 		plus: 100767.58	},
        { rate: 14.63, 	max: 99999999999999,	plus: 148738.19 }
      ]
    },
    CO: {
      single: [  { rate: 4.4, max: 99999999999999,	plus: 0	} ],
      married: [ { rate: 4.4, max: 99999999999999, plus: 0 } ]
    },
    CT: {
      single: [
        { rate: 3,    max: 10000, 			plus: 0 	},
        { rate: 5,    max: 50000, 			plus: 300 	},
        { rate: 5.5,  max: 100000, 			plus: 2300 	},
        { rate: 6,    max: 200000, 			plus: 5050 	},
        { rate: 6.5,  max: 250000, 			plus: 11050 },
        { rate: 6.9,  max: 500000, 			plus: 14300 },
        { rate: 6.99, max: 99999999999999, 	plus: 31550 }
      ],
      married: [
        { rate: 3,    max: 20000, 			plus: 0 	},
        { rate: 5,    max: 100000, 			plus: 600 	},
        { rate: 5.5,  max: 200000, 			plus: 4600 	},
        { rate: 6,    max: 400000, 			plus: 10100 },
        { rate: 6.5,  max: 500000, 			plus: 22100 },
        { rate: 6.9,  max: 1000000, 		plus: 28600 },
        { rate: 6.99, max: 99999999999999, 	plus: 63100 }
      ]
    },
    DE: {
      single: [
        { rate: 0,    max: 2000, 			plus: 0 		},
        { rate: 2.2,  max: 5000, 			plus: 0 		},
        { rate: 3.9,  max: 10000, 			plus: 66 		},
        { rate: 4.8,  max: 20000, 			plus: 261 		},
        { rate: 5.2,  max: 25000, 			plus: 741 		},
        { rate: 5.55, max: 60000, 			plus: 1001 		},
        { rate: 6.6,  max: 99999999999999, 	plus: 2943.5	}
      ],
      married: [
        { rate: 0,    max: 2000, 			plus: 0 		},
        { rate: 2.2,  max: 5000, 			plus: 0 		},
        { rate: 3.9,  max: 10000, 			plus: 66 		},
        { rate: 4.8,  max: 20000, 			plus: 261 		},
        { rate: 5.2,  max: 25000, 			plus: 741 		},
        { rate: 5.55, max: 60000, 			plus: 1001 		},
        { rate: 6.6,  max: 99999999999999, 	plus: 2943.5 	}
      ]
    },
    DC: {
      single: [
        { rate: 4,      max: 10000, 			plus: 0 	},
        { rate: 6,      max: 40000, 			plus: 400 	},
        { rate: 6.5,    max: 60000, 			plus: 2200 	},
        { rate: 8.5,    max: 250000, 			plus: 3500 	},
        { rate: 9.25,   max: 500000, 			plus: 19650 },
        { rate: 9.75,   max: 1000000, 			plus: 42775 },
        { rate: 10.75,  max: 99999999999999, 	plus: 91525 }
      ],
      married: [
        { rate: 4,      max: 10000, 			plus: 0 	},
        { rate: 6,      max: 40000, 			plus: 400 	},
        { rate: 6.5,    max: 60000, 			plus: 2200 	},
        { rate: 8.5,    max: 250000, 			plus: 3500 	},
        { rate: 9.25,   max: 500000, 			plus: 19650 },
        { rate: 9.75,   max: 1000000, 			plus: 42775 },
        { rate: 10.75,  max: 99999999999999, 	plus: 91525 }
      ]
    },
    FL: { single: [], married: [] },
    GA: {
      single: [
        { rate: 1,    max: 750, 			plus: 0 	},
        { rate: 2,    max: 2250, 			plus: 7.5 	},
        { rate: 3,    max: 3750, 			plus: 37.5 	},
        { rate: 4,    max: 5250, 			plus: 82.5 	},
        { rate: 5,    max: 7000, 			plus: 142.5 },
        { rate: 5.75, max: 99999999999999, 	plus: 230 	}
      ],
      married: [
        { rate: 1,    max: 1000, 			plus: 0 	},
        { rate: 2,    max: 3000, 			plus: 10 	},
        { rate: 3,    max: 5000, 			plus: 50 	},
        { rate: 4,    max: 7000, 			plus: 110 	},
        { rate: 5,    max: 10000, 			plus: 190 	},
        { rate: 5.75, max: 99999999999999, 	plus: 340 	}
      ]
    },
    HI: {
      single: [
        { rate: 1.4,  max: 2400, 			plus: 0 		},
        { rate: 3.2,  max: 4800, 			plus: 33.6 		},
        { rate: 5.5,  max: 9600, 			plus: 110.4 	},
        { rate: 6.4,  max: 14400, 			plus: 374.4 	},
        { rate: 6.8,  max: 19200, 			plus: 681.6 	},
        { rate: 7.2,  max: 24000, 			plus: 1008 		},
        { rate: 7.6,  max: 36000, 			plus: 1353.6 	},
        { rate: 7.9,  max: 99999999999999,	plus: 2265.6 	},
      ],
      married: [
        { rate: 1.4,  max: 4800, 			plus: 0 		},
        { rate: 3.2,  max: 9600, 			plus: 67.2 		},
        { rate: 5.5,  max: 19200, 			plus: 220.8 	},
        { rate: 6.4,  max: 28800, 			plus: 748.8 	},
        { rate: 6.8,  max: 38400, 			plus: 1363.2 	},
        { rate: 7.2,  max: 48000, 			plus: 2016 		},
        { rate: 7.6,  max: 72000, 			plus: 2707.2 	},
        { rate: 7.9,  max: 99999999999999,	plus: 4531.2 	},
      ]
    },
    ID: {
      single: [
        { rate: 1,    max: 1662, 			plus: 0 		},
        { rate: 3,    max: 4987, 			plus: 16.62		},
        { rate: 4.5,  max: 8311, 			plus: 116.37 	},
        { rate: 6,    max: 99999999999999, 	plus: 265.95 	},
      ],
      married: [
        { rate: 1,    max: 3324, 			plus: 0 		},
        { rate: 3,    max: 9974, 			plus: 33.24 	},
        { rate: 4.5,  max: 16622, 			plus: 232.74 	},
        { rate: 6,    max: 99999999999999, 	plus: 531.9 	}
      ]
    },
    IL: {
      single: 	[ { rate: 4.95, max: 99999999999999, plus: 0 } ],
      married: 	[ { rate: 4.95, max: 99999999999999, plus: 0 } ]
    },
    IN: {
      single: 	[ { rate: 3.15, max: 99999999999999, plus: 0 } ],
      married: 	[ { rate: 3.15, max: 99999999999999, plus: 0 } ]
    },
    IA: {
      single: [
        { rate: 4.4, 	max: 6000, 				plus: 0 		},
        { rate: 4.82, 	max: 30000,				plus: 264 		},
        { rate: 5.7,	max: 75000,	 			plus: 1420.8	},
        { rate: 6,		max: 99999999999999, 	plus: 3985.8	}
      ],
      married: [
        { rate: 4.4, 	max: 12000, 			plus: 0			},
        { rate: 4.82, 	max: 60000,				plus: 528 	 	},
        { rate: 5.7,	max: 150000,	 		plus: 2841.6 	},
        { rate: 6,		max: 99999999999999,	plus: 7971.6 	}
      ]
    },
    KS: {
      single: [
        { rate: 3.1,  max: 15000, 			plus: 0 		},
        { rate: 5.25, max: 30000, 			plus: 465 		},
        { rate: 5.7,  max: 99999999999999, 	plus: 1252.5 	}
      ],
      married: [
        { rate: 3.1,  max: 30000, 			plus: 0 	},
        { rate: 5.25, max: 60000, 			plus: 930 	},
        { rate: 5.7,  max: 99999999999999, 	plus: 2505 	}
      ]
    },
    KY: {
      single:  [ { rate: 4.5,	max: 99999999999999, 	plus: 0 } ],
      married: [ { rate: 4.5,	max: 99999999999999,	plus: 0 } ]
    },
    LA: {
      single: [
        { rate: 1.85, max: 12500, 			plus: 0 		},
        { rate: 3.5,  max: 50000, 			plus: 231.25 	},
        { rate: 4.25, max: 99999999999999, 	plus: 1543.75 	}
      ],
      married: [
        { rate: 2, max: 25000, 			plus: 0		},
        { rate: 4, max: 100000, 		plus: 500	},
        { rate: 6, max: 99999999999999, plus: 3500	}
      ]
    },
    ME: {
      single: [
        { rate: 5.8,  max: 24500, 			plus: 0 		},
        { rate: 6.75, max: 58050, 			plus: 1421 		},
        { rate: 7.15, max: 99999999999999, 	plus: 3685.63 	}
      ],
      married: [
        { rate: 5.8,  max: 49050, 			plus: 0 		},
        { rate: 6.75, max: 116100, 			plus: 2844.9	},
        { rate: 7.15, max: 99999999999999, 	plus: 7370.78	}
      ]
    },
    MD: {
      single: [
        { rate: 2,    max: 1000, 			plus: 0 		},
        { rate: 3,    max: 2000, 			plus: 20 		},
        { rate: 4,    max: 3000, 			plus: 50 		},
        { rate: 4.75, max: 100000, 			plus: 90 		},
        { rate: 5,    max: 125000, 			plus: 4697.5 	},
        { rate: 5.25, max: 150000, 			plus: 5947.5 	},
        { rate: 5.5,  max: 250000, 			plus: 7260 		},
        { rate: 5.75, max: 99999999999999, 	plus: 12760 	}
      ],
      married: [
        { rate: 2,    max: 1000, 			plus: 0 		},
        { rate: 3,    max: 2000, 			plus: 20 		},
        { rate: 4,    max: 3000, 			plus: 50 		},
        { rate: 4.75, max: 150000, 			plus: 90 		},
        { rate: 5,    max: 175000, 			plus: 7072.5 	},
        { rate: 5.25, max: 225000, 			plus: 8322.5 	},
        { rate: 5.5,  max: 300000, 			plus: 10947.5 	},
        { rate: 5.75, max: 99999999999999, 	plus: 15072.5 	}
      ]
    },
    MA: {
      single:  [ { rate: 5, max: 99999999999999, plus: 0 } ],
      married: [ { rate: 5, max: 99999999999999, plus: 0 } ]
    },
    MI: {
      single:  [ { rate: 4.05, max: 99999999999999, plus: 0 } ],
      married: [ { rate: 4.05, max: 99999999999999, plus: 0 } ]
    },
    MN: {
      single: [
        { rate: 5.35, max: 30070, 			plus: 0 		},
        { rate: 6.8,  max: 98760, 			plus: 1608.75 	},
        { rate: 7.85, max: 183340, 			plus: 6279.67 	},
        { rate: 9.85, max: 99999999999999, 	plus: 12979.2 	}
      ],
      married: [
        { rate: 5.35, max: 43950, 			plus: 0 		},
        { rate: 6.8,  max: 174610, 			plus: 2351.33 	},
        { rate: 7.85, max: 304970, 			plus: 11236.21 	},
        { rate: 9.85, max: 99999999999999, 	plus: 21469.47 	}
      ]
    },
    MS: {
      single: [
        { rate: 0, max: 4000, 			plus: 0 	},
        { rate: 3, max: 5000, 			plus: 0 	},
        { rate: 4, max: 10000, 			plus: 30 	},
        { rate: 5, max: 99999999999999, plus: 230 	}
      ],
      married: [
        { rate: 0, max: 4000, 			plus: 0 	},
        { rate: 3, max: 5000, 			plus: 0 	},
        { rate: 4, max: 10000, 			plus: 30 	},
        { rate: 5, max: 99999999999999, plus: 230 	}
      ]
    },
    MO: {
      single: [
        { rate: 0,		max: 1207,   			plus: 0 		},
        { rate: 2,		max: 2414,  			plus: 0 		},
        { rate: 2.5,	max: 3621,  			plus: 24.14		},
        { rate: 3,		max: 4828,  			plus: 54.32 	},
        { rate: 3.5,	max: 6035,  			plus: 90.53 	},
        { rate: 4,		max: 7242,  			plus: 132.77	},
        { rate: 4.5,	max: 8449,  			plus: 181.05 	},
        { rate: 4.95,	max: 99999999999999, 	plus: 235.37 	}
      ],
      married: [
        { rate: 0,		max: 1207,   			plus: 0 		},
        { rate: 2,		max: 2414,  			plus: 0 		},
        { rate: 2.5,	max: 3621,  			plus: 24.14		},
        { rate: 3,		max: 4828,  			plus: 54.32 	},
        { rate: 3.5,	max: 6035,  			plus: 90.53 	},
        { rate: 4,		max: 7242,  			plus: 132.77	},
        { rate: 4.5,	max: 8449,  			plus: 181.05 	},
        { rate: 4.95,	max: 99999999999999, 	plus: 235.37 	}
      ]
    },
    MT: {
      single: [
        { rate: 1,    max: 3600, 			plus: 0 	},
        { rate: 2,    max: 6300, 			plus: 36 	},
        { rate: 3,    max: 9700, 			plus: 90 	},
        { rate: 4,    max: 13000, 			plus: 192 	},
        { rate: 5,    max: 16800, 			plus: 324 	},
        { rate: 6,    max: 21600, 			plus: 514 	},
        { rate: 6.75, max: 99999999999999, 	plus: 802	}
      ],
      married: [
        { rate: 1,    max: 3600, 			plus: 0 	},
        { rate: 2,    max: 6300, 			plus: 36 	},
        { rate: 3,    max: 9700, 			plus: 90 	},
        { rate: 4,    max: 13000, 			plus: 192 	},
        { rate: 5,    max: 16800, 			plus: 324 	},
        { rate: 6,    max: 21600, 			plus: 514 	},
        { rate: 6.75, max: 99999999999999, 	plus: 802	}
      ]
    },
    NE: {
      single: [
        { rate: 2.46, max: 3440, 			plus: 0 		},
        { rate: 3.51, max: 20590, 			plus: 84.62 	},
        { rate: 5.01, max: 33180, 			plus: 686.59 	},
        { rate: 6.84, max: 99999999999999, 	plus: 1317.35 	}
      ],
      married: [
        { rate: 2.46, max: 6860, 			plus: 0 		},
        { rate: 3.51, max: 41190, 			plus: 168.76 	},
        { rate: 5.01, max: 66360, 			plus: 1373.74 	},
        { rate: 6.84, max: 99999999999999, 	plus: 2634.76 	}
      ]
    },
    NV: { single: [], married: [] },
    NH: { single: [], married: [] },
    NJ: {
      single: [
        { rate: 1.4,    max: 20000, 			plus: 0 		},
        { rate: 1.75,   max: 35000, 			plus: 280 		},
        { rate: 3.5,    max: 40000, 			plus: 542.5 	},
        { rate: 5.525,  max: 75000, 			plus: 717.5 	},
        { rate: 6.37,   max: 500000, 			plus: 2651.25 	},
        { rate: 8.97,   max: 1000000, 			plus: 29723.75 	},
        { rate: 10.75,  max: 99999999999999, 	plus: 74573.75	}
      ],
      married: [
        { rate: 1.4,    max: 20000,   			plus: 0       },
        { rate: 1.75,   max: 50000,   			plus: 280     },
        { rate: 2.45,   max: 70000,   			plus: 805     },
        { rate: 3.5,    max: 80000,   			plus: 1295    },
        { rate: 5.525,  max: 150000,  			plus: 1645    },
        { rate: 6.37,   max: 500000,  			plus: 5512.5  },
        { rate: 8.97,   max: 1000000, 			plus: 27807.5 },
        { rate: 10.75,  max: 99999999999999, 	plus: 72657.5 }
      ]
    },
    NM: {
      single:  [
        { rate: 1.7,    max:  5500,            plus: 0      	},
        { rate: 3.2,    max:  11000,           plus: 93.5   	},
        { rate: 4.7,    max:  16000,           plus: 269.5  	},
        { rate: 4.9,    max:  210000,          plus: 504.5  	},
        { rate: 5.9,    max:  99999999999999,  plus: 10010.5	}
      ],
      married: [
        { rate: 1.7,    max:  8000,            plus: 0    	},
        { rate: 3.2,    max:  16000,           plus: 136  	},
        { rate: 4.7,    max:  24000,           plus: 392  	},
        { rate: 4.9,    max:  315000,          plus: 768  	},
        { rate: 5.9,    max:  99999999999999,  plus: 15027	}
      ]
    },
    NY: {
      single:  [
        { rate: 4,      max:  8500,            plus: 0         	},
        { rate: 4.5,    max:  11700,           plus: 340       	},
        { rate: 5.25,   max:  13900,           plus: 484       	},
        { rate: 5.85,   max:  80650,           plus: 599.5     	},
        { rate: 6.25,   max:  215400,          plus: 4504.38   	},
        { rate: 6.85,   max:  1077550,         plus: 12926.25  	},
        { rate: 9.65,   max:  5000000,         plus: 71983.53  	},
        { rate: 10.3,   max:  25000000,        plus: 450499.95 	},
        { rate: 10.9,   max:  99999999999999,  plus: 2510499.95	}
      ],
      married: [
        { rate: 4,      max:  17150,           plus: 0         	},
        { rate: 4.5,    max:  23600,           plus: 686       	},
        { rate: 5.25,   max:  27900,           plus: 976.25    	},
        { rate: 5.85,   max:  161550,          plus: 1202      	},
        { rate: 6.25,   max:  323200,          plus: 9020.53   	},
        { rate: 6.85,   max:  2155350,         plus: 19123.65  	},
        { rate: 9.65,   max:  5000000,         plus: 144625.93 	},
        { rate: 10.3,   max:  25000000,        plus: 419134.65 	},
        { rate: 10.9,   max:  99999999999999,  plus: 2479134.65	}
      ]
    },
      NC: {
      single:  [ { rate: 4.99,	max:  99999999999999, plus: 0 } ],
      married: [ { rate: 4.99,	max:  99999999999999, plus: 0 } ]
    },
    ND: {
      single:  [
        { rate: 0,   	max:  51650,         	plus: 0 		},
        { rate: 1.95,   max:  232900,        	plus: 0 		},
        { rate: 2.5,    max:  99999999999999,	plus: 3534.38	}
      ],
      married: [
        { rate: 0,   	max:  70350,			plus: 0			},
        { rate: 1.95,   max:  260950,        	plus: 0			},
        { rate: 2.5,    max:  99999999999999,	plus: 3716.7	}
      ]
    },
    OH: {
      single:  [
        { rate: 0,      max:  25000,           plus: 0       	},
        { rate: 2.765,  max:  44250,           plus: 0       	},
        { rate: 3.226,  max:  88450,           plus: 532.26  	},
        { rate: 3.688,  max:  110650,          plus: 1958.15 	},
        { rate: 3.99,   max:  99999999999999,  plus: 2776.89 	}
      ],
      married: [
        { rate: 0,      max:  25000,           plus: 0       	},
        { rate: 2.765,  max:  44250,           plus: 0       	},
        { rate: 3.226,  max:  88450,           plus: 532.26  	},
        { rate: 3.688,  max:  110650,          plus: 1958.15 	},
        { rate: 3.99,   max:  99999999999999,  plus: 2776.89 	}
      ]
    },
    OK: {
      single:  [
        { rate: 0.25,   max:  1000,            plus: 0    	},
        { rate: 0.75,   max:  2500,            plus: 2.5  	},
        { rate: 1.75,   max:  3750,            plus: 13.75	},
        { rate: 2.75,   max:  4900,            plus: 35.63	},
        { rate: 3.75,   max:  7200,            plus: 67.25	},
        { rate: 4.75,   max:  99999999999999,  plus: 153.5	}
      ],
      married: [
        { rate: 0.25,   max:  200,             plus: 0    	},
        { rate: 0.75,   max:  5000,            plus: 5    	},
        { rate: 1.75,   max:  7500,            plus: 27.5 	},
        { rate: 2.75,   max:  9800,            plus: 71.25	},
        { rate: 3.75,   max:  12200,           plus: 134.5	},
        { rate: 4.75,   max:  99999999999999,  plus: 224.5	}
      ]
    },
    OR: {
      single:  [
        { rate: 4.75,   max:  3650,            plus: 0      	},
        { rate: 6.75,   max:  9200,            plus: 173.38 	},
        { rate: 8.75,   max:  125000,          plus: 548    	},
        { rate: 9.9,    max:  99999999999999,  plus: 10680.5	}
      ],
      married: [
        { rate: 4.75,   max:  7300,            plus: 0      	},
        { rate: 6.75,   max:  18400,           plus: 346.75 	},
        { rate: 8.75,   max:  250000,          plus: 1096   	},
        { rate: 9.9,    max:  99999999999999,  plus: 21361  	}
      ]
    },
    PA: {
      single:  [ {	rate: 3.07,	max:  99999999999999, plus: 0 } ],
      married: [ {	rate: 3.07,	max:  99999999999999, plus: 0 } ]
        },
    PR: {	single: [],   married:	[]	},
    RI: {
      single:  [
        { rate: 3.75,   max:  68200,           plus: 0      	},
        { rate: 4.75,   max:  155050,          plus: 2557.5 	},
        { rate: 5.99,   max:  99999999999999,  plus: 6682.88	}
      ],
      married: [
        { rate: 3.75,   max:  68200,           plus: 0      	},
        { rate: 4.75,   max:  155050,          plus: 2557.5 	},
        { rate: 5.99,   max:  99999999999999,  plus: 6682.88	}
      ]
    },
    SC: {
      single:  [
        { rate: 0,      max:  3200,            plus: 0    	},
        { rate: 3,      max:  6410,            plus: 0    	},
        { rate: 4,      max:  9620,            plus: 96.3 	},
        { rate: 5,      max:  12820,           plus: 224.7	},
        { rate: 6,      max:  16040,           plus: 384.7	},
        { rate: 7,      max:  99999999999999,  plus: 577.9	}
      ],
      married: [
        { rate: 0,      max:  3200,            plus: 0    	},
        { rate: 3,      max:  6410,            plus: 0    	},
        { rate: 4,      max:  9620,            plus: 96.3 	},
        { rate: 5,      max:  12820,           plus: 224.7	},
        { rate: 6,      max:  16040,           plus: 384.7	},
        { rate: 7,      max:  99999999999999,  plus: 577.9	}
      ]
    },
    SD:	{ single: [],   married: []	},
    TN:	{ single: [],   married: []	},
    TX:	{ single: [],   married: []	},
    UT:	{
        single:  [ { rate: 4.65, max:  99999999999999, plus: 0 } ],
        married: [ { rate: 4.65, max:  99999999999999, plus: 0 } ]
        },
    VT: {
      single:  [
        { rate: 3.35,   max:  40950,           plus: 0       	},
        { rate: 6.6,    max:  99200,           plus: 1371.83 	},
        { rate: 7.6,    max:  206950,          plus: 5216.33 	},
        { rate: 8.75,   max:  99999999999999,  plus: 13405.33	}
      ],
      married: [
        { rate: 3.35,   max:  68400,           plus: 0      	},
        { rate: 6.6,    max:  165350,          plus: 2291.4 	},
        { rate: 7.6,    max:  251950,          plus: 8690.1 	},
        { rate: 8.75,   max:  99999999999999,  plus: 15271.7	}
      ]
    },
    VA: {
      single:  [
        { rate: 2,      max:  3000,            plus: 0		},
        { rate: 3,      max:  5000,            plus: 60		},
        { rate: 5,      max:  17000,           plus: 120	},
        { rate: 5.75,   max:  99999999999999,  plus: 720	}
      ],
      married: [
        { rate: 2,      max:  3000,            plus: 0  	},
        { rate: 3,      max:  5000,            plus: 60 	},
        { rate: 5,      max:  17000,           plus: 120	},
        { rate: 5.75,   max:  99999999999999,  plus: 720	}
      ]
    },
    WA: { single: [], married: [] },
    WV: {
      single:  [
        { rate: 3,      max:  10000,           plus: 0     	},
        { rate: 4,      max:  25000,           plus: 300   	},
        { rate: 4.5,    max:  40000,           plus: 900   	},
        { rate: 6,      max:  60000,           plus: 1575  	},
        { rate: 6.5,    max:  99999999999999,  plus: 2775  	}
      ],
      married: [
        { rate: 3,      max:  10000,           plus: 0   	},
        { rate: 4,      max:  25000,           plus: 300 	},
        { rate: 4.5,    max:  40000,           plus: 900 	},
        { rate: 6,      max:  60000,           plus: 1575	},
        { rate: 6.5,    max:  99999999999999,  plus: 2775	}
      ]
    },
    WI: {
      single:  [
        { rate: 3.54,   max:  12760,           plus: 0       	},
        { rate: 4.65,   max:  25520,           plus: 451.7   	},
        { rate: 5.3,    max:  280950,          plus: 1045.04 	},
        { rate: 7.65,   max:  99999999999999,  plus: 14582.83	}
      ],
      married: [
        { rate: 3.54,   max:  17010,           plus: 0       	},
        { rate: 4.65,   max:  34030,           plus: 602.15  	},
        { rate: 5.3,    max:  374600,          plus: 1393.58 	},
        { rate: 7.65,   max:  99999999999999,  plus: 19443.79	}
      ]
    },
    WY: {	single: [],   married:	[]	}
},

    standardDeduction: {
    AL:	{ single: 2500,     married: 7500   },
    AK:	{ single: 0,        married: 0  	},
    AZ:	{ single: 13850,    married: 27700 	},
    AR:	{ single: 2270,     married: 4540 	},
    CA:	{ single: 5202,     married: 10404 	},
    CO:	{ single: 13850,    married: 27700 	},
    CT:	{ single: 0,        married: 0 	    },
    DE:	{ single: 3250,     married: 6500 	},
    DC:	{ single: 13850,    married: 27700 	},
    FL:	{ single: 0,        married: 0 		},
    GA:	{ single: 5400,     married: 7100   },
    HI:	{ single: 2200,     married: 4400   },
    ID:	{ single: 13850,    married: 27700  },
    IL:	{ single: 0,        married: 0      },
    IN:	{ single: 0,        married: 0      },
    IA:	{ single: 0,     	married: 0		},
    KS:	{ single: 3500,     married: 8000   },
    KY:	{ single: 2980,     married: 5960   },
    LA:	{ single: 0,        married: 0      },
    ME:	{ single: 13850,    married: 27700  },
    MD:	{ single: 2350,     married: 4700	},
    MA:	{ single: 0,        married: 0		},
    MI:	{ single: 0,        married: 0		},
    MN:	{ single: 13825,    married: 27650	},
    MS:	{ single: 2300,     married: 4600   },
    MO:	{ single: 13850,    married: 27700  },
    MT:	{ single: 5540,     married: 11080	},
    NE:	{ single: 7900,     married: 15800	},
    NV:	{ single: 0,        married: 0      },
    NH:	{ single: 0,        married: 0      },
    NJ:	{ single: 0,        married: 0      },
    NM:	{ single: 13850,    married: 27700  },
    NY:	{ single: 8000,     married: 16050  },
    NC:	{ single: 12750,    married: 25500  },
    ND:	{ single: 13850,    married: 27700  },
    OH:	{ single: 0,        married: 0      },
    OK:	{ single: 6350,     married: 12700  },
    OR:	{ single: 2420,     married: 4840   },
    PA:	{ single: 0,        married: 0      },
    PR:	{ single: 0,        married: 0      },
    RI:	{ single: 9300,     married: 18600	},
    SC:	{ single: 13850,    married: 27700	},
    SD:	{ single: 0,        married: 0      },
    TN:	{ single: 0,        married: 0      },
    TX:	{ single: 0,        married: 0      },
    UT:	{ single: 0,        married: 0		},    //TAX credits???
    VT:	{ single: 6350,     married: 12700  },
    VA:	{ single: 4500,     married: 9000   },
    WA:	{ single: 0,        married: 0      },
    WV:	{ single: 0,        married: 0		},
    WI:	{ single: 11790,    married: 21820  },
    WY:	{ single: 0,        married: 0      },
  },

  disability: {
    CA: {
      name: "SDI",
      max: 145600,
      rate: 1.1 / 100,
    },
    HI: {
      name: "STI",
      max: 62415.6, //Weekly maximum is $6.
      rate: 0.5 / 100,
    },
    NJ: {
      name: "SDI",
      max: 151900,
      rate: 0.14 / 100,
    },
    NY: {
      name: "SDI",
      max: 6240,
      rate: 0.5 / 100,
    },
    RI: {
      name: "SDI",
      max: 81900,
      rate: 1.1 / 100,
    }
  }

}
