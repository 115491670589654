<template>
  <button :class="classes" :type="submit ? 'submit' : 'button'">
    <slot v-if="! loading">
      <span v-html="text"></span>
    </slot>
    <i v-else class="pi pi-spin pi-spinner text-base" style="font-weight: bold"></i>
  </button>
</template>

<script>
export default {
  props: {
    text: String,
    dark: Boolean,
    loading: Boolean,
    submit: Boolean
  },

  computed: {
    classes () {
      const def = "whitespace-nowrap inline-block text-center py-2 text-sm rounded uppercase font-bold px-4 border-2 cursor-pointer".split(' ');
      if (! this.dark) {
        def.push("bg-white text-psn-blue border-psn-blue");        
      } else {
        def.push("bg-psn-blue text-white border-transparent");
      }
      return def;
    }
  },
}
</script>