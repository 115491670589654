<template>

  <div>

    <div class="text-center">
      <label class="font-bold mb-2 block" for="psn-template-review-select">Select template</label>
      <select id="psn-template-review-select" class="w-full md:w-auto mx-auto"
        :value="paystub.template.selectedTemplateIndex"
        @input="$emit('update:selectedTemplateIndex', $event.target.value)">
        <option v-for="tc of templateComponents" :key="tc.id" :value="tc.id">Template #{{tc.index + 1}}</option>
      </select>
    </div>

    <!-- color options -->
    <ColorOptions :colorOptions="colorOptions" v-if="colorOptions"
      @update:colors="$emit('update:colors', $event)" :colors="paystub.template.colors" />

    <!-- fields controller -->
    <div class="mx-auto w-72 mt-8" v-if=" paystub.stubs.length > 1 && ui.templateHasFields">
      <ul>
        <li>
          <label>
            <input type="radio" value="different" name="fieldsController" v-model="fieldRandomization" />
            Randomize all paystubs differently
            <psn-tooltip>
              <template #content>
                <p>
                  Our documents have unique identifier fields when you create multiple paystubs.
                  These are available on Template 1 and Template 2.
                </p>

                <p>This option assigns <b>the same values</b> across all identifier fields in all your paystubs.</p>
              </template>
            </psn-tooltip>
          </label>
        </li>
        <li>
          <label>
            <input type="radio" value="same" name="fieldsController" v-model="fieldRandomization" />
            Randomize all paystubs the same
            <psn-tooltip>
              <template #content>
                <p>
                  Our documents have unique identifier fields when you create multiple paystubs.
                  These are available on Template 1 and Template 2.
                </p>

                <p>This option assigns <b>different values</b> across all identifier fields in all your paystubs.</p>
              </template>
            </psn-tooltip>
          </label>
        </li>
      </ul>
    </div>

    <!-- NEW numbered stubs -->
    <div class="xl:flex mt-8 xl:mt-0">

      <!-- paginated selector and info (for smaller screens)-->
      <div v-if="paystub.stubs.length > 1" class="xl:hidden flex flex-wrap gap-2 justify-center mb-8">
        <a @click="currentStubIndex--" title="Previous paystub"
          class="w-8 h-8 block bg-contain bg-no-repeat psn-bg-arrow-left cursor-pointer"
          :style="'visibility:' + (currentStubIndex ? 'visible': 'hidden')"></a>

        <span :class="'font-semibold rounded-full h-8 w-8 inline-block text-center pt-1 border border-psn-blue '
          + (i === currentStubIndex ? 'bg-psn-blue text-white' : 'text-psn-blue cursor-pointer') "
          @click="currentStubIndex = i"
          v-for="(stub, i) of paystub.stubs" :key="stub._key" >
          {{i + 1}}
        </span>

        <a @click="currentStubIndex++" title="Next paystub"
          class="w-8 h-8 block bg-contain bg-no-repeat psn-bg-arrow-right cursor-pointer"
          :style="'visibility:' + (currentStubIndex < paystub.stubs.length - 1 ? 'visible': 'hidden')"></a>
      </div>

      <!-- paginated selector and info (for larger screens) -->
      <div v-if="paystub.stubs.length > 1" class="hidden xl:flex flex-col flex-wrap gap-8 justify-center w-1/6">
        <p class="px-2">Review individual paystubs</p>

        <div class="flex justify-center gap-8">

          <div class="flex flex-col flex-wrap gap-2 justify-center">
            <span :class="'font-semibold rounded-full h-8 w-8 inline-block text-center pt-1 border border-psn-blue '
              + (i === currentStubIndex ? 'bg-psn-blue text-white' : 'text-psn-blue cursor-pointer') "
              @click="currentStubIndex = i"
              v-for="(stub, i) of paystub.stubs" :key="stub._key" >
              {{i + 1}}
            </span>
          </div>

          <div class="flex flex-col justify-center gap-4">
            <a @click="currentStubIndex--" title="Previous paystub"
              class="w-8 h-8 block bg-contain bg-no-repeat psn-bg-arrow-up cursor-pointer"
              :style="'visibility:' + (currentStubIndex ? 'visible': 'hidden')"></a>
            <a @click="currentStubIndex++" title="Next paystub"
              class="w-8 h-8 block bg-contain bg-no-repeat psn-bg-arrow-down cursor-pointer"
              :style="'visibility:' + (currentStubIndex < paystub.stubs.length - 1 ? 'visible': 'hidden')"></a>
          </div>

        </div>
      </div>

      <div class="mt-4 overflow-hidden origin-top-left mx-auto" ref="templateContainer">
        <!-- crucial that we use v-show instead of v-if, otherwise the field state is lost,
        because we don't read the field values from the source of truth  -->
        <template v-for="(stub, index) of paystub.stubs" :key="stub._key">
          <suspense @resolve="templateLoaded">
            <component v-show="index === currentStubIndex" v-if="paystub.template.selectedTemplateIndex"
              :is="templateComponents.find(tc => tc.id === paystub.template.selectedTemplateIndex).component"
              :country="country"
              :paystub="paystub" :stub="stub" mode="preview" ref="templates"
              :logos="paystub.template.logos"
              @temp-height="adjustTemplateHeight"
              :fields="stub.fields"
              :showEmployerContribs="paystub.details.copies === 'both'"
              @update:fields="$emit('update:fields', stub._key, $event)"
              />
              <!-- :defaultFields="paystub.template.fields" -->
          </suspense>
        </template>
      </div>

      <div class="w-1/6 hidden xl:block" v-if="paystub.stubs.length > 1">

      </div>

    </div>

    <!-- <psn-button v-if="true" @click="directDownload(true)" text="Download" />
    <psn-button v-if="true" @click="getS3Paths()" text="S3 paths" /> -->

  </div>
</template>

<script>
import utils from "./templates/utils";
import ColorOptions from "./color-options.vue";

export default {
  components: { ColorOptions },

  props: ["paystub", "country", "colorOptions"],
  emits: ["update:selectedTemplateIndex", "update:fields", "update:colors"],

  data () {
    return {
      downloadUrl: null,
      currentStubIndex: 0,
      fieldRandomization: "different",
      ui: {
        downloading: false,
        templateHasFields: false,
      }
    };
  },

  watch: {
    //must reset because indices are meaningless when new stubs are added/removed.
    async "paystub.stubs.length" () {
      this.currentStubIndex = 0;
      await this.$nextTick();
      this.randomizeFields();
    },

    fieldRandomization: {
      handler () {
        this.randomizeFields();
      },
    },

    //Without this, scaling down of previews doesn't trigger for the newly shown stubs.
    async currentStubIndex () {
      await this.$nextTick();
      window.dispatchEvent(new Event('resize'));
    },
  },

  computed: {
    postJson () {
      const ret = {
        costomer: {
          email_address: "",
        },
        template_id: +this.paystub.template.selectedTemplateIndex,
        docType: this.country + "/paystub/" + this.paystub.template.selectedTemplateIndex,
        document: this.paystub,
      };

      this.$parent.$parent.adjustDates(ret.document);
      return JSON.stringify( ret );
    },

    templateComponents () {
      const ret = utils.loadTemplates(this.country);
      this.$emit('update:selectedTemplateIndex', ret[0].id);
      return ret;
    }
  },

  methods: {
    randomizeFields () {
      let firstStubTemplate = this.$refs.templates[0];
      this.ui.templateHasFields = !! firstStubTemplate.generateFieldValues;
      if (! this.ui.templateHasFields) return;

      //Without parse/stringify individual paystubs cannot be edited separately.
      const emit = (stub, fieldVals) => {
        const vals = JSON.parse(JSON.stringify(fieldVals));
        this.$emit('update:fields', stub._key, vals);
      }

      if (this.fieldRandomization === 'different') {
        this.paystub.stubs.forEach(stub => emit(stub, firstStubTemplate.generateFieldValues()));
      } else { //same
        const fieldVals = firstStubTemplate.generateFieldValues();
        this.paystub.stubs.forEach(stub => emit(stub, fieldVals));
      }
    },

    async templateLoaded () {
      await this.$nextTick();
      this.randomizeFields();
    },

    adjustTemplateHeight (e) {
      //const amount = this.country === 'ca' ? 200 : 50;
      const amount = 50;
      this.$refs["templateContainer"].style.height = (e + amount) + "px"; //50 was enough for us, canada requires 200
    },

    async directDownload (download) {
      //const mime = this.country === 'ca' && this.paystub.details.copies === 'both' ? 'zip' : 'pdf';
      const mime = 'zip';
      const response = await fetch(`/doc-gen?download=1&skipS3=1`, {
        method: 'POST',
        body: this.postJson,
        headers: {
          'Accept': `application/${mime}`,
          'Content-Type': 'application/json',
        }
      });

      if (! download) {
        const ret = await response.json();
        return console.log(ret);
      }

      //https://stackoverflow.com/questions/44168090/fetch-api-to-force-download-file
      const blob = await response.blob();
      console.log("işte paystub blom: ", blob);
      const url = await URL.createObjectURL(blob);
      console.log("na bu da urlsi: ", url);
      window.open(url, '_blank');
      URL.revokeObjectURL(url);
    },

    getS3Paths () {
      fetch('/doc-gen', {
        method: 'POST',
        body: this.postJson,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      .then(response => response.json())
      .then(ret => console.log(ret));
    }
  },
}
</script>
