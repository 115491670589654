<template>
  <div>
    <label v-if="! inline" :for="htmlId" class="block font-bold text-psn-light-header mb-3">{{label}}</label>
    <select :required="required !== undefined || required" :id="htmlId" @change="$emit('change')" :value="modelValue" 
      @input="$emit('update:modelValue', $event.target.value)" :class="cls" >
      <option v-if="inline" value="">{{label}} {{required ? '*' : ''}}</option>
      <slot />
    </select>
  </div>   
</template>

<script>
export default {  


  props: ["modelValue", "label", "required", "inline", "cls"],

  computed: {
    htmlId () {
      if (this.inline) return null;
      return "" + Math.random();
    }
  }
}
</script>