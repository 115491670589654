<template>
    <section class="md:container md:mx-auto flex flex-col-reverse md:flex-row-reverse mt-16 md:mt-32 lg:mt-56 md:px-4">
      
      <div class="container mx-auto pt-8 md:pt-16 text-base md:-ml-16 md:pl-32 md:pr-16 md:pb-16 md:bg-psn-active md:text-xl">

        <h2 class="content-h2 text-center md:text-left">Not all {{$localize(country, "pay stub")}} generators are created equal.</h2>
        <p class="content-italic-subheader text-center md:text-left">We stand out.</p>
        
        <p class="mt-8 text-psn-light-text leading-loose text-base md:text-lg">
          Your satisfaction is what drives our business. The Best Paystubs combines expertise in accounting and 
          {{$localize(country, "US")}} tax law with 
          top tier software development and customer service excellence to deliver the market’s leading digital {{$localize(country, "paystub")}} platform. 
          {{$localize(country, "Headquartered in Texas,")}} 
          with offices all over the world, you’re guaranteed to get the support you need, when you need it.
        </p>

        <router-link class="text-psn-green-text font-semibold text-xl mt-8 block text-center md:text-left"
          :to="{name: country + '-paystub'}">
          
          <span class="right-arrow-green" style="backg">Create your {{$localize(country, "paystub")}}</span>
        </router-link>

      </div>
      
      <div class="computer-container">
        <div class="computer computer-shadow " :class="computerBg"></div>
      </div>

    </section>
</template>

<script>
export default {
  computed: {
    country () {
      return this.$route.meta.country;
    },

    computerBg () {
      return this.country === 'us' ? "computer-us" : "computer-ca";
    },
  },
  
}
</script>

<style scoped>
  .computer-container {
    @apply h-64 w-full md:w-4/5 md:h-[600px] lg:h-[700px] md:-mt-8 lg:-mt-16;
  }

  .computer {    
    @apply bg-cover bg-no-repeat;
    @apply md:rounded-tr-3xl md:rounded-br-3xl w-full h-full;
    /* background-position: center -50px; */
    @apply md:bg-[-240px_center] xl:bg-center;
  }

  .computer-shadow {
    box-shadow: 43px 4px 43px 0px rgba(34, 60, 80, 0.2);
  }

  .computer-us {
    background-image: url("@/assets/images/computer-us.webp");    
  }

  .computer-ca {
    background-image: url("@/assets/images/computer-ca.webp");
  }

  .right-arrow-green {
    background-image: url('@/assets/images/right-arrow_green.svg');
    @apply bg-no-repeat bg-right pr-10;
    background-size: 10%;
  } 
</style>