import { /* nextTick, ,*/ nextTick, watch, /* watchEffect */ } from "vue";

import Stub from "./Stub";
import dayjs from "dayjs";
//import Earning from "./Earning";
import taxCalcUS from "../tax-calc";
import taxCalcCA from "../tax-calc-ca";
import taxCalcUK from "../tax-calc-uk";

import { computed } from "vue";
import Earning from "./Earning";
import Deduction from "./Deduction";
import { PQueue, PQueueItem } from "./PQueue";

const periodMap = {
  "daily":        {amount: 1, unit: "day"},
  "weekly":       {amount: 1, unit: "week"},
  "bi-weekly":    {amount: 2, unit: "week"},
  "semi-monthly": {amount: 15, unit: "day"},
  "4-weekly":     {amount: 4, unit: "week"},
  "monthly":      {amount: 1, unit: "month"},
  "quarterly":    {amount: 3, unit: "month"},
  "semi-annually":{amount: 6, unit: "month"},
  "annually":     {amount: 1, unit: "year"}
};

//This is the topmost logic handler for paystubs.
//We are responsible for handling paystub quantity, dates and prior YTDs mainly.
//All other details are handled within the relevant files under ./cls.

let paystub;
nextTick;

export default class StubsRunner {

  runQueue () {
    //console.log("queue is running", this.pqueue.items);

    while (this.pqueue.items.length) {
      const item = this.pqueue.items.shift();
      //  ("processing item: ", item);
      switch (item.type) {
        case "stub-added":
          break;
        case "deductions-to-be-created":
          Deduction.createDeductions(item.args);
          break;
        case "stub-prior-ytd-changed":
          this.runStubPriorYTDChanged(item);
          break;
        case "item-ytd-total-changed":
          this.runItemYTDChanged(item);
          break;
        default:
          //console.log("unprcessed item: ", item);
      }
    }
  }

  runItemYTDChanged (_item) {
    const {item, stub, type} = _item.args;

    const index = paystub.stubs.findIndex(s => s._key === stub._key);

    const currentStub = paystub.stubs[index];
    const nextStub = paystub.stubs[index + 1];
    if (! nextStub) return; //last stub

    const nextItem = type === "earning"
      ? nextStub.earnings.find(e => e.protoKey === item.protoKey)
      : nextStub.deductions.find(d => d.key === item.key);

    //If a year changed occured, reset to zero.
    //Otherwise an item's prior ytd is taken from the ytdTotal of the previous stub.
    const yearChanged = nextStub.payPeriod.end.getFullYear() !== currentStub.payPeriod.end.getFullYear();
    nextItem.priorYTD = yearChanged ? 0 : item.ytdTotal;
  }

  runStubPriorYTDChanged (item) {
    Deduction.calcNominalPriorYTDs(item.args);
  }

  constructor (ps, country) {
    paystub = ps;
    this.country = country;
    this.ytdCascadingEnabled = false;
    this.pqueue = new PQueue(() => this.runQueue());
  }

  async init (addInitialStub) {
    this.computify();
    Stub.init(paystub, this, this.country);

    if (! addInitialStub) {
      paystub.stubs.forEach(stub => {
        Stub.computify(stub, false);
        //Deduction.bindWatch(stub);
      });
      await nextTick();
    } else { //start with initial stub:
      this.addNewStub();
      //this.recalcAllStubs();
    }
  }

  computify () {
    //watch: details.quantity
    //Add or remove stubs. immediately triggers maxPeriods computed due to first stub's dates
    watch( () => paystub.details.quantity, async (qty, old) => {
      if (qty < old) { //remove stubs.
        paystub.stubs.splice(0, old - qty);
      } else {
        this.ytdCascadingEnabled = false;
        for (let i = 0; i < qty - old; i++) { //add x stubs where x is the difference.
          this.addNewStub();
        }
      }
    });

    watch (() => paystub.period0Index, () => {
      const stub = paystub.stubs[0];
      stub.earnings.forEach(e => Earning.totalChanged(stub, e));
    });

    //We immediately set the first period index to max periods.
    //If user is unhappy, they can change it.
    watch( () => this.maxPeriods, () => paystub.period0Index = this.maxPeriods);

    watch( () => paystub.details.paymentPeriodicity, () => {
      this.stubPeriodChanged(0, "end", paystub.stubs[0].payPeriod.end);
    });

    //Without this, changing employment type to contractor still shows
    //deductions for existing stubs.
    watch ( () => paystub.details.employmentType, () => {
      paystub.stubs.forEach(stub => Deduction.createDeductions(stub));
    });

    //computed: maxPeriods
    //Depends on the first paystub's dates.
    const taxCalc = {us: taxCalcUS, ca: taxCalcCA, uk: taxCalcUK}[this.country];

    this.maxPeriods = computed(() => {
      if (! paystub.stubs.length) return -1;
      let co = taxCalc.ytdCoefficient(paystub.stubs[0].payPeriod, paystub.details.paymentFrequency);
      //don't even ask.
      //Monthly payment. Feb 1 comes 1.019 months, resulting in 3rd pay period.
      //Mar1 comes 1.94 months, also resulting in 3rd period.
      co -= 0.02;
      const ret = Math.ceil(co) + 1;
      return ret;
    });
  }

/*   //called from Earning.js in response to total changes, so that we can readjust priorYTD of the first stub's regular
  earningTotalChanged (stub, earning) {
    if (! this.ytdCascadingEnabled) return;
    const index = paystub.stubs.findIndex(s => s._key === stub._key);
    if (index) return; //we only care about the first stub's income
    earning.priorYTD = + (earning.total * (paystub.period0Index - 1)).toFixed(2);
    earning.nominalPriorYTD = earning.priorYTD;
  } */

/*   recalcAllStubs () {
    //disable tax calc
    paystub.stubs[0].earnings.forEach(e => {
      e.priorYTD = + (e.total * (paystub.period0Index - 1)).toFixed(2);
    });
  } */

  //Called from Earning.js or Deduction.js in response to ytdTotal changes, so that we can cascade prior ytds:
  //Note that this function is indirectly recursive. A change in earning's priorYTD causes a
  //recalculation of its ytd total, which in turn calls this function again to process the next stub.
  //type is either "earning" or "deduction"
  itemYTDTotalChanged (stub, item, type) {
    this.pqueue.push({type: "item-ytd-total-changed", args: {stub, item, type}});
    //if (! this.ytdCascadingEnabled) return;
    /* const index = paystub.stubs.findIndex(s => s._key === stub._key);

    const currentStub = paystub.stubs[index];
    const nextStub = paystub.stubs[index + 1];
    if (! nextStub) return; //last stub

    const nextItem = type === "earning"
      ? nextStub.earnings.find(e => e.protoKey === item.protoKey)
      : nextStub.deductions.find(d => d.key === item.key);

    //If a year changed occured, reset to zero.
    //Otherwise an item's prior ytd is taken from the ytdTotal of the previous stub.
    const yearChanged = nextStub.payPeriod.end.getFullYear() !== currentStub.payPeriod.end.getFullYear();
    nextItem.priorYTD = yearChanged ? 0 : item.ytdTotal; */


  }

  //called from Stub.js
  //Purpose is to adjust consecutive paystubs' pay dates equal to the distance to their period end.
  payDateChanged (origStub) {
    //await nextTick(); //Crucial. Without this firstStub may come null as it is too newly created.
    const stubIndex = paystub.stubs.findIndex(s => s._key === origStub._key);
    if (stubIndex) return; //We only apply this to the first paystub.
    const firstStub = paystub.stubs[0];
    const diff = dayjs(firstStub.payDate).diff(firstStub.payPeriod.end, "day");
    paystub.stubs.forEach((stub, index) => {
      if (! index) return; //skip first
      stub.payDate = dayjs(stub.payPeriod.end).add(diff, "day").toDate();
    });
  }

  //called from Earning.js in response to rate/hours changes. Apply to the subsequent stubs:
  earningRateOrHoursChanged (stub, earning) {
    if (! (earning.isRegular || earning.protoKey === 'overtime')) return;
    const index = paystub.stubs.findIndex(s => s._key === stub._key);

    paystub.stubs.filter((s, i) => i > index).forEach(s => {
      const e = s.earnings.find(e => e.protoKey === earning.protoKey);
      e.rate = earning.rate;
      if (e.isRegular) e.hours = earning.hours; //do not apply hours to Overtime.
    });
  }

  //called from 5b-Stub.vue to populate add earning dialoge
  getAvailableEarningProtos (stub) {
    return stub.earnings.filter(e => ! this.isEarningVisible(stub, e));
  }

  //called from 5b-Stub.vue
  addEarning (stub, earning) {
    stub;
    paystub.stubs.forEach(s => {
      const e = s.earnings.find(e => e.protoKey === earning.protoKey);
      e.visible = true;
    })
  }

  //called from 5b-Stub.vue
  addCustomEarning (stub, name, type) {
    const protoKey = "custom-earning-" + Math.random();
    const opts = Object.assign({}, {isCustom: true, visible: false, name, type, protoKey});

    paystub.stubs.forEach(s =>  {
      const earning = Earning.make(s, opts);
      s.earnings.push(earning);
    });

    const ourEarning = stub.earnings.find(e => e.protoKey === protoKey);
    ourEarning.visible = true;
  }

  addDeduction (stub, name, nature) {
    Deduction.addDeduction(stub, name, nature);
  }

  //called from Stub.vue
  removeEarning (stub, earning) {
    earning.visible = false;
    earning.rate = 0;
    earning.priorYTD = 0;
    stub;
    if (! earning.isCustom) return;
    //if all stubs have ytdTotal of 0, remove the custom earning from the pool
    //await nextTick();
    const existingStubWithEarning = paystub.stubs.find(s => {
      const e = s.earnings.find(e => e.protoKey === earning.protoKey);
      if (! e) return false; //not possible but still;
      return !! e.ytdTotal;
    });
    if (! existingStubWithEarning) {
      paystub.stubs.forEach(s => {
        const index = s.earnings.findIndex(e => e.protoKey === earning.protoKey);
        if (index > -1) s.earnings.splice(index, 1);
      });
    }
  }

  //called from Stubs.vue in response to date changes
  //also called from a watcher in this file, when periodicity changes. ************** FIX THE COMMENT *****************
  stubPeriodChanged (stubIndex, which, day) {
    this.adjustPeriod(stubIndex, which, day);

    //adjust previous ones
    let prevCount = stubIndex;
    while (prevCount--)
      this.adjustPeriod(prevCount, "end", dayjs(paystub.stubs[prevCount + 1].payPeriod.start).subtract(1, "day").toDate());

    //adjust consequent ones
    let i = stubIndex;
    while (++i < paystub.stubs.length)
      this.adjustPeriod(i, "start", dayjs(paystub.stubs[i - 1].payPeriod.end).add(1, "day").toDate());
  }

  adjustPeriod (stubIndex, which, day) {
    //adjust the other end of the stub.
    const diff = periodMap[paystub.details.paymentPeriodicity];
    if (which === 'start') {
      const end = dayjs(day).add(diff.amount, diff.unit).subtract(1, "day").toDate();
      paystub.stubs[stubIndex].payPeriod = {start: day, end};
    }
    else {
      const start = dayjs(day).subtract(diff.amount, diff.unit).add(1, "day").toDate();
      paystub.stubs[stubIndex].payPeriod = {start, end: day};
    }
  }

  makePeriod (endDay) {
    if (! endDay) endDay = dayjs().startOf('day').toDate();
    const sub = periodMap[paystub.details.paymentPeriodicity];
    const start = dayjs(endDay).subtract(sub.amount, sub.unit).add(1, "day").toDate();
    return {start, end: dayjs(endDay).toDate()};
  }

  async addNewStub (isNewStub) {
    const firstStub = paystub.stubs[0];
    const period = this.makePeriod(firstStub ? dayjs(firstStub.payPeriod.start).subtract(1, 'day') : null);
    const stub = Stub.make(period, isNewStub);
    const pItem = new PQueueItem ("stub-added", null);
    this.pqueue.push(pItem);
    return stub;
  }

  async priorYTDChanged (stub) {
    this.pqueue.push({type: "stub-prior-ytd-changed", args: stub});
  }

  isEarningVisible (stub, earning) {
    stub;
    if (earning.isRegular) return true;
    if (earning.visible) return true;
    if (earning.ytdTotal) return true;
    return false;
  }

  //called from Stub.vue
  isEarningDeletable (stub, earning) {
    if (earning.isRegular) return false;
    if (! earning.priorYTD) return true;
    //has prior ytd. deletion only allowed for the first occurrence.
    const firstStubWithEarningIndex = paystub.stubs.findIndex(s => {
      const e = s.earnings.find(e => e.protoKey === earning.protoKey);
      if (! e) return false;
      return !! e.ytdTotal;
    });

    const stubIndex = paystub.stubs.findIndex(s => s._key === stub._key);
    return stubIndex === firstStubWithEarningIndex;
  }

  //called from Paystub.vue
  setStubFields (key, fields) {
    const stub = paystub.stubs.find(s => s._key === key);
    Object.assign(stub.fields, fields); ////stub.fields = fields; caused infinite loop in vue.
  }
}