//2021 provincial rates
export default {
  provinceTaxBrackets: {

    ON: [//Ontario
        { max: 45142,          rate: 5.05,     constant: 0 },
        { max: 90287,          rate: 9.15,     constant: 1851 },
        { max: 150000,         rate: 11.16,    constant: 3666 },
        { max: 220000,         rate: 12.16,    constant: 5166 },
        { max: 9999999999,     rate: 13.16,    constant: 7366 },
    ],
    
    QC: [ //Quebec
        { max: 45105,          rate: 15,      constant: 0 },
        { max: 90200,          rate: 20,      constant: 2255 },
        { max: 109755,         rate: 24,      constant: 5863 },
        { max: 9999999999,     rate: 25.75,   constant: 7783 },
    ],

    NS: [ //Nova Scotia 
        { max: 29590,          rate: 8.79,     constant: 0 },
        { max: 59180,          rate: 14.95,    constant: 1823 },
        { max: 93000,          rate: 16.67,    constant: 2841 },
        { max: 150000,         rate: 17.5,     constant: 3613 },
        { max: 9999999999,     rate: 21,       constant: 8863 },
    ],

    NB: [ //New Brunswick 
        { max: 43835,          rate: 9.12,     constant: 0 },
        { max: 87671,          rate: 14.82,    constant: 2499 },
        { max: 142534,         rate: 16.52,    constant: 3989 },
        { max: 162383,         rate: 17.84,    constant: 5870 },
        { max: 9999999999,     rate: 20.3,     constant: 9865 },
    ],

    MB: [ //Manitoba 
        { max: 33723,          rate: 10.8,     constant: 0 },
        { max: 72885,          rate: 12.75,    constant: 658 },
        { max: 9999999999,     rate: 17.4,     constant: 4047 },
    ],

    BC: [ //British Columbia
        { max: 42184,          rate: 5.06,     constant: 0 },
        { max: 84369,          rate: 7.7,      constant: 1114 },
        { max: 96866,          rate: 10.5,     constant: 3476 },
        { max: 117623,         rate: 12.29,    constant: 5210 },
        { max: 159483,         rate: 14.7,     constant: 8045 },
        { max: 222420,         rate: 16.8,     constant: 11394 },
        { max: 9999999999,     rate: 20.5,     constant: 19623 },
    ],

    PE: [ //Prince Edward Island 
        { max: 31984,          rate: 9.8,      constant: 0 },
        { max: 63969,          rate: 13.8,     constant: 1279 },
        { max: 9999999999,     rate: 16.7,     constant: 3134 },
    ],

    SK: [ //Saskatchewan
        { max: 45677,          rate: 10.5,     constant: 0 },
        { max: 130506,         rate: 12.5,     constant: 914 },
        { max: 9999999999,     rate: 14.5,     constant: 3524 },
    ],

    AB: [ //Alberta
        { max: 131220,           rate: 10,      constant: 0 },
        { max: 157464,           rate: 12,      constant: 1851 },
        { max: 209952,           rate: 13,      constant: 3666 },
        { max: 314928,           rate: 14,      constant: 5166 },
        { max: 9999999999,       rate: 15,      constant: 7366 },
    ],

    NL: [ //Newfoundland and Labrador
        { max: 38081,           rate: 8.7,      constant: 0 },
        { max: 76161,           rate: 14.5,     constant: 2209 },
        { max: 135973,          rate: 15.8,     constant: 3199 },
        { max: 190363,          rate: 17.3,     constant: 5238 },
        { max: 9999999999,      rate: 18.3,     constant: 7142 },
    ],

    NT: [ //Northwest Territories
        { max: 44396,            rate: 5.9,     constant: 0 },
        { max: 88796,            rate: 8.6,     constant: 1199 },
        { max: 144362,           rate: 12.2,    constant: 4395 },
        { max: 9999999999,       rate: 14.05,   constant: 7066 },
    ],

    YT: [ //Yukon
        { max: 49020,           rate: 6.4,      constant: 0 },
        { max: 98040,           rate: 9,        constant: 1275 },
        { max: 151978,          rate: 10.9,     constant: 3137 },
        { max: 500000,          rate: 12.8,     constant: 6025 },
        { max: 9999999999,      rate: 15,       constant: 17025 },
    ],

    NU: [ //Nunavut
        { max: 46740,            rate: 4,       constant: 0 },
        { max: 93480,            rate: 7,       constant: 1402 },
        { max: 151978,           rate: 9,       constant: 3272 },
        { max: 9999999999,       rate: 11.5,    constant: 7071 },
    ],
  },

  provinceMinTaxable: {
  
    ON: 16845.14, //Ontario
    QC: 0, //Quebec //not yet provided
    NS: 12144, //Nova Scotia
    NB: 11157, //New Brunswick
    MB: 10478, //Manitoba
    BC: 20630, //British Columbia
    PE: 11076, //Prince Edward Island
    SK: 17245.8, //Saskatchewan
    AB: 20630, //Alberta
    NL: 10050, //Newfoundland and Labrador
    NT: 16185, //Northwest Territories
    YT: 14645.8, //Yukon
    NU: 17506, //Nunavut
  
  },

  provincialSurtaxBrackets: {
    PE: [
      { max: 12500, rate: 0, constant: 0 },
      { max: 9999999999, rate: 10, constant: 0 },
    ],
    ON: [
      { max: 4874, rate: 0, constant: 0 },
      { max: 6237, rate: 20, constant: 0 }, 
      { max: 9999999999, rate: 36, constant: 272.6 }, //Found by (6237 * 0,2) This constant amount will be added to ((basic payable tax - 6237) * 0,36)
    ],
  },

  provincialHealthPremiumBrackets: { //health premium only available in Ontario. Constant amounts are fixed amounts, no percentages.
    ON: [
      { max: 20000,       constant: 0 },
      { max: 36000,       constant: 300 },
      { max: 48000,       constant: 450 },
      { max: 72000,       constant: 600 },
      { max: 200000,      constant: 750 },
      { max: 9999999999,  constant: 900 },
    ]
  },

  provincialTaxReductionBrackets: {
    BC: [
      { max: 21418, rate: 0,    constant: 481 },//Tax reduction amount is 481 fixed in this bracket
      { max: 34929, rate: 3.56, constant: 0 }, //Tax Reduction in this bracket is 481 - ((34.929 - yearlyRegular) * 0,0356)
      { max: 9999999999, rate: 0,    constant: 0 },//No tax reduction in this bracket
    ]
  },
  
}