<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 divide-y-2 lg:divide-y-0 lg:divide-x-2 
    divide-gray-200 text-psn-light-header mt-4">  
    <div class="lg:pr-16 ">
      <slot name="left"></slot>
    </div>
    <div class="lg:pl-16 mt-8 pt-8 lg:mt-0 lg:pt-0">
      <slot name="right"></slot>
    </div>
  </div>
</template>