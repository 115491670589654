"use strict";
import dayjs from "dayjs";
import StateTaxUS2021 from "./stateTax-2021.js";
import StateTaxUS2022 from "./stateTax-2022.js";
import StateTaxUS2023 from "./stateTax-2023.js";
import StateTaxUS2024 from "./stateTax-2024.js";

const stateTaxTableByYear = {
  2021: StateTaxUS2021,
  2022: StateTaxUS2022,
  2023: StateTaxUS2023,
  2024: StateTaxUS2024,
};

const SOCIAL_SECURITY = 6.2 / 100;
const MEDICARE = 1.45 / 100;
const MEDICARE_WAGE_TRESHOLD = 200000;
const MEDICARE_SURTAX = 0.9 / 100;

//MAX wage cap is 142.800 for 2021 (8854 ss tax), 145000 for 2022, 147000 cap, 8990 in tax
const SOCIAL_SECURITY_WAGE_CAP = {
  2021: 142800,
  2022: 147000,
  2023: 160200,
  2024: 168600,
};

//https://smartasset.com/taxes/standard-deduction
//very nice 2022: https://www.efile.com/tax-deduction/federal-standard-deduction/
const standardDeduction = {
  2021: {
    single: 12550, //also married, filing separately
    married: 25100, //also widow
    hoh: 18800, //head of household
  },
  2022: {
    single: 12950,
    married: 25900,
    hoh: 19400,
  },
  2023: {
    single: 13850,
    married: 27700,
    hoh: 20800,
  },
  2024: {
    single: 14600,
    married: 29200,
    hoh: 21900,
  },
};

const federalBrackets = {
  //https://www.nerdwallet.com/article/taxes/federal-income-tax-brackets

  2020: {
    single: [
      { max: 9875, rate: 10, plus: 0 },
      { max: 40125, rate: 12, plus: 987.5 },
      { max: 85525, rate: 22, plus: 4617.5 },
      { max: 163300, rate: 24, plus: 14605.5 },
      { max: 207350, rate: 32, plus: 33271.5 },
      { max: 518400, rate: 35, plus: 47367.5 },
      { max: 99999999999999, rate: 37, plus: 156235 },
    ],

    married: [
      { max: 19750, rate: 10, plus: 0 },
      { max: 80250, rate: 12, plus: 1975 },
      { max: 171050, rate: 22, plus: 9235 },
      { max: 326600, rate: 24, plus: 29211 },
      { max: 414700, rate: 32, plus: 66543 },
      { max: 622050, rate: 35, plus: 94735 },
      { max: 99999999999999, rate: 37, plus: 167307.5 },
    ],

    //TODO: There are actually 2 more statuses: "Married, filing separately" and "Head of household"
    //Most paystubs implementations ignore these but the numbers may differ significantly.
    //Ask for guidence.
  },

  //https://www.irs.com/articles/2021-federal-income-tax-rates-brackets-standard-deduction-amounts/
  2021: {
    single: [
      { max: 9950, rate: 10, plus: 0 },
      { max: 40525, rate: 12, plus: 995 },
      { max: 86375, rate: 22, plus: 4664 },
      { max: 164925, rate: 24, plus: 14751 },
      { max: 209425, rate: 32, plus: 33603 },
      { max: 523600, rate: 35, plus: 47843 },
      { max: 99999999999999, rate: 37, plus: 157804.25 },
    ],

    married: [
      { max: 19900, rate: 10, plus: 0 },
      { max: 81050, rate: 12, plus: 1990 },
      { max: 172750, rate: 22, plus: 9328 },
      { max: 329850, rate: 24, plus: 29502 },
      { max: 418850, rate: 32, plus: 67206 },
      { max: 628300, rate: 35, plus: 95686 },
      { max: 99999999999999, rate: 37, plus: 168993.5 },
    ],
  },

  //https://www.irs.gov/newsroom/irs-provides-tax-inflation-adjustments-for-tax-year-2022
  //https://www.forbes.com/sites/ashleaebeling/2021/11/10/irs-announces-2022-tax-rates-standard-deduction-amounts-and-more/?sh=3a7bc10d4a3a
  2022: {
    single: [
      { max: 10275, rate: 10, plus: 0 },
      { max: 41775, rate: 12, plus: 1027.5 },
      { max: 89075, rate: 22, plus: 4807.5 },
      { max: 170050, rate: 24, plus: 15213.5 },
      { max: 215950, rate: 32, plus: 34647.5 },
      { max: 539900, rate: 35, plus: 49335.5 },
      { max: 99999999999999, rate: 37, plus: 162718 },
    ],

    married: [
      { max: 20550, rate: 10, plus: 0 },
      { max: 83550, rate: 12, plus: 2055 },
      { max: 178150, rate: 22, plus: 9615 },
      { max: 340100, rate: 24, plus: 30427 },
      { max: 431900, rate: 32, plus: 69295 },
      { max: 647850, rate: 35, plus: 98671 },
      { max: 99999999999999, rate: 37, plus: 174253.5 },
    ],
  },

  2023: {
    single: [
      { max: 11000, rate: 10, plus: 0 },
      { max: 44725, rate: 12, plus: 1100 },
      { max: 95375, rate: 22, plus: 5147 },
      { max: 182100, rate: 24, plus: 16290 },
      { max: 231250, rate: 32, plus: 37104 },
      { max: 578125, rate: 35, plus: 52832 },
      { max: 99999999999999, rate: 37, plus: 174238.25 },
    ],

    married: [
      { max: 22000, rate: 10, plus: 0 },
      { max: 89450, rate: 12, plus: 2200 },
      { max: 190750, rate: 22, plus: 10294 },
      { max: 364200, rate: 24, plus: 32580 },
      { max: 462500, rate: 32, plus: 74208 },
      { max: 963750, rate: 35, plus: 105664 },
      { max: 99999999999999, rate: 37, plus: 186601.5 },
    ],
  },

  2024: {
    single: [
      { max: 11600, rate: 10, plus: 0 },
      { max: 47150, rate: 12, plus: 1160 },
      { max: 100525, rate: 22, plus: 5426 },
      { max: 191950, rate: 24, plus: 17168.5 },
      { max: 243725, rate: 32, plus: 39110.5 },
      { max: 609350, rate: 35, plus: 55678.5 },
      { max: 999999999, rate: 37, plus: 183647.25 },
    ],
    married: [
      { max: 23200, rate: 10, plus: 0 },
      { max: 94300, rate: 12, plus: 2320 },
      { max: 201050, rate: 22, plus: 10852 },
      { max: 383900, rate: 24, plus: 34337 },
      { max: 487450, rate: 32, plus: 78221 },
      { max: 731200, rate: 35, plus: 111357 },
      { max: 999999999, rate: 37, plus: 196669.5 },
    ],
  },
};

export default {
  //status can be either "single" or "married"
  calcFederal(yearlyRegular, periodTotal, freq, period, state, additionalData) {
    //us has marital status:
    const status = additionalData.status;

    //- extract year:
    const year = dayjs(period.end).year();

    //-. tax bracket is found using the yearly regular:
    let taxable = yearlyRegular - standardDeduction[year][status];
    if (taxable < 0) taxable = 0;

    const brackets = federalBrackets[year][status];
    const bracketIndex = brackets.findIndex((b) => taxable <= b.max);
    const bracket = brackets[bracketIndex];
    const prevBracket = brackets[bracketIndex - 1];
    const prevCeil = prevBracket ? prevBracket.max : 0;

    const yearlyNominalTax =
      (taxable - prevCeil) * (bracket.rate / 100) + bracket.plus;
    const effectiveIncomeTaxRate = yearlyNominalTax / yearlyRegular || 0; //sometimes comes NaN hence the trick.

    //medicare:
    let yearlyMedicare = yearlyRegular * MEDICARE;
    if (yearlyRegular >= MEDICARE_WAGE_TRESHOLD) {
      yearlyMedicare +=
        (yearlyRegular - MEDICARE_WAGE_TRESHOLD) * MEDICARE_SURTAX;
    }
    const medicareRatio = yearlyMedicare / yearlyRegular;
    const medicare = +(periodTotal * medicareRatio).toFixed(2);

    //social security:
    const cap = SOCIAL_SECURITY_WAGE_CAP[year];
    const incomeForSS = yearlyRegular > cap ? cap : yearlyRegular;
    const yearlySS = incomeForSS * SOCIAL_SECURITY;
    const ssRatio = yearlySS / yearlyRegular;
    let ss = +(periodTotal * ssRatio).toFixed(2);

    if (incomeForSS === cap) {
      const maxSSForPeriod = (incomeForSS * SOCIAL_SECURITY) / freq;
      if (ss > maxSSForPeriod) ss = maxSSForPeriod;
    }

    //state taxes is an array of objects
    const stateTaxes =
      this.calcState(yearlyRegular, periodTotal, freq, status, period, state) ||
      [];

    //all:
    const taxes = [
      { name: "FICA - Medicare", amount: medicare, key: "medicare" },
      { name: "FICA - Social Security", amount: ss, key: "ss" },
      {
        name: "Federal Tax",
        amount: +(periodTotal * effectiveIncomeTaxRate).toFixed(2),
        key: "federal",
      },
      ...stateTaxes,
    ];

    taxes.forEach((tax) =>
      Object.assign(tax, { priorYTD: 0, ytdTotal: 0, isCustom: false })
    );

    return taxes;
  },

  createCustomDeduction(name) {
    return {
      name,
      amount: 0,
      key: "custom-" + name,
      priorYTD: 0,
      ytdTotal: 0,
      isCustom: true,
      isEmployer: false,
    };
  },

  calcState(yearlyRegular, periodTotal, freq, status, period, state) {
    const stateTax = {
      key: "state",
      name: "State Tax",
      amount: 0,
    };

    //page load comes without state. we still show 0.
    if (!state) return [stateTax];

    //- extract year:
    const year = dayjs(period.end).year();
    const stateTaxDB = stateTaxTableByYear[year];

    //-. tax bracket is found using the yearly regular:
    let taxable = yearlyRegular - stateTaxDB.standardDeduction[state][status];
    if (taxable < 0) taxable = 0;

    const brackets = stateTaxDB.brackets[state][status];
    const bracketIndex = brackets.findIndex((b) => taxable <= b.max);

    let effectiveIncomeTaxRate = 0;

    if (bracketIndex > -1) {
      //no tax brackets:
      const bracket = brackets[bracketIndex];
      const prevBracket = brackets[bracketIndex - 1];
      const prevCeil = prevBracket ? prevBracket.max : 0;

      const yearlyNominalTax =
        (taxable - prevCeil) * (bracket.rate / 100) + bracket.plus;
      effectiveIncomeTaxRate = yearlyNominalTax / yearlyRegular || 0; //sometimes comes NaN hence the trick.
    }

    stateTax.amount = +(periodTotal * effectiveIncomeTaxRate).toFixed(2);

    const sdi = this.calcSDI(taxable, periodTotal, freq, status, period, state);

    const ret = [stateTax];
    if (sdi) ret.push(sdi);
    return ret;
  },

  calcSDI(yearlyTaxable, periodTotal, freq, status, period, state) {
    const year = dayjs(period.end).year();
    const stateTaxDB = stateTaxTableByYear[year];

    const stateRecord = stateTaxDB.disability[state];
    if (!stateRecord) return null;

    const sdi = {
      key: "sdi",
      name: stateRecord.name,
      amount: 0,
    };

    if (0 === yearlyTaxable) return sdi;

    const capInPeriod = +((stateRecord.rate * stateRecord.max) / freq).toFixed(
      2
    );
    let yearlyTax = yearlyTaxable * stateRecord.rate;

    const taxRatio = yearlyTax / yearlyTaxable; //Yearly taxable or gross taxable
    let amount = periodTotal * taxRatio;
    if (amount > capInPeriod) amount = capInPeriod;
    sdi.amount = +amount.toFixed(2);
    return sdi;
  },

  //calculated how many days since the year start for priodYDT (not ytd)
  ytdDays(period) {
    const jan1ForEnd = dayjs(period.end).startOf("year");
    const jan1ForStart = dayjs(period.start).startOf("year");
    //if period crosses jan 1, that means there is no prior YTD
    return jan1ForEnd.isSame(jan1ForStart)
      ? dayjs(period.start).diff(jan1ForStart, "day")
      : 0;
  },

  ytdCoefficient(period, freq) {
    const days = this.ytdDays(period);
    return days / (365 / freq);
  },
};
