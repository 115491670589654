<template>

  <input type="number" :value="val" @input="input"
    @blur="blur" @focus="focus" step="any"
    :class="{'psn-invalid': invalid}"
    />

</template>

<script>

export default {

  props: ["modelValue", "max"],
  emits: ['update:modelValue'],

  data () {
    return {
      val: "" + this.modelValue, //string.
      lastValidVal: "" + this.modelValue, //string
      invalid: false,
    }
  },

  methods: {
    input (e) {
      let val = parseFloat(e.target.value);
      this.invalid = isNaN(val) || val < 0 || val > this.max;

      if (! this.invalid)
        this.$emit('update:modelValue', val);
      else
        this.val = e.target.value;
    },

    blur () {
      this.val = "" + this.modelValue;
      this.invalid = false;
      //because invalid input causes emitting of last value, which is same so doesn't trigger watch.
      this.$forceUpdate();
    },

    focus (e) {
      setTimeout(() => e.target.select(), 30);
    },
  },

  watch: {
    modelValue (v) {
      this.val = "" + v;
      this.lastValidVal = this.val;
      this.invalid = false;
    },
  },

}

</script>
