const PERMA_MARKER = 'data-vue-meta';
import logoPath from "@/assets/images/logo-compressed.svg";
import pricingData from "@/lib/pricing-data.js";

export default {

  makeLinkTag (rel, href, attrs = {}) {
    const tag = document.createElement("link");
    tag.setAttribute(PERMA_MARKER, '');
    tag.setAttribute("rel", rel);
    tag.setAttribute("href",  href);

    Object.entries(attrs).forEach(kv=> tag.setAttribute(kv[0], kv[1] || ''));
    document.head.appendChild(tag);
  },

  makeAlternate (lang, href) {
    lang = lang === 'uk' ? 'gb' : lang;
    this.makeLinkTag("alternate", href, {hreflang: "en-" + lang});
  },

  makeDefaultAlternate (href) {
    this.makeLinkTag("alternate", href, {hreflang: "en"});
    this.makeLinkTag("alternate", href, {hreflang: "x-default"});
  },

  makeCanonical (href) {
    this.makeLinkTag("canonical", href);
  },

  makeDescription (desc) {
    const tag = document.createElement("meta");
    tag.setAttribute(PERMA_MARKER, '');
    tag.setAttribute("name", "description");
    tag.setAttribute("content", desc);
    document.head.appendChild(tag);
  },

  makeNoIndex () {
    const tag = document.createElement("meta");
    tag.setAttribute(PERMA_MARKER, '');
    tag.setAttribute("name", "robots");
    tag.setAttribute("content", "noindex");
    document.head.appendChild(tag);
  },

  makeOgTag (prop, content) {
    const tag = document.createElement("meta");
    tag.setAttribute(PERMA_MARKER, '');
    tag.setAttribute("property", "og:" + prop);
    tag.setAttribute("content", content);
    document.head.appendChild(tag);
  },

  makeRedirect (url) {
    const tag = document.createElement("meta");
    tag.setAttribute(PERMA_MARKER, '');
    tag.setAttribute("http-equiv", "refresh");
    tag.setAttribute("content", "0; url=" + url);
    document.head.appendChild(tag);
  },

  reset () {
    Array.from(document.querySelectorAll('[' + PERMA_MARKER + ']'))
      .map(el => el.parentNode.removeChild(el));
  },

  //json-ld:
  writeLD (data) {
    const tag = document.createElement("script");
    tag.setAttribute(PERMA_MARKER, "");
    tag.setAttribute("type", "application/ld+json");
    tag.textContent = JSON.stringify(data);
    document.head.appendChild(tag);

  },

  makeLD_FAQ (set) {
    //set is an array of {q, a}, where q is question, a is an arroy of paragraphs:
    const mainEntity = set.map(qa => {
      const a = qa.a.map(a => a.replace(/\n/g, '').replace(/\s+/g, ' ')).join("<br>");

      return {
        "@type": "Question",
        name: qa.q,
        acceptedAnswer: {
          "@type": "Answer",
          "text": a,
        },
      }
    });

    const ret = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity,
    };

    this.writeLD(ret);
  },

  makeLD_organization () {
    const logo = location.origin + logoPath;
    const ret = {
      "@context": "https://schema.org",
      "@type": "FinancialService",
      "image": [
        logo
       ],
      "@id": location.origin + '/',
      "name": "The Best Paystubs LLC",
      "url": location.origin + '/',
      "logo": logo,
      "email": "support@thebestpaystubs.com",
      "telephone": "+1 (817) 523-8255",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "3420 Bristol St., Suite 600",
        "addressLocality": "Costa Mesa",
        "addressRegion": "CA",
        "postalCode": "92626",
        "addressCountry": "US"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": 32.706703327737145,
        "longitude": -97.0578310969238
      },
      "sameAs" : [
        "https://www.facebook.com/TheBestPaystubs/",
        "https://www.instagram.com/thebestpaystubs/",
        "https://www.linkedin.com/company/the-best-paystubs/",
        "https://twitter.com/TheBestPaystubs",
        "https://www.youtube.com/channel/UC_o8im-lTmhiP_x_Vfpzleg/featured"
          ],
      "priceRange": "7.99 - 39.99 USD"
    }

    this.writeLD(ret);
  },

  makeLD_product (country, type) {
    //type is paystub, p60 etc
    const key = country + "-" + type;
    const map = {
      "us-paystub": {
        name: "Paystub - US",
        desc: "A written document that details gross earnings, net pay, deductions, etc. for an employee.",
      },
      "ca-paystub": {
        name: "Paystub - CA",
        desc: "A written document that details gross earnings, net pay, deductions, etc. for an employee.",
      },
      "uk-paystub": {
        name: "Payslip - UK",
        desc: "A written document that details gross earnings, net pay, deductions, etc. for an employee.",
      },
      "us-1099-misc": {
        name: "1099-MISC Form",
        desc: "Used by U.S employers to report payments made to independent contractors (who cover their own employment taxes)."
      },
      "us-1099-NEC": {
        name: "1099-NEC Form",
        desc: "Used by U.S employers to report payments made to independent contractors (who cover their own employment taxes)."
      },
      "us-w-2": {
        name: "W-2 Form",
        desc: `W-2 form is used by U.S. employers to report wages paid to employees and the taxes withheld from them.
          Make sure you have the forms you need to file your taxes`
      },
      "ca-t4": {
        name: "T-4 Slip",
        desc: "Used by Canadian employers to report wages paid to employees during a calendar year and the deductions withheld from them."
      },
      "uk-p60": {
        name: "P60",
        desc: "Used by Canadian employers to report wages paid to employees during a calendar year and the deductions withheld from them.",
      }
    }
    const {name, desc} = map[key];

    const items = pricingData[country].filter(item => item.type === type);
    const sortedPrices = items.map(item => item.price).sort((a, b) => a - b);

    const ret = {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": name,
      "description": desc,
      "brand": {
        "@type": "Brand",
        "name": "The Best Pay Stubs"
      },
      "offers": {
        "@type": "AggregateOffer",
        "lowPrice": "" + sortedPrices[0],
        "highPrice": "" + sortedPrices[sortedPrices.length - 1],
        "offerCount": "1",
        "priceCurrency": items[0].currency,
        "availability": "https://schema.org/InStock",
        "seller": {
          "@type": "Organization",
          "name": "The Best Paystubs LLC"
        }
      }
    }
    this.writeLD(ret);
  },

  makeLD_video (poster, video, duration) {
    const ret = {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "The Best Paystubs is the secure way to generate pay stubs and payroll documents.",
      "description": "We offer real paycheck stubs and the most up-to-date federal tax forms. Create paystubs and payroll documents quickly. You’ll receive an email in minutes with a secure link to download a print-ready PDF.",
      "thumbnailUrl": poster,
      "uploadDate": "2022-06-02T08:00:00+08:00",
      "duration": duration,
      "contentUrl": video,
    }
    this.writeLD(ret);
  },

  makeLD_blogPost (url, title, desc, imageUrl, publishedDate, updateDate) {
    const logo = location.origin + logoPath;
    if (! updateDate) updateDate = publishedDate;

    const ret = {
      "@context": "http://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": url,
      },
      "headline": title,

      "image": [
        imageUrl
        //"https://tbp-production-storage.s3.us-west-1.amazonaws.com/blog/blog-3.webp"
       ],
      "datePublished": publishedDate,
      "dateModified": updateDate,
      "author": {
        "@type": "Organization",
        "name": "The Best Paystubs",
        "url": location.origin + "/",
      },
      "publisher": {
        "@type": "Organization",
        "name": "The Best Paystubs LLC",
        "logo": {
          "@type": "ImageObject",
          "url": logo,
        }
      },
      "description": desc,
    }
    this.writeLD(ret);
  }

}