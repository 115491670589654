<template>
  <div v-if="colorOptions" class="mt-8">
    <p class="mb-2 text-center"><b>Color options</b></p>

    <div class="flex gap-2 justify-center" >
      <div v-for="(opt, index) of colorOptions" :key="selectedTemplateIndex + ':' + index"
        class="w-8 h-8 border-blue-600 cursor-pointer"
        :class="colors && colors.chrome === opt.chrome ? 'border-2' : 'border-0'"
        :style="'background-color: ' +  opt.chrome"
        @click="$emit('update:colors', opt)"
        ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["colorOptions", "selectedTemplateIndex", "colors"],
  emits: ["update:colors"]
}
</script>