<template>
  <InputMask :placeholder="placeholder" v-model="mv" 
    :mask="mp.mask" :auto-clear="false" :pattern="mp.pattern" />
</template>

<script>
import InputMask from "primevue/inputmask";
export default {
  components: {InputMask},

  props: ["placeholder", "modelValue", "country"],

  data () {
    return {
      mv: this.modelValue,
    }
  },

  computed: {
    mp () {
      switch (this.country) {
        case "us": case "ca":
        default:
          return {
            mask: "(999) 999-9999",
            pattern: "\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}",
          }
      }
    },        
  },

  watch: {
    mv () {
      this.$emit('update:modelValue', this.mv);
    }
  }
}
</script>