<template>
  <div class="font-proxima">

    <div class="mt-8 md:mt-16 mx-auto container px-2">

      <header>
         <h1 v-if="! faqData"
          class="text-psn-light-header text-2xl md:text-3xl text-center mb-8 md:mb-12">FAQ</h1>
        <h2 v-else
          class="content-h2 text-center">FAQ</h2>
      </header>

      <!-- FAQ -->
      <section class="psn-faq" v-if="Array.isArray(faq)" >

        <Accordion :activeIndex="0" :multiple="true">
          <AccordionTab v-for="(item, index) of faq" :key="index">
            <template #header>
              <h3 class="text-psn-light-header text-base md:text-xl font-bold flex-grow pr-2">{{item.q}}</h3>
              <button class="flex-none psn-faq-plus-button w-5 h-5 block bg-center bg-no-repeat bg-contain" title="Toggle answer"></button>

            </template>
            <div class="bg-white p-6 pb-0 text-psn-light-text leading-loose font-bold border border-gray-100 border-solid">
              <template v-if="item.a.length">
                <p class="mb-6" v-for="(a, i) of item.a" :key="i" v-html="a"></p>
              </template>
              <p class="mb-6" v-else>[content missing]</p>
            </div>
          </AccordionTab>

        </Accordion>

      </section>

    </div>

  </div>
</template>

<script>
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

export default {
  components: {Accordion, AccordionTab},
  props: ["faq-data"], //faq-data present for normal pages, missing for FAQ page.

  data () {
    return {
      faq: null,
    }
  },

  computed: {
    country () {
      return this.$route.meta.country;
    },
  },

  watch: {
    country: {
      async handler () {
        //IMPORTANT: Home page FAQs are dynamic imports without async,
        //so they first appear as unresolved promises, hence if (this.faqData) doesn't work.
        if (Array.isArray(this.faqData)) return this.faq = this.faqData;

        //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import
        switch (this.country) {
          case "us": {
            const { default: data } = await import("@/views/us/faq/data.js");
            return this.faq = data;
          }
          case "ca": {
            const { default: data } = await import("@/views/ca/faq/data.js");
            return this.faq = data;
          }
          case "uk": {
            const { default: data } = await import("@/views/uk/faq/data.js");
            return this.faq = data;
          }
        }
      }, immediate: true,
    },

    faq: {
      handler () {
        if (this.faq) this.$meta.makeLD_FAQ(this.faq);
      }, immediate: true,
    },
  }
}
</script>

<style scoped>
  .psn-faq-plus-button {
    background-image: url("../assets/images/plus.svg");
  }
</style>

<style>
  .psn-faq .p-accordion-toggle-icon {
    display: none !important;
  }
</style>

